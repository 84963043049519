<!-- 




<div id="top"></div> -->
<div class="app-header"></div>
<!--=================================
 inner-intro -->

<section class="inner-intro bg-1 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">Leadership and Team Page </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>Leadership and Team Page </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>




<!--=================================
our History-->





<section class="our-team white-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)" [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}" required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-title">
                <span>List of people who matter in our Company</span>
                <h2>Our Team </h2>
                <div class="separator"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-2 text-start">
                <div class="team-image">
                  <img class="img-fluid icon" src="assets/images/ing/jawad1.jpg" alt="">
                </div>
                <div class="team-info">
                  <div class="team-name">
                    <span> Founder, and CEO</span>
                    <h5>Mr. Jawwad Nazir</h5>
                    <p class="team-1"> Founder, and CEO, has been instrumental in our resounding success in the Africa
                      region. His extraordinary leadership and expertise in the ship business have helped us become one
                      of
                      the top used ships in Africa. His experience of 20+ years in the African ship industry and
                      unmatched
                      communication skills have been immaculate for our success.
                      <br> Apart from Mr. Bhatti, our team consists of dedicated professionals who are passionate about
                      providing our customers with the best possible experience.
                    </p>
                  </div>
                  <div class="team-social">
                    <ul>
                      <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                      <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                      <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                      <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-2 text-start">
                <div class="team-image">
                  <img class="img-fluid icon" src="assets/images/ing/Miss Noreen1.jpg" alt="">
                </div>
                <div class="team-info">
                  <div class="team-name">
                    <span>Operations Coordinator</span>
                    <h5>Ms. Noreen</h5>
                    <p class="team-1">Operations Coordinator having industry experience of 10 years</p>
                  </div>
                  <div class="team-social">
                    <ul>
                      <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                      <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                      <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                      <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-2 text-start">
                <div class="team-image">
                  <img class="img-fluid icon" src="assets/images/ing/Nazia1.jpg" alt="">
                </div>
                <div class="team-info">
                  <div class="team-name">
                    <span> Head of IT Dept</span>
                    <h5>Ms. Nazia Khan</h5>
                    <p class="team-1">Head of IT Dept., having 15+ years of experience and proven track record of
                      setting
                      up a Japanese auto dealer's IT department from scratch and leading it to success.</p>
                  </div>
                  <div class="team-social">
                    <ul>
                      <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                      <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                      <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                      <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-2 text-start">
                <div class="team-image">
                  <img class="img-fluid icon" src="assets/images/ing/Noman Hussain1.jpg" alt="">
                </div>
                <div class="team-info">
                  <div class="team-name">
                    <span>Logistics and Shipping Head</span>
                    <h5>Mr. Noman Hussain</h5>
                    <p class="team-1">Logistics and Shipping Head, Durban, have an experience of 15 years and is one
                      of our most oldest and valuable team members.</p>
                  </div>
                  <div class="team-social">
                    <ul>
                      <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                      <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                      <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                      <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="team-2 text-start">
            <div class="team-image">
            <img class="img-fluid icon" src="images/team/05.jpg" alt="">
            </div>
            <div class="team-info">
             <div class="team-name">
              <span>Customer</span>
              <h5>Anne Smith</h5>
              <p>The printin and simply dummy text of bled it to make a type.</p>
             </div>
             <div class="team-social">
                 <ul>
                  <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                  <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                </ul>
             </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="team-2 text-start">
            <div class="team-image">
            <img class="img-fluid icon" src="images/team/06.jpg" alt="">
            </div>
            <div class="team-info">
             <div class="team-name">
              <span></span>
              <h5>Mellissa Doe </h5>
              <p>Make a type simply dummy text of the printin and bled it to.</p>
             </div>
             <div class="team-social">
                 <ul>
                  <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                  <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                </ul>
             </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="team-2 text-start">
            <div class="team-image">
            <img class="img-fluid icon" src="images/team/07.jpg" alt="">
            </div>
            <div class="team-info mb-md-0 mb-4">
             <div class="team-name">
              <span></span>
              <h5>Joana Williams</h5>
              <p>The printin and simply dummy text of bled it to make a type.</p>
             </div>
             <div class="team-social">
                 <ul>
                  <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                  <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                </ul>
             </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="team-2 text-start">
            <div class="team-image">
            <img class="img-fluid icon" src="images/team/08.jpg" alt="">
            </div>
            <div class="team-info mb-0">
             <div class="team-name">
              <span>Customer</span>
              <h5>Michael Bean</h5>
              <p>Printin and bled it to make a type simply dummy text of the.</p>
             </div>
             <div class="team-social">
                 <ul>
                  <li><a class="icon-1" href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a class="icon-2" href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a class="icon-3" href="#"><i class="fa fa-pinterest-p"></i></a></li>
                  <li><a class="icon-4" href="#"><i class="fa fa-behance"></i></a></li>
                </ul>
             </div>
            </div>
          </div>
        </div>
      </div> -->

        </div>
      </div>
    </div>
  </div>
</section>






<!-- Mirrored from themes.potenzaglobalsolutions.com/html/cardealer/about-01.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 31 May 2023 21:39:47 GMT -->