import { NgIf } from '@angular/common';
import { Component, OnInit, ElementRef,ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import Validation from '../utils/validation';
import { LogInService } from './password-reset.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})

export class PasswordResetComponent {
  form !: FormGroup;
  submitted = false;
  loader:any=false;
  @ViewChild('resetInput') resetInputElement!: ElementRef<HTMLInputElement>;
  constructor(private elementRef: ElementRef,private formBuilder: FormBuilder, public registration: LogInService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {

        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );
    
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loader=true;
      console.log(this.form.value);
      this.registration.saveUserData(this.form.value).subscribe((r:any) => {
        console.log(r.succesfull);
        if (r.successfull==true) {
          Swal.fire('Password Updated', 'Password is sucessfully updated!', 'success');
          this.submitted = false;
          this.form.reset();
        }else{
          Swal.fire('Sorry', 'Password is not Updated!', 'error');
        }
        this.loader=false
    })
  }
  ngAfterViewInit() {
    this.resetInputElement.nativeElement.focus();
    // Hack: Scrolls to top of Page after page view initialized
    /*let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }*/
  }
}
