import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Constants } from '../util/constant';
import { catchError } from 'rxjs/operators';
//import { CustomerService } from '../customer.service';


@Injectable({
  providedIn: 'root'
})
export class product_details {

  constructor(private http: HttpClient) {

  }
  RecentlyAdded(val1:any,val2:any,val3:any): Observable<any> {
    //console.log('123');
   // let params = new HttpParams();
//params = params.append('param_1', offset);
//params = params.append('param_2', 'value-2');
    const headers = new HttpHeaders()
      // .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
    //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
    return this.http.get(Constants.RecentlyAddes + '?BodyType=' + val1+ '&Make=' + val2+ '&Model=' + val3 , { headers: headers }).pipe(catchError(this.handleError));
  }
  saveUserData(id: any,): Observable<any> {
   // console.log('123');
   // let params = new HttpParams();
//params = params.append('param_1', offset);
//params = params.append('param_2', 'value-2');
    const headers = new HttpHeaders()
      // .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
    //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
    return this.http.get(Constants.DETAILS_URL + '?id=' + id , { headers: headers }).pipe(catchError(this.handleError));
  }
  formData(dataRequest:any,stock:any,make:any,model:any):Observable<any>{
    console.log('123');
    const headers = new HttpHeaders()
	 // .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
    //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
    return this.http.post(Constants.detail_contact+ '?Stock=' + stock+ '&Make=' + make+ '&Model=' + model ,
      JSON.stringify(dataRequest), { headers: headers }).pipe(catchError(this.handleError));
  }
  //advAgcActiveInactive(id: any, status: any): Observable<T:any> {
  // const headers = new HttpHeaders()
  //.set('app_user_id', this.customerService.getLogedInUserId())
  // .set('Content-Type', 'application/json')
  // .set('Authorization', 'Bearer ' + this.customerService.getToken());

  //return this.http.get(Constants.GET_ADMIN_DROPDOWN_URL + '/updateStatus?id=' + id + '&status=' + status, { headers: headers }).pipe(catchError(this.handleError));
  //}
  //saveDataProcess(): Observable<any> {
  /*  const headers = new HttpHeaders()
    .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.customerService.getToken());
    //console.log.log(JSON.stringify(company));
    return this.http.post(Constants.SAVE_DATA_RESPONSES_REQUEST,
      JSON.stringify(dataRequest), { headers: headers }).pipe(catchError(this.handleError));*/
  //}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.log.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}