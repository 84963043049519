import { environment } from "src/environments/environment.prod";


export class Constants{
  public static get GET_LOGIN_URL(): string{
    return environment.apiUrl + 'login';
  }
  public static get GET_LATEST_CAR_DETAIL_URL(): string{
    return environment.apiUrl + 'home';
  }
  public static get GET_CAR_DETAIL_URL(): string{
    return environment.apiUrl + 'details';
  }
  public static get ToTAL_RECORD_URL(): string{
    return environment.apiUrl + 'totalRecord';
  }
  public static get REGISTRATION_URL(): string{
    return environment.apiUrl + 'signup';
  }
  public static get DETAILS_URL(): string{
    return environment.apiUrl + 'car_detail';
  }
  public static get PROFILE(): string{
    return environment.apiUrl + 'loginValue';
  }
  public static get LOGIN(): string{
    return environment.apiUrl + 'signIn';
  }
  public static get forgetPassword(): string{
    return environment.apiUrl + 'forgetPassword';
  }
  public static get contact_US(): string{
    return environment.apiUrl + 'Contact';
  }
  public static get FAVORITES(): string{
    return environment.apiUrl + 'favorites';
  }
  public static get newArrivals(): string{
    return environment.apiUrl + 'NewArrivals';
  }
  public static get RecentlyAddes(): string{
    return environment.apiUrl + 'RecentlyAddes';
  }
  public static get Selectedfavorite(): string{
    return environment.apiUrl + 'Selectfavorite';
  }
  public static get Image_Exist(): string{
    return environment.apiUrl + 'ImgExist';
  }
  public static get Update_Profile(): string{
    return environment.apiUrl + 'UpdateProfile';
  }
  public static get detail_contact(): string{
    return environment.apiUrl + 'Detail_Form';
  }
  public static get Make_URL(): string{
    return environment.apiUrl + 'Make';
  }
  public static get Body_Type_URL(): string{
    return environment.apiUrl + 'Body_Type';
  }
}