<!-- <div id="top"></div> -->
<div class="app-loader" *ngIf="loader"></div>
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
    <div class="container">
       <div class="row text-center intro-title">
           <div class="col-md-6 text-md-start d-inline-block">
               <h1 class="text-white">Password Reset</h1>
             </div>
            <div class="col-md-6 text-md-end float-end">
           <ul class="page-breadcrumb">
             <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>             
             <li><span>Password Reset</span> </li>
           </ul>
        </div>
       </div>
    </div>
  </section>
  
  <!--=================================
   inner-intro -->
  
  
  <!--=================================
   login-form  -->
  
  <section class="login-form page-section-ptb">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
           <div class="section-title">
             
             <h2>Reset Password</h2>
             <div class="separator"></div>
           </div>
        </div>
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
      <div class="row justify-content-center">
      
        <div class="col-lg-6 col-md-12">
          <div class="gray-form clearfix">
            <div class="mb-3">
              <label class="form-label">Email *</label>
              <input formControlName="email" class="form-control" type="text" placeholder="Enter your email"
                name="email" [ngClass]="{'is-invalid':submitted && form.controls['email'].errors}" #resetInput>                
              <div *ngIf="submitted && form.controls['email'].errors" class="text-danger">
                <div *ngIf="form.controls['email'].errors['required']">Email is required</div>
                <div *ngIf="form.controls['email'].errors['email']">Email is invalid</div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">New Password* </label>
              <input formControlName="password" class="form-control"  type="password" placeholder="Password" name="Password"
                [ngClass]="{'is-invalid':submitted && form.controls['password'].errors}" >
              <div *ngIf="submitted && form.controls['password'].errors" class="text-danger">
                <div *ngIf="form.controls['password'].errors['required']">Password is required</div>
                <div *ngIf="form.controls['password'].errors['minlength']">
                  Password must be at least 6 characters
                </div>
                <div *ngIf="form.controls['password'].errors['maxlength']">
                  Password must not exceed 40 characters
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Confirm Password*</label>
              <input formControlName="confirmPassword" class="form-control" type="password" placeholder="Password"
                name="Password" [ngClass]="{'is-invalid':submitted && form.controls['confirmPassword'].errors}">
              <div *ngIf="submitted && form.controls['confirmPassword'].errors" class="text-danger">
                <div *ngIf="form.controls['confirmPassword'].errors['required']">
                  Confirm Password is required
                </div>
                <div *ngIf="form.controls['confirmPassword'].errors['matching']">
                  Confirm Password does not match
                </div>
              </div>
            </div>
           
              <div class="d-grid">
                <button type="submit" class="button red btn-block">Reset Password</button>
             
               </div>
               <p  style="margin-top: 20px;">Already have an account? please <a style="color: #db2d2e;" [routerLink]="['/Log-In']"> login here </a></p>
              </div>
          <!-- <div class="login-social text-center">
            <h5>Login with Social media</h5>
            <ul>
                <li><a class="fb button" href="#"><i class="fa fa-facebook"></i> Facebook</a></li>
                <li><a class="twitter button" href="#"><i class="fa fa-twitter"></i> Twitter</a></li>
                <li><a class="pinterest button" href="#"><i class="fa fa-google-plus"></i> google+</a></li>
            </ul>
          </div> -->
        </div>
      </div>
    </form>
    </div>
  </section>
