import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { RegistrationService } from './contact-us.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  constructor(private formBuilder: FormBuilder,private elementRef: ElementRef, public registration: RegistrationService,private route: ActivatedRoute) {

  }
  form !: FormGroup;
  loadAPI: any="";
  submitted = false;
  loader=false
 // url1:any = "assets/js/mega-menu/mega_menu.js";
 // url2:any =  "assets/js/owl-carousel/owl.carousel.min.js";
 // url3:any =   "assets/js/jquery-ui.js";
 // url4:any =   "assets/js/select/jquery-select.js";
 // url5:any =  "assets/js/custom.js";
  url:any=[ "assets/js/custom.js",];
  addUrl: any = [];
  id: any ='';
  
  ngOnInit(){
    let location = this.route.snapshot.params['locations']; 
    console.log(location);
    if(location){
      let el:any = document.getElementById('location');
      el.scrollIntoView();
      //this.scroll(locations);
      //locations.scrollIntoView();
    }else{
      let el:any = document.getElementById('contactUs');
      el.scrollIntoView();
    }
    this.loadAPI = new Promise(resolve => {
      console.log("resolving promise...");
      this.loadScript();
    });
    this.form = this.formBuilder.group(
      {
        Name: ['', Validators.required],
        Query: ['', Validators.required],
        message: [''],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
      
      },
    );
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  loadScript() {
    if(this.url.length>0){
      this.url.forEach((element:any) => {
        let node = document.createElement("script");
        node.src = element;
        node.type = "text/javascript";
        node.async = true;
        document.getElementsByTagName("head")[0].appendChild(node);
        this.addUrl.push(node);
      });
    }
  }
  onSubmit(){
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.loader=true;
    //console.log(this.form.value);
    this.registration.saveUserData(this.form.value).subscribe((r: any) => {
      //console.log(r.succesfull);
      if (r.successfull == true) {
        Swal.fire('Query', 'Query is succeccfully submitted!', 'success');
        this.submitted = false;
        this.form.reset();
        //console.log("hey");
      } else {
        Swal.fire('Query', 'Query is not Submitted!', 'error');
      }

      this.loader=false
    })
  }

    ngOnDestroy() {
      //console.log('222222');
      //console.log(this.addUrl);
      this.addUrl.forEach((link:any) => {
        link.parentNode.removeChild(link);
      });
    }
    
    ngAfterViewInit() {
      // Hack: Scrolls to top of Page after page view initialized
     /* let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }*/
    }
  
 
}
