<!-- <div id="top"></div> -->
<div class="app-loader" *ngIf="loader"></div>
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
    <div class="container">
       <div class="row text-center intro-title">
           <div class="col-md-6 text-md-start d-inline-block">
               <h1 class="text-white">Login </h1>
             </div>
            <div class="col-md-6 text-md-end float-end">
           <ul class="page-breadcrumb">
             <li><a href="#"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
             <li><a href="#">pages</a> <i class="fa fa-angle-double-right"></i></li>
             <li><span>Login</span> </li>
           </ul>
        </div>
       </div>
    </div>
</section>
  
  <!--=================================
   inner-intro -->
  
  
  <!--=================================
   login-form  -->
  
  <section class="login-form page-section-ptb">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
           <div class="section-title">
             <span>Log in with your id or social media </span>
             <h2>Login To Your Account</h2>
             <div class="separator"></div>
           </div>
        </div>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
      <div class="row justify-content-center">
       
        <div class="col-lg-6 col-md-12">
          <div class="gray-form clearfix">
            
            <div class="mb-3">
              <label class="form-label">Email *</label>
              <input formControlName="email" autofocus class="form-control" type="text" placeholder="Enter your email"
                name="email" [ngClass]="{'is-invalid':submitted && loginForm.controls['email'].errors}" #LoginInput>
              <div *ngIf="submitted && loginForm.controls['email'].errors" class="text-danger">
                <div *ngIf="loginForm.controls['email'].errors['required']">Email is required</div>
                <div *ngIf="loginForm.controls['email'].errors['email']">Email is invalid</div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Password* </label>
              <input formControlName="password" class="form-control" type="password" placeholder="Password" name="Password"
                [ngClass]="{'is-invalid':submitted && loginForm.controls['password'].errors}" >
              <div *ngIf="submitted && loginForm.controls['password'].errors" class="text-danger">
                <div *ngIf="loginForm.controls['password'].errors['required']">Password is required</div>
                <div *ngIf="loginForm.controls['password'].errors['minlength']">
                  Password must be at least 6 characters
                </div>
                <div *ngIf="loginForm.controls['password'].errors['maxlength']">
                  Password must not exceed 40 characters
                </div>
              </div>
            </div>
            <div class="mb-3">    
                <div class="remember-checkbox mb-4">
                    <input type="checkbox" name="one" id="one">
                    <label for="one"> Remember me</label>
                    <a  class="float-end" [routerLink]="['/Password-Reset']">Forgot Password?</a>
                  </div>
            </div>
            <div class="d-grid">
              <button type="submit" class="button red btn-block">Log in</button>
            </div>

          </div>
          <div class="mb-3">    
            <div class="remember-checkbox mb-4">
               
                <a  style="float: left;" [routerLink]="['/Registration']">Register Now</a>
              </div>
        </div>
          <!-- <div class="login-social text-center">
            <h5>Login with Social media</h5>
            <ul>
                <li><a class="fb button" href="#"><i class="fa fa-facebook"></i> Facebook</a></li>
                <li><a class="twitter button" href="#"><i class="fa fa-twitter"></i> Twitter</a></li>
                <li><a class="pinterest button" href="#"><i class="fa fa-google-plus"></i> google+</a></li>
            </ul>
          </div> -->
        </div>
      
      </div>
      </form>
    </div>
  </section>