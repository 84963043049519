<!-- <div id="top"></div> -->
<div class="app-header"></div>
<!--=================================
 inner-intro -->

<section class="inner-intro bg-17 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">About us </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="#"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>about us </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!--=================================
 inner-intro -->


<!--=================================
 welcome -->

<section class="welcome-4 page-section-ptb white-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)"
                          [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}"
                          required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="section-title">
                <span>Welcome to </span>
                <h2>Who are We?</h2>
                <div class="separator"></div>
                <p>Welcome to Premium Auto Gallery (PAG), your trusted local dealership proudly associated with CarMax
                  Africa Group. At PAG, we are passionate about providing you with an exceptional car-buying experience,
                  tailored to meet your needs. Our goal is to offer top-quality vehicles at competitive prices,
                  accompanied by exceptional customer service and support. We understand that buying a car is a
                  significant decision, so we take the time to listen to you and help you find the perfect vehicle that
                  fits your lifestyle and budget. Our aim is to make the car-buying process easy and stress-free,
                  ensuring you drive away in your new car feeling happy and confident in your purchase.</p>

                <p>We take pride in our diverse inventory, catering to every taste and budget. From sleek Sedans to
                  unfailingly solid SUVs, we have a wide range of vehicles to suit your preferences. Our experienced
                  experts are available to answer your questions and guide you through the entire process, from finding
                  the perfect vehicle to securing financing and providing after-sales support. We operate out of Durban,
                  Harare, Japan, Lusaka, Namibia, and Tanzania, providing our customers with convenient access to our
                  wide range of vehicles.</p>

                <p>Due to our success in Africa, we are expanding our services to Europe and establishing an office in
                  London. </p>
                <p>As one of the most established and authorized agents in Harare, Zimbabwe, we have earned a reputation
                  for excellence over the years. Our dedicated team is committed to providing you with the best possible
                  experience, and we continuously strive to improve our services and offerings. We understand the unique
                  needs and preferences of our African market, and we aim to exceed your expectations.</p>
                <p>Thank you for considering PAG for your next vehicle purchase. We eagerly await the opportunity to
                  serve you and help you find the perfect car to meet your needs.</p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<!--=================================
 welcome -->

<hr class="gray" />

<!--=================================
our History -->

<section class="our-history white-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <span>Premium Auto Gallery's 30 year timeline from the beginning</span>
          <h2>Our 15 Years Journey from the Beginning</h2>
          <div class="separator"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="timeline list-style-none">
          <li>
            <div class="timeline-badge">
              <h4>01</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>The Beginning </h5>
              </div>
              <div class="timeline-body">
                <p>– 2008 </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-badge">
              <h4>02</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>Acquisition Stock from Japan and a Yard in Harare with </h5>
              </div>
              <div class="timeline-body">
                <p>– 2009</p>
              </div>
            </div>
          </li>
          <li>
            <div class="timeline-badge">
              <h4>03</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>Our First Customer </h5>
              </div>
              <div class="timeline-body">
                <p>– 2009</p>
              </div>
            </div>
          </li>

          <li class="timeline-inverted">
            <div class="timeline-badge">
              <h4>04</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>Partnership with QTC Japan</h5>
              </div>
              <div class="timeline-body">
                <p>– 2011</p>
              </div>
            </div>
          </li>

          <li>
            <div class="timeline-badge">
              <h4>05</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>Partnership with CarMax Africa </h5>
              </div>
              <div class="timeline-body">
                <p>– 2020</p>
              </div>
            </div>
          </li>

          <li class="timeline-inverted">
            <div class="timeline-badge">
              <h4>06</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>Won the Award of Most Recognized ship in Harare</h5>
              </div>
              <div class="timeline-body">
                <p> - 2021</p>
              </div>
            </div>
          </li>

          <li>
            <div class="timeline-badge">
              <h4>07</h4>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h5>Our New Website </h5>
              </div>
              <div class="timeline-body">
                <p> – June 2023</p>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</section>

<!--=================================
our History-->

<section class="welcome-4 page-section-ptb white-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="section-title">
          <span>Welcome to </span>
          <h2>Setting us Apart and Ahead </h2>
          <div class="separator"></div>
          <p>At PAG, delivering exceptional customer service is our priority. We treat every customer with respect and
            dignity and firmly believe that "seeing is believing”. Which is why we have yards in the regions we operate,
            where you can visit and know what you are buying, getting firsthand experience, this is the quality which
            sets us apart from our online competitors. </p>

          <p>We understand that buying a car is a significant investment, and we want to ensure that you are entirely
            comfortable with their choice. That's why we also allow our customers to take the vehicles on a test drive,
            ensuring that you can feel the car's performance and quality before making the purchase.
          </p>
        </div>
      </div>
    </div>

  </div>
</section>

<section class="welcome-4 page-section-ptb white-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="section-title">
          <span>Welcome to </span>
          <h2>OUR VALUES </h2>
          <div class="separator"></div>
          <h4 style="text-align: left !important;">Social Responsibility</h4>
          <p>At Premium Auto Gallery (PAG), we take pride in being more than just a ship in Africa. In addition to our
            unwavering commitment to offering reliable, high-quality vehicles and providing excellent customer service,
            we are deeply committed to fulfilling our environmental and social responsibilities.</p>

          <p>One of our core initiatives is to make a positive impact on the lives of underprivileged children by
            supporting their education. We firmly believe that education is a transformative force that can empower and
            uplift communities. Therefore, we allocate a portion of our yearly profits to reputable NGOs that focus on
            funding schooling for children in destitute areas. By investing in education, we strive to create
            opportunities and a brighter future for these children, helping them break the cycle of poverty.
          </p>
          <p>Through our philanthropic efforts, we aim to give back to the community and contribute to the sustainable
            development of African societies. We recognize the importance of collective responsibility and the role
            businesses can play in making a meaningful difference.</p>
          <br>
          <h4 style="text-align: left !important;">Environmental Responsibility </h4>
          <p>We are passionate advocates for spreading the message across the world for preserving and protecting the
            environment. We believe that every individual has a role to play in making our planet a better place, and we
            strive to contribute to the cause.</p>
          <p>As a result, PAG place great importance on catalytic converters in their vehicles, which help protect the
            environment by reducing harmful emissions. Unfortunately, these converters are often targeted by thieves due
            to the precious metals contained within them. So, we emphasize the importance of proper disposal and
            recycling to prevent environmental damage and combat theft. As a responsible , we also educate our customers
            on the importance of catalytic converters and how to keep them safe.</p>
          <p>By choosing Premium Auto Gallery (PAG), you are not only gaining access to top-quality vehicles and
            exceptional service but also joining us in our mission to create a positive impact and improve lives.
            Together, let's drive change and build a better future for Africa.
          </p>
        </div>
      </div>
    </div>

  </div>
</section>

<!--=================================
Counter -->


<!--=================================
 Counter -->


<!--=================================
 testimonial -->



<!--=================================
 testimonial -->