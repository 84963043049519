import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HeaderService } from './header.service';
import { Router } from '@angular/router';

@Component({
  selector: '.app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  })
  export class HeaderComponent implements OnInit {
  constructor(public registration:HeaderService,public router:Router) {}
  userToken: any ='null';
  userData: any=''
  ngOnInit(): void {
    if(localStorage.getItem('Token')){
    this.userToken=localStorage.getItem('Token');
    this.userData=localStorage.getItem('UserData');
    console.log(this.userData)
    }else{
      this.userToken = 'null';
    }
  }

  logOut(){
    this.registration.saveUserData().subscribe((r:any) => {
      console.log(r);
        localStorage.setItem('Token','null')
        localStorage.setItem('UserData','')
        this.router.navigate(['/Log-In'])
  }
)}
  }
