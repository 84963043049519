 <header id="header">
    <div class="topbar" style="background-color: #03459E;">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="topbar-left text-lg-start text-center">
              <ul class="list-inline">
                <li> <i class="fa fa-envelope-o"> </i>support@premiumautogallery.com</li>
                <li> <i class="fa fa-clock-o" ></i> Monday – Friday 9:00 – 17:00 PM </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="topbar-right text-lg-end text-center">
              <ul class="list-inline">
                <li> <i class="fa fa-phone" ></i > +263 786 900 600</li>
                <li><a href="https://www.facebook.com/premiumautogallery.Harare/"><i class="fa fa-facebook"></i></a></li>
                <li><a href="https://twitter.com/Premiumauto23"><i class="fa fa-twitter"></i></a></li>
                <li><a href="https://www.instagram.com/premiumautogalleryafrica/"><i class="fa fa-instagram"></i></a></li>
                <li><a href="https://www.youtube.com/@premiumautogallery/"><i class="fa fa-youtube-play"></i></a></li>
                <li> <a *ngIf="userToken === 'null'" [routerLink]="['/Log-In']">Log In <i class="fa fa-sign-in"></i></a>
                  <a *ngIf="userToken !== 'null'" (click)="logOut()">Log Out <i class="fa fa-sign-in"></i></a> </li>
                <li><a href="/Profile" *ngIf="userToken !== 'null'"><i class="fas fa-user"></i></a></li>  
                <li>{{userData}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--=================================
 mega menu -->

    <div class="menu" style="height: 108px;">
      <!-- menu start -->
      <nav id="menu" class="mega-menu">
        <!-- menu list items container -->
        <section class="menu-list-items">
          <div class="container">
            <div class="row">
              <div class="col-md-12 position-relative">
                <!-- menu logo -->
                <ul class="menu-logo">
                  <li>
                    <a [routerLink]="['/Home']"><img id="logo_dark_img" src="../assets/images/logo-2.jpeg" alt="logo"> </a>
                  </li>
                </ul>
                <!-- menu links -->
                <ul class="menu-links">
                  <!-- active class -->
                  <li class="active"><a [routerLink]="['/Home']" style="color: black !important;"> Home </a>
                    <!-- drop down multilevel  -->
                    <!-- <ul class="drop-down-multilevel">
                      <li><a href="index.html">Home 1</a></li>
                      <li><a href="index-2.html">Home 2</a></li>
                      <li class="active"><a href="index-3.html">Home 3</a></li>
                      <li><a href="index-4.html">Home 4</a></li>
                      <li><a href="index-5.html">Home 5</a></li>
                      <li><a href="index-6.html">Home 6</a></li>
                      <li><a href="index-7.html">Home 7</a></li>
                      <li><a href="index-8.html">Home 8</a></li>
                      <li><a href="index-9.html">Home 9</a></li>
                      <li><a href="index-10.html">Home 10</a></li>
                      <li><a href="index-car-service.html">Car service </a></li>
                      <li><a href="index-car-directory.html"> Car directory</a></li>
                      <li><a href="index-car-listing.html">Car listing </a></li>
                      <li><a href="index-landing-page.html">landing page</a></li>
                    </ul> -->
                  </li>
              <li><a [routerLink]="['/About-Us']" style="color: black !important;">About Us <i
                    class="fa fa-angle-down fa-indicator"></i></a>
                <!-- drop down full width -->
               
              <!-- drop down multilevel  -->
              <ul class="drop-down-multilevel">
                <li><a [routerLink]="['/terms']">terms and conditions </a></li>
                <li><a [routerLink]="['/privacy-policy']">privacy policy </a></li>
                <li><a [routerLink]="['/Testimonial']">Testimonial</a></li>
                <li><a [routerLink]="['/team']">Leadership & Teams</a></li>
                <li><a [routerLink]="['/Faq']">FAQ</a></li>
              </ul>
            
              </li>
                  <li><a [routerLink]="['/Blog']" style="color: black !important;">blog</a>
                    <li><a [routerLink]="['/Service']" style="color: black !important;">Services</a>
                    <!-- drop down multilevel  -->
                   
                  </li>
                  <li><a [routerLink]="['/Stock-Listing']" style="color: black !important;">Stock listing </a>
                 
                    <!-- drop down multilevel  -->
                    <!-- <ul class="drop-down-multilevel">
                      <li><a href="listing-01.html">listing 01</a></li>
                      <li><a href="listing-02.html">listing 02</a></li>
                    </ul> -->
                  </li>
                  <li><a [routerLink]="['/Contact-Us']" style="color: black !important;"> Contact </a>
                    <!-- drop down multilevel  -->
                    <!-- <ul class="drop-down-multilevel right-menu">
                      <li><a href="contact-01.html">contact 01</a></li>
                      <li><a href="contact-02.html">contact 02</a></li>
                    </ul> -->
                </ul>
              </div>
            </div>
          </div>
        </section>
      </nav>
      <!-- menu end -->
    </div>
  </header>
