
<footer class="footer-3 footer-topbar light page-section-pt mt-4"> 
    <div class="container">
      <div class="row top">
        <div class="col-lg-3 col-md-12">
          <img class="img-fluid" id="logo_footer_dark" src="assets/images/logo-2.jpeg" alt="">
        </div>
        <div class="col-lg-5 col-md-12 py-4 py-lg-0">
          <div class="footer-nav text-lg-end text-center">
            <ul>
              <li><a [routerLink]="['/Home']">Home</a></li>
              <li><a [routerLink]="['/About-Us']">About us</a></li>
              <li><a [routerLink]="['/Service']">Service</a></li>
              <li><a [routerLink]="['/Blog']">Blog</a></li>
              <li><a [routerLink]="['/Contact-Us']">Contact us</a></li>

            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="social text-lg-end text-center">
            <ul>
              <li><a href="https://www.facebook.com/premiumautogallery.Harare/"><i class="fa fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/Premiumauto23"><i class="fa-brands fa fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/premiumautogalleryafrica/"><i class="fa fa-instagram"></i></a></li>
              <li><a href="https://www.youtube.com/@premiumautogallery/"><i class="fa fa-youtube-play"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-12">
          <div class="about-content">
            <h6 class="text-black">about us</h6>
            <p class="text-gray">Welcome to Premium Auto Gallery (PAG), your trusted local dealership proudly associated with CarMax Africa Group. At PAG, we are passionate about providing you with an exceptional car-buying experience, tailored to meet your needs.  </p>
          </div>
        </div>
    <div class="col-lg-7 col-md-12">
          <h6 class="text-black">Useful Links</h6>
          <div class="usefull-link mb-0">
            <div class="row">
              <div class="col-sm-4">
                <ul>
                  <li> <a [routerLink]="['/Faq']"> <i class="fa fa-angle-right"></i> FAQ </a></li>
                  <li> <a [routerLink]="['/Blog']"> <i class="fa fa-angle-right"></i> Blogs</a></li>
                  <li> <a [routerLink]="['/Testimonial']"> <i class="fa fa-angle-right"></i> Testimonials</a></li>
                </ul>
              </div>
              <div class="col-sm-4">
                <ul>
                  <li> <a [routerLink]="['/Contact-Us/locations']"> <i class="fa fa-angle-right"></i> Locations  </a></li>
                  <li> <a [routerLink]="['/Contact-Us']"> <i class="fa fa-angle-right"></i> Contact Us</a></li>
                  
                </ul>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="text-lg-start text-center">
              <p>©Copyright 2021 Premium Auto Gallery Developed by Premium Auto Gallery <a href="https://premiumautogallery.com/"></a></p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <ul class="list-inline text-lg-end text-center">
              <li><a [routerLink]="['/privacy-policy']">privacy policy </a>|</li>
              <li><a [routerLink]="['/terms']">terms and conditions </a>|</li>
              <li><a [routerLink]="['/Contact-Us']">contact us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
   
  </footer>
  <div class="car-top">
    <span><img src="/assets/images/ing/car1.png" alt=""></span>
  </div>
  <div class="whtsapp-icon">
    <span><a href="https://wa.me/+263786900600?text=Hello%20World" target="_blank"><img src="/assets/images/ing/whatsapp.png" alt=""></a></span>
  </div>