<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-3 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">Contact us </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>Contact us </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container" id="location">
  <div class="row">


    <div class="col" id="cont1" >
      <img src="assets\images\ing\south africa flag.jpg" alt="" style=" margin-top: 49px;                                                                          
         
          height: 106px; border-radius:53%;margin-left: 34%;" id="contact-image">

      <div>
        <h5 style="margin-top: 17px;width: 100%;text-align: center;" class="c3">Durban office </h5>
        <span style="width: 100%;text-align: center;float: left;" class="c1">65 Masabalala Yengwa Avenue Unit 2, Durban Station
          Warehouse Area, Stamford Hill, Durban, KWA-ZULU-NATAL, 4001</span><br>
        <span style="width: 100%;text-align: center;float: left;">+27 31 579 1239</span><br>
        <span style="width: 100%;text-align: center;float: left;">+27 82 578 6237</span><br>
        <span style="width: 100%;text-align: center;float: left;">info@carmaxafrica.com</span>
        <span style="width: 100%;text-align: center;float: left;">VIEW DURBAN STOCK</span>
      </div>



    </div>
    <div class="col" id="cont1">
      <img src="assets\images\ing\Japan flag.jpg" alt="" style="    margin-top: 49px;
         
          height: 106px; border-radius:53%;margin-left: 34%;" id="contact-image">

      <div>
        <h5 style="margin-top: 17px;width: 100%;text-align: center;" class="c3"> JAPAN OFFICE </h5>

        <span style="width: 100%;text-align: center;float: left;" class="c2">1880-1, Matsukawado-cho, Kasugai-shi, Aichi,
          Japan</span><br>
        <span style="width: 100%;text-align: center;float: left;" class="c2">+81 567 690 211</span><br>
        <span style="width: 100%;text-align: center;float: left;" class="c2">
          info@qtccars.com</span><br>
        <span style="width: 100%;text-align: center;float: left;" class="c2">VIEW JAPAN STOCK</span>
      </div>



    </div>
    <div class="col">
      <img src="assets\images\ing\zimbawawe flag.png" alt="" style="    margin-top: 49px;
         
          height: 106px; border-radius:53%;margin-left: 34%;" id="contact-image1">

      <div>
        <h5 style="margin-top: 17px;width: 100%;text-align: center;"> Harare Office </h5>
        <span style="width: 100%;text-align: center;float: left;">18218 Samora Machel Ave, Harare</span><br>
        <span style="width: 100%;text-align: center;float: left;">+263 78 690 0600</span><br>
        <span style="width: 100%;text-align: center;float: left;">info@premiumautogallery.com</span><br>
        <span style="width: 100%;text-align: center;float: left;">VIEW HARARE STOCK</span>

      </div>



    </div>
  </div>

</div>

<!--=================================
   inner-intro -->


<!--=================================
   contact -->

<section class="contact-2 page-section-ptb white-bg" id="contactUs">
  <div class="container">
    <div class="row">
      <div class="col-md-12 justify-content-center">
        <div class="section-title">
          <span>We’d Love to Hear From You</span>
          <h2>LET'S GET IN TOUCH!</h2>
          <div class="separator"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 col-sm-12 mb-lg-0 mb-1">
        <div class=" row">
          <div class="col-md-12">
            <form class="form-horizontal" id="contactform" role="form"
             [formGroup]="form" (ngSubmit)="onSubmit()" novalidate
              >
              <h5>We have completed over a 1000+ projects for five hundred clients. Give us your next project.</h5>
              <p>It would be great to hear from you! If you got any questions, please do not hesitate to send us a
                message. We are looking forward to hearing from you! We reply within 24 hours !</p>
              <div id="formmessage" class="form-notice" style="display:none;">Success/Error Message Goes Here</div>
              <div class="contact-form">
                <div class="mb-4">
                  <input formControlName="Name" id="contactform_name" type="text" placeholder="Name*" class="form-control" name="name"
                  [ngClass]="{'is-invalid':submitted && form.controls['Name'].errors}">
                  <div *ngIf="submitted && form.controls['Name'].errors" class="text-danger">
                    <div *ngIf="submitted && form.controls['Name'].errors['required']">First Name is required</div>
                  </div>
                </div>
                <div class="mb-4">
                  <input formControlName="email" id="contactform_email" type="" placeholder="Email*" class="form-control" name="email"
                  [ngClass]="{'is-invalid':submitted && form.controls['email'].errors}">
                  <div *ngIf="submitted && form.controls['email'].errors" class="text-danger">
                    <div *ngIf="form.controls['email'].errors['required']">Email is required</div>
                    <div *ngIf="form.controls['email'].errors['email']">Email is invalid</div>
                  </div>
                </div>
                </div>
                <div class="col-md-4 pb-3" style="padding-left: 0px;">
                  <div class="selected-box">
                    <select class="form-control mb-1"  formControlName="Query" [ngClass]="{'is-invalid':submitted && form.controls['Query'].errors}">
                      <option value="">Type of Query</option>
                      <option value="Support">Support</option>
                      <option value="Inquiry">Inquiry</option>
                      <option value="Complaint">Complaint</option>
                    </select>
                    <div *ngIf="submitted && form.controls['Query'].errors" class="text-danger">
                    Query is required
                    </div> 
                  </div>
                </div>
                <div class="mb-4">
                  <input id="contactform_phone" formControlName="phoneNumber" type="number" placeholder="Phone*" class="form-control" name="phone" 
                  [ngClass]="{'is-invalid':submitted && form.controls['phoneNumber'].errors}">
                  <div *ngIf="submitted && form.controls['phoneNumber'].errors" class="text-danger">
                    <div *ngIf="form.controls['phoneNumber'].errors['required']">Phone Number is required</div>
                  </div>
                </div>
                <div class="mb-4">
                  <textarea  formControlName="message" id="contactform_message" class="form-control input-message" placeholder="Comment*" rows="7"
                    name="message"></textarea>
                </div>
                <div class="d-grid pt-3 pb-3">
                  <button  type="submit"  class="button red btn-block"> Send your
                    message </button>
                </div>

            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12 pl-5">
        <div class="feature-box-3 grey-border">
          <div class="icon">
            <i class="fa fa-map-marker"></i>
          </div>
          <div class="content">
            <h5>Address </h5>
            <p>18218 Samora Machel Ave, Harare </p>
          </div>
        </div>
        <div class="feature-box-3 grey-border">
          <div class="icon">
            <i class="fa fa-phone"></i>
          </div>
          <div class="content">
            <h5>Phone </h5>
            <p>+263 786 900 600 </p>
          </div>
        </div>
        <div class="feature-box-3 grey-border mb-0">
          <div class="icon">
            <i class="fa fa-envelope-o"></i>
          </div>
          <div class="content">
            <h5>Email </h5>
            <p> support@premiumautogallery.com </p>
          </div>
        </div>
        <div class="opening-hours mt-3 gray-bg">
          <h6>opening hours</h6>
          <ul class="list-style-none">
            <li><strong>Monday</strong> <span> 9:00 AM to 17:00 PM</span></li>
            <li><strong>Tuesday </strong> <span> 9:00 AM to 17:00 PM</span></li>
            <li><strong>Wednesday </strong> <span> 9:00 AM to 17:00 PM</span></li>
            <li><strong>Thursday </strong> <span> 9:00 AM to 17:00 PM</span></li>
            <li><strong>Friday </strong> <span> 9:00 AM to 17:00 PM</span></li>
            <li><strong>Saturday</strong> <span class="text-red"> Closed</span></li>
            <li><strong>Sunday</strong> <span class="text-red"> Closed</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 col-sm-12 mb-lg-0 mb-1  mt-4" >
        <div class="container-fluid" style="padding-left: 0px;height:100%">
          <iframe style="width: 100%; height:100%"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.2301012259636!2d31.039982774450475!3d-17.827845076304154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a5fba389b36f%3A0x10fc371a022c086!2sCar%20Junction%20Zimbabwe%20-%20Japanese%20Used%20Cars%20%26%20Spare%20Parts!5e0!3m2!1sen!2s!4v1698241797030!5m2!1sen!2s"
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12" >
        <table class="mt-4 mb-0">
                  <tbody>
                    <tr>
                      <td colspan="4" width="623">
                        <h6 class="text-red" style="text-align: center;"><strong><u>For Sales &amp; Support Contact</u></strong> :-
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td width="128" style="text-align: center;">Mr Jawad</td>
                      <td width="230" style="text-align: center;">Jawad@premiumautogallery.co.zw</td>
                    </tr>
                    <tr>
                      <td colspan="4" width="623">
                        <h6 class="text-red" style="text-align: center;"><u>For Office Assistance &amp; Shipping
                            Contact:-</u><strong><u> </u></strong></h6>
                      </td>
                    </tr>
                    <tr>
                      <td width="128" style="text-align: center;">Miss Noreen</td>
                      <td width="230" style="text-align: center;">noreen@premiumautogallery.co.zw</td>
                    </tr>
                    <tr>
                      <td colspan="4" width="623">
                        <h6 class="text-red" style="text-align: center;"><u>For Website Feedback &amp; Complains Contact:-</u></h6>
                      </td>
                    </tr>
                    <tr>
                      <td width="128" style="text-align: center;">Ms Nazia</td>
                      <td width="230" style="text-align: center;">pagit@premiumautogallery.co.zw</td>
                    </tr>
                    <tr>
                      <td colspan="4" width="623">
                        <h6 class="text-red" style="text-align: center;"><u>Head of Logistics &amp; Shipping Durban Office.-</u></h6>
                      </td>
                    </tr>
                    <tr>
                      <td width="128" style="text-align: center;padding: 0px">Mr Noman Hussain</td>
                      <td width="230" style="text-align: center;">noman@premiumautogallery.co.zw</td>
                    </tr>
                  </tbody>
                </table>
            </div>
    </div>
    
  </div>
  
</section>

<style>
  td {
    border: 1px solid rgb(204, 202, 202);
  }
</style>

<section
  class=" col-4 elementor-section elementor-top-section elementor-element elementor-element-bb83d9c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
  style="margin-top: 15px;"data-id="bb83d9c" data-element_type="section">
  <div class="container">
    <div style="margin-bottom: 60px; margin-top: -60px;" class="elementor-container elementor-column-gap-default">
      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a1e596b"
        data-id="a1e596b" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-d28c847 elementor-widget elementor-widget-text-editor"
            data-id="d28c847" data-element_type="widget" data-widget_type="text-editor.default">
            <div class="elementor-widget-container">
              <h2> </h2>
              
            </div>
          </div>
        </div>
      </div>
      <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-362d80c"
        data-id="362d80c" data-element_type="column">
        <div class="elementor-widget-wrap elementor-element-populated">
          <section
            class="elementor-section elementor-inner-section elementor-element elementor-element-9af165d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="9af165d" data-element_type="section">
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ef49384"
                data-id="ef49384" data-element_type="column">
                <div class="elementor-widget-wrap">
                </div>
              </div>
            </div>



            <!--=================================
   contact -->


            <!--=================================
   contact-map -->
  </section>
            