import { NgIf } from '@angular/common';
import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { Router } from '@angular/router';
import Validation from '../utils/validation';
import { RegistrationService } from './registration.service';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  form !: FormGroup;
  submitted = false;
  loader:any =false;
  countryModel: any = 'ZA';
  @ViewChild('emailInput') emailInputElement!: ElementRef<HTMLInputElement>;
  constructor(private formBuilder: FormBuilder, public registration: RegistrationService, private router: Router) { 
    function passwordValidator(control :any) {
      const password = control.value;
    
      // Check if the password contains at least one capital letter, one special character, and one number
      const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/;
    
      return regex.test(password) ? null : { invalidPassword: true };
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {

        month: ['', Validators.required],
        year: ['', Validators.required],
        country: ['', Validators.required],
        gender: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        Day: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40),
            this.passwordValidator
          ]
        ],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue]
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loader=true;
    //console.log(this.form.value);
    this.registration.saveUserData(this.form.value).subscribe((r: any) => {
      //console.log(r.succesfull);
      if (r.successfull) {
        Swal.fire('Register', 'Usser is succeccfully Registered!', 'success');
        this.submitted = false;
        this.form.reset();
        this.router.navigate(['Log-In']);
        //console.log("hey");
      } else {
        Swal.fire('Register', r.message, 'error');
        this.loader=false
        return;
      }

      this.loader=false
    })
    
  }



  passwordValidator(control  :any){
    const password = control.value;
  
    // Check if the password contains at least one capital letter, one special character, and one number
    const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/;
  
    return regex.test(password) ? null : { invalidPassword: true };
  }




  usedCarType: boolean = true;
  loadAPI: any = "";
  url4: any = "assets/js/select/jquery-select.js";

  usedCarTypeFun(val: any) {
    this.usedCarType = val;
  }
  loadScript() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url4;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
  ngAfterViewInit() {
    this.emailInputElement.nativeElement.focus();
    // Hack: Scrolls to top of Page after page view initialized
   /* let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }*/
  }

} 
