import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TeamComponent } from './team/team.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ServiceComponent } from './service/service.component';
import { FaqComponent } from './faq/faq.component';
import { LogInComponent } from './log-in/log-in.component';
import { RegistrationComponent } from './registration/registration.component';
import { BlogComponent } from './blog/blog.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import  {  FormsModule,  ReactiveFormsModule  }  from  '@angular/forms';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import {HttpClientModule} from "@angular/common/http";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    LoaderComponent,
 TeamComponent,
 TestimonialComponent,
 TermsComponent,
 PrivacyPolicyComponent,
 ContactUsComponent,
 ServiceComponent,
 FaqComponent,
 LogInComponent,
 RegistrationComponent,
 BlogComponent,
 PasswordResetComponent,
 ProductListingComponent,
 BlogDetailComponent,
 ProductDetailsComponent,
 ProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSliderModule,
  ], 
  providers: [],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule { }
