import { Component, OnInit, Output } from '@angular/core';
import { product_listing } from './product_listing.service';
import { homeService } from '../home/home.service';
import { Router } from '@angular/router';
import { Options,LabelType } from '@angular-slider/ngx-slider';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent {
  loader: any = false;
  UserId: any ='';
  userToken: any;
  minValue: number = 1000;
  maxValue: number = 100000;
  options: Options = {
    floor: 1000,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return "$" + value;
      }
    }
  };
  exteriorColorValues: any = [{ 'value': 'Ruby Red Matelic', 'checked': false }, { 'value': 'Racing Yellow', 'checked': false }, { 'value': 'Gaurds Red', 'checked': false }, { 'value': 'Aqua Blue Matelic', 'checked': false }, { 'value': 'White', 'checked': false }, { 'value': 'Dark Blue Metallic ', 'checked': false }, { 'value': 'Pearl ', 'checked': false }]
  modelValues: any = [{ 'value': '3-Series', 'checked': false }, { 'value': 'Boxter', 'checked': false }, { 'value': 'Carrera', 'checked': false }, { 'value': 'F-type', 'checked': false }, { 'value': 'GT-R', 'checked': false }, { 'value': 'GTS', 'checked': false }, { 'value': 'M6', 'checked': false }, { 'value': 'Macan', 'checked': false }, { 'value': 'Mazda', 'checked': false }, { 'value': 'RLX', 'checked': false }, { 'value': 'FIT', 'checked': false }, { 'value': 'Aqua', 'checked': false }]
  conditionValues: any = [{ 'value': 'Brand New', 'checked': false }, { 'value': 'Sligthly Used', 'checked': false }, { 'value': 'Used', 'checked': false }]
  bodyValues: any = [{ 'value': '2 Door', 'checked': false }, { 'value': '4 Door', 'checked': false }, { 'value': 'Convertible', 'checked': false }, { 'value': 'Sedan', 'checked': false }, { 'value': 'Sport', 'checked': false },{ 'value': 'Hatchback', 'checked': false },{ 'value': 'Wagon', 'checked': false },{ 'value': 'Van', 'checked': false },{ 'value': 'Truck', 'checked': false },{ 'value': 'SUV', 'checked': false }]
  transmissionValues: any = [{ 'value': '5-Speed manual', 'checked': false }, { 'value': '6-Speed manual', 'checked': false }, { 'value': '6-Speed Automatic', 'checked': false }, { 'value': '6-Speed Semi-Auto', 'checked': false }, { 'value': '7-Speed PDK', 'checked': false }, { 'value': '8-Speed Automatic', 'checked': false }, { 'value': '8-Speed Triptronic', 'checked': false }]
  yearsValues: any = [{ 'value': '2008', 'checked': false }, { 'value': '2009', 'checked': false }, { 'value': '2010', 'checked': false }, { 'value': '2011', 'checked': false }, { 'value': '2012', 'checked': false }, { 'value': '2013', 'checked': false }, { 'value': '2014', 'checked': false }, { 'value': '2015', 'checked': false }, { 'value': '2016', 'checked': false }, { 'value': '2017', 'checked': false }, { 'value': '2018', 'checked': false }, { 'value': '2019', 'checked': false }, { 'value': '2020', 'checked': false }, { 'value': '2021', 'checked': false }, { 'value': '2022', 'checked': false }, { 'value': '2023', 'checked': false }]
  makeValueSelected: any = [];
  locationValueSelected: any = [];
  usedCarType: boolean = true;
  loadAPI: any = "";
  offset: any = 25;
  pageNo: any = 1;
  Search: any = '';
  type: any = '';
  constructor(public homePageService:homeService,public ProductListing: product_listing, private route: ActivatedRoute, private router: Router) { }
  //url1:any = "assets/js/mega-menu/mega_menu.js";
  //url2:any =  "assets/js/owl-carousel/owl.carousel.min.js";
  //url3:any =   "assets/js/jquery-ui.js";
  //url5:any =  "assets/js/custom.js";
  // url4:any =   "assets/js/select/jquery-select.js";
  url: any = ["assets/js/custom.js"];
  addUrl: any = [];
  data: any = '';
  Record: any = 0;
  totalPages: any = 0;
  SearchVal: any = '';
  InnerSearchVal :any = '';
  make: any = '';
  HMake: any = '';
  counter = Array;
  homeTitle:any = 'All';
  
  ngOnInit() {
    this.userToken=localStorage.getItem('Token');
    this.UserId = localStorage.getItem('UserID');
    this.List_of_body_Type();
    if(this.UserId){
      this.userSelectedFavorite(this.UserId);
    }
    


    this.loadAPI = new Promise(resolve => {
      //console.log("resolving promise...");
      this.loadScript();
      // console.log(this.minValue)
      // console.log(this.maxValue)
      // console.log(this.type)
      
      if (localStorage.getItem('InnerSearchVal')) {
        this.InnerSearchVal = localStorage.getItem('InnerSearchVal');
        let InnerSearchVal = JSON.parse(this.InnerSearchVal);
        //console.log(valSearch);
        //this.yearsValueSelected.push(valSearch.year);
        //let val = {'checked':true};
        // this.yearValues(val,valSearch.year);
        if (InnerSearchVal.InnerSearchYear) {
          this.yearsValueSelected=InnerSearchVal['InnerSearchYear']
        }
        if (InnerSearchVal.InnerSearchBody || this.type) {
          this.bodyValueSelected=InnerSearchVal['InnerSearchBody']
        }
        if (InnerSearchVal.InnerSearchcondition) {
          this.conditionValueSelected=InnerSearchVal['InnerSearchcondition']
        }
        if (InnerSearchVal.InnerExteriorSearch) {
          this.exteriorColorSlectedValues = InnerSearchVal['InnerExteriorSearch']
        }
        if (InnerSearchVal.InnerModelSearch) {
          this.exteriorColorSlectedValues = InnerSearchVal['InnerModelSearch']
        }
        if (InnerSearchVal.min){
          this.minValue = InnerSearchVal.min;
        }
        if (InnerSearchVal.max){
          this.maxValue = InnerSearchVal.max;
        }
        //console.log(valSearch);       

      }

      if (localStorage.getItem('searchVal')) {
        this.SearchVal = localStorage.getItem('searchVal');
        let valSearch = JSON.parse(this.SearchVal);
        //console.log(valSearch);
        //this.yearsValueSelected.push(valSearch.year);
        //let val = {'checked':true};
        // this.yearValues(val,valSearch.year);
        if (valSearch.year) {
          this.yearsValues.forEach((item: any) => {
            if (valSearch.year === item.value) {
              item.checked = true;
              this.yearsValueSelected.push(item.value);
            }
          })
        }
        if (valSearch.body || this.type) {
          this.homeTitle = valSearch.body;
          //console.log(valSearch.body);
          this.bodyValues.forEach((item: any) => {
            console.log(item);
            if ((this.homeTitle === item.value) || (this.type === item.value)) {
              console.log('1111111');
              item.checked = true;
              this.bodyValueSelected.push(item.value);
            }
          })
        }
        if (valSearch.model) {
          this.modelValues.forEach((item: any) => {
            if (valSearch.model === item.value) {
              item.checked = true;
              this.modelSlectedValues.push(item.value);
            }
          })
        }
        if (valSearch.make) {
          this.makeValueSelected.push(valSearch.make);
        }
        if (valSearch.location) {
          this.homeTitle = valSearch.location;
         // console.log(this.homeTitle);
          if(valSearch.location !== 'all'){
            this.locationValueSelected.push(valSearch.location);
          }
          
        }
        if (valSearch.make) {
          this.make = valSearch.make;
        } else if (this.HMake) {
          this.make = this.HMake;
        }
        if (valSearch.min){
          this.minValue = valSearch.min;
        }
        if (valSearch.max){
          this.maxValue = valSearch.max;
        }
       
        //console.log(valSearch);       

      }
      
      this.product_listing();
      //console.log(test)
    });
  }
  userFavorieVacs:any=[];
  userSelectedFavorite(values:any){
    //this.favorie_cust_id=20
    this.ProductListing.Selectedfavorite(values).subscribe((r: any) => {
      if(r.successfull){
        this.userFavorieVacs = r.content;
       // console.log(this.Record)
      }
    })
  }
  totalrecord(){
    this.ProductListing.TotalRecord().subscribe((r: any) => {
      if(r.successfull){
        this.Record = r.Record;
       // console.log(this.Record)
      }
    })    
}

checkIfImageExists(name:any) {
  name.forEach( (item: any) =>{
    //this.userFavorieVacs;
    let obj = this.userFavorieVacs.find((o:any) => o.VEHICLE_ID === item.VEHICLE_ID);
    if(obj){
      item.FavVac = true;
    }else{
      item.FavVac = false;
    }
   //console.log(obj);

    /*let imagesPath = 'assets/images/255x188/S'+item.Stock_No+'D.jpg';
          const img = new Image();
          img.src = imagesPath;
    img.onload = () => {
      //console.log(item.VEHICLE_ID);
      this.Image_Exist('S'+item.Stock_No+'D',item.VEHICLE_ID);
      item.img = true;
     }
    img.onerror = () => {
      item.img = false;
     
    }*/
    this.data.push(item);
  })
//console.log(this.data);
}


// USAGE
/* result = this.checkIfImageExists(`assets\images'\'255x188\{{item.Stock_No}}.jpg`, (exists:any) => {
  if (exists) {
    console.log('Image exists. ')
   // this.img=true
  } else {
    console.error('Image does not exists.')
    //this.img=false
  }
});*/
  product_listing() {
    this.loader = true
    this.Record = 0;
    this.data = [];
    this.totalrecord();
    //var offset=2;
    //var pageNo=2;
    console.log(this.locationValueSelected);
    this.ProductListing.saveUserData(this.offset, this.pageNo, this.minValue, this.maxValue, "'" + this.yearsValueSelected.join("','") + "'", "'" + this.conditionValueSelected.join("','") + "'", "'" + this.bodyValueSelected.join("','") + "'", "'" + this.modelSlectedValues.join("','") + "'", "'" + this.exteriorColorValues.join("','") + "'", this.Search, this.make, this.locationValueSelected ).subscribe((r: any) => {
     // console.log(r.message);
      if (r.successfull) {
       // this.data = r.content;
        
          
           // setTimeout(()=>{
        this.checkIfImageExists(r.content);
        //console.log((r.content).length,'hello')
        
        // console.log(this.data);
        // console.log(this.Record);
        this.totalPages = Math.ceil(r.Record / this.offset);
        console.log(r.Record)
        //console.log(this.totalPages);
       // this.locationValueSelected = [];
       // this.makeValueSelected = [];
      }
      this.loader = false;
    })
  }
  SearchValues() {
    this.pageNo = 1;
    this.product_listing();
  }
  pageNoViwe(val: any) {
    this.pageNo = val;
    this.product_listing();
  }
  previousPage() {
    if (this.pageNo === 1) {
      return;
    }
    this.pageNo = this.pageNo - 1;
    this.product_listing();
  }
  nextPage() {
    if (this.pageNo === this.totalPages) {
      return;
    }
    this.pageNo = this.pageNo + 1;
    this.product_listing();
  }
  offsetValueChange(val: any) {
    this.offset = val.value;
    this.product_listing();
  }
  PriceRangeChange() {
  }
  submitSearch(val: any) {
    this.Search = val.value
   // console.log(this.Search);
  }
  PostData(val: any,val1:any,val2:any,val3:any) {
    //localStorage.setItem('BodyDetail', val1);
    //localStorage.setItem('MakeDetail', val2);
    //localStorage.setItem('ModelDetail', val3);
    this.router.navigate(['Stock-Detail', val]);
    //console.log(val)
  }
  usedCarTypeFun(val: any) {
    this.usedCarType = val;
  }

  loadScript() {
    if (this.url.length > 0) {
      this.url.forEach((element: any) => {
        let node = document.createElement("script");
        node.src = element;
        node.type = "text/javascript";
        node.async = true;
        document.getElementsByTagName("head")[0].appendChild(node);
        this.addUrl.push(node);
      });
    }
  }
  yearsValueSelected: any = [];
  yearValues(check: any, val: any) {
    //console.log(val);
    if (check.checked) {
      this.yearsValueSelected.push(val);
    } else {
      let index = this.yearsValueSelected.indexOf(val);
      if (index > -1) {
        this.yearsValueSelected.splice(index, 1);
      }
    }
    // console.log(this.yearsValueSelected);
  }
  yearSelectAll(check: any) {
    if (check.checked) {
      this.yearsValues.forEach((item: any) => {
        item.checked = true;
        this.yearsValueSelected.push(item.value);
      })
    } else {
      this.yearsValueSelected = [];
      this.yearsValues.forEach((item: any) => {
        item.checked = false;
      })
    }
    //this.yearsValueSelected
  }
  conditionValueSelected: any = []
  conditionValue(check: any, val: any) {
    if (check.checked) {
      this.conditionValueSelected.push(val);
      //console.log(this.conditionValueSelected)
    } else {
      let index = this.conditionValueSelected.indexOf(val);
      if (index > -1) {
        this.conditionValueSelected.splice(index, 1);
        // console.log(this.conditionValueSelected)
      }
    }
    //console.log(this.conditionValueSelected);
  }
  conditionSelectAll(check: any) {
    if (check.checked) {
      this.conditionValues.forEach((item: any) => {
        item.checked = true;
        this.conditionValueSelected.push(item.value);
      })
    } else {
      this.conditionValueSelected = [];
      this.conditionValues.forEach((item: any) => {
        item.checked = false;
      })
    }
    //this.yearsValueSelected
  }
  bodyValueSelected: any = []
  bodyValue(check: any, val: any) {
    if (check.checked) {
      this.bodyValueSelected.push(val);
      //console.log(this.conditionValueSelected)
    } else {
      let index = this.bodyValueSelected.indexOf(val);
      if (index > -1) {
        this.bodyValueSelected.splice(index, 1);
        // console.log(this.conditionValueSelected)
      }
    }
   // console.log(this.bodyValueSelected);
  }
  bodySelectAll(check: any) {
    if (check.checked) {
      this.bodyValues.forEach((item: any) => {
        item.checked = true;
        this.bodyValueSelected.push(item.value);
      })
    } else {
      this.bodyValueSelected = [];
      this.bodyValues.forEach((item: any) => {
        item.checked = false;
      })
    }
    //this.yearsValueSelected
  }
  transmissionSelectedValues: any = []
  transmissionValue(check: any, val: any) {
    if (check.checked) {
      this.transmissionSelectedValues.push(val);
      //console.log(this.conditionValueSelected)
    } else {
      let index = this.transmissionSelectedValues.indexOf(val);
      if (index > -1) {
        this.transmissionSelectedValues.splice(index, 1);
        // console.log(this.conditionValueSelected)
      }
    }
   // console.log(this.transmissionSelectedValues);
  }
  transmissionSelectAll(check: any) {
    if (check.checked) {
      this.transmissionValues.forEach((item: any) => {
        item.checked = true;
        this.transmissionSelectedValues.push(item.value);
      })
    } else {
      this.transmissionSelectedValues = [];
      this.transmissionValues.forEach((item: any) => {
        item.checked = false;
      })
    }
    //this.yearsValueSelected
  }
  modelSlectedValues: any = []
  modelValue(check: any, val: any) {
    if (check.checked) {
      this.modelSlectedValues.push(val);
      //console.log(this.conditionValueSelected)
    } else {
      let index = this.modelSlectedValues.indexOf(val);
      if (index > -1) {
        this.modelSlectedValues.splice(index, 1);
        // console.log(this.conditionValueSelected)
      }
    }
    //console.log(this.modelSlectedValues);
  }
  modelSelectAll(check: any) {
    if (check.checked) {
      this.modelValues.forEach((item: any) => {
        item.checked = true;
        this.modelSlectedValues.push(item.value);
      })
    } else {
      this.modelSlectedValues = [];
      this.modelValues.forEach((item: any) => {
        item.checked = false;
      })
    }
    //this.yearsValueSelected
  }
  exteriorColorSlectedValues: any = []
  exteriorColorValue(check: any, val: any) {
    if (check.checked) {
      this.exteriorColorSlectedValues.push(val);
      //console.log(this.conditionValueSelected)
    } else {
      let index = this.exteriorColorSlectedValues.indexOf(val);
      if (index > -1) {
        this.exteriorColorSlectedValues.splice(index, 1);
        // console.log(this.conditionValueSelected)
      }
    }
    //console.log(this.exteriorColorSlectedValues);
  }
  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  favorute(values:any, check: any){
    this.loader=true;
    //console.log(check);
    //this.UserId = localStorage.getItem('UserID');
    this.ProductListing.savefavoriteData(this.UserId, values.VEHICLE_ID, check).subscribe((r:any) => {
      //
      if(r.successfull === true){
        //this.userFavorieVacs = r.content;
          this.data.forEach( (item: any, index:any) =>{
          if(item.VEHICLE_ID === values.VEHICLE_ID){
           // console.log(item.VEHICLE_ID);
            if(check){
            //  console.log(index);
              item.FavVac = false;
              this.data[index].FavVac = true;
            }else{
             //console.log('222222222');
              this.data[index].FavVac = false;
            }
           // console.log(this.data)
          }
        })  
        //console.log(this.data);
        Swal.fire('Favorite', r.message, 'success');
       // console.log(true)
      }else{
        Swal.fire('Favorite', r.message, 'error');
        //console.log(false)
      }
      this.loader=false;
    })
   }
   Image_Exist(ImageName:any,VecId:any){
    //this.favorie_cust_id=20
    this.ProductListing.ImgExist(ImageName,VecId).subscribe((r: any) => {
      if(r.successfull){
       
        //console.log('001')
      }
    })
  }
  ngOnDestroy() {
    //console.log('222222');
    //console.log(this.addUrl);
    this.addUrl.forEach((link: any) => {
      link.parentNode.removeChild(link);
    });
    localStorage.setItem('InnerSearchVal','');
    localStorage.setItem('searchVal', '');
    localStorage.setItem('HMake', '')
    localStorage.setItem('type', '')
  }
list_of_body_Type : any = []
  List_of_body_Type(){
    this.homePageService.Body_Type().subscribe((r: any) => {
      if(r.successfull){
        this.list_of_body_Type = r.content;
        console.log(typeof(this.list_of_body_Type))
       // console.log(this.Record)
      }
    })    
}
}
