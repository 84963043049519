<div id="top"></div>
<div class="app-header"></div>
<!--=================================
 inner-intro -->

<section class="inner-intro bg-1 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">Testimonial</h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>Testimonial</span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>




<!--=================================
our History-->

<section class="testimonial-1 white-bg page page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)"
                          [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}"
                          required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-title">
                <span>What Our Happy Clients say about us</span>
                <h2> Testimonials </h2>
                <div class="separator"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="owl-carousel owl-theme" data-nav-dots="true" data-items="3" data-md-items="3"
                data-sm-items="2" data-xs-items="2" data-space="30">
                <div class="item">
                  <div class="testimonial-block text-center">
                    <div class="testimonial-image">
                      <img class="img-fluid" src="assets\images\ing\test-1.jpg" alt="">
                    </div>
                    <div class="testimonial-box">
                      <!--<div class="testimonial-avtar">
               <img class="img-fluid" src="assets\images\ing\test-1.jpeg" alt="">
               <h6>Miss Grace Nyasha</h6>
               
             </div>-->
                      <div class="testimonial-content">
                        <p>Thank you, Premium Auto Gallery, in all the assistance in getting my car. I am very happy.
                          Car is beautiful and they kept their word about delivering one amazing car. </p>
                        <i class="fa fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="testimonial-block text-center">
                    <div class="testimonial-image">
                      <img class="img-fluid" src="assets\images\ing\test-2.jpg" alt="">
                    </div>
                    <div class="testimonial-box">
                      <!--<div class="testimonial-avtar">
               <img class="img-fluid" src="assets\images\ing\test-2.jpeg" alt="">
               <h6>Mr. George</h6>
              
             </div>-->
                      <div class="testimonial-content">
                        <p>Thank you for exceptional services. Very happy to hit the road in my dream car. Amazing
                          customer service by your team Premium Autos.</p>
                        <i class="fa fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="testimonial-block text-center">
                    <div class="testimonial-image">
                      <img class="img-fluid" src="assets\images\ing\test-3.jpg" alt="">
                    </div>
                    <div class="testimonial-box">
                      <!--<div class="testimonial-avtar">
               <img class="img-fluid" src="assets\images\ing\test-3.jpeg" alt="">
               <h6>Mr Detlef Nolden</h6>
               
             </div>-->
                      <div class="testimonial-content">
                        <p style="overflow-y: scroll;height: 96px;">Good morning Mr. Nazir,
                          Very happy with my new Mazda CX-5 I purchased through Premium Auto Gallery. The whole buying
                          process was easy and went smoothly. The car was delivered to my doorstep, it was in a very
                          good condition, serviced and clean inside and out. I would definitely recommend to come to
                          Premium Auto Gallery if you are interested to buy a car from Japan. They will make sure that
                          you get the best vehicle you could get. Any question will be answered promptly and you even
                          can rely on their service center for later maintenance or repairs. Great customer service and
                          excellent experience!
                          Detlef Nolden, German Embassy, Administration.
                        </p>
                        <i class="fa fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="testimonial-block text-center">
                    <div class="testimonial-image">
                      <img class="img-fluid" src="assets\images\ing\test-4.jpg" alt="">
                    </div>
                    <div class="testimonial-box">
                      <!--<div class="testimonial-avtar">
               <img class="img-fluid" src="assets\images\ing\test-4.jpg" alt="">
               <h6>Nyasha Mabasa</h6>
             
             </div>-->
                      <div class="testimonial-content">
                        <p style="height: 96px;overflow-y: scroll;">Thank you, Jawad, for you and your teams exceptional
                          service. We called you on a public holiday and you were kind enough to come to the show room
                          and show us the vehicles you had in store and facilitated the purchase of the vehicle. We
                          greatly appreciate this exceptional service.
                          We also appreciated the insights you shared on the vehicles you had in stock and the options
                          that you were presenting for us. We had been searching for a vehicle for weeks with no luck on
                          something that could meet our specifications, certainly your assistance was insightful for us
                          to make the necessary purchase decision, which was the type of the car to purchase. Again,
                          following the public holidays, your exceptional team completed the registration process for us
                          and called us in when everything was complete.
                          We are yet to complete the change of ownership process and we will be sure to reach out if we
                          need anything else further from.
                          Many thanks,
                          Nyasha Mabasa.
                        </p>
                        <i class="fa fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="testimonial-block text-center">
                    <div class="testimonial-image">
                      <img class="img-fluid" src="assets\images\ing\Siphiwe Masango.jpg" alt="">
                    </div>
                    <div class="testimonial-box">
                      <!--<div class="testimonial-avtar">
               <img class="img-fluid" src="assets\images\ing\Siphiwe Masango.jpg" alt="">
               <h6>Siphiwe Masango</h6>
             
             </div>-->
                      <div class="testimonial-content">
                        <p>Swift, Satisfactory Service. Reliable and dependable service provided. We cannot wait to hit
                          the road!
                          Siphiwe Masango
                        </p>
                        <i class="fa fa-quote-right"></i>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<!--=================================
 footer -->