<!-- <div id="top"></div> -->
<div class="app-loader" *ngIf="loader"></div>
<div class="app-header"></div>
<!--=================================
  loading -->


<section>
  <div class="container-fix">

    <div id="myCarousel" class="carousel " data-ride="carousel">
      <!-- Indicators -->
      <!-- <ol class="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol> -->

      <!-- Wrapper for slides -->
      <div class="carousel-inner" style="width: 100%; height: 400px;">
        <div class="item active">
          <img src="assets\images\ing\1.jpg" style="width:100%; margin-top: -2px;">

        </div>

        <div class="item">
          <img src="assets\images\ing\2.jpg" style="width:100%;  margin-top: -3px;">
        </div>

        <div class="item">
          <img src="assets\images\ing\3.jpg" alt="New york" style="width:100%;   margin-top: 0%;">
        </div>
        <div class="item">
          <img src="assets\images\ing\4.jpg" alt="New york" style="width:100%;   margin-top: 0%;">
        </div>
        <div class="item">
          <img src="assets\images\ing\5.jpg" alt="New york" style="width:100%;  height: 400px; margin-top: 0%;">
        </div>
      </div>
      <!-- FOR REMOVING STRETCHED IMAGES -->
      <!-- <div class="carousel-inner" style="width: 100%;">
                <div class="item active">
                  <img src="images/2.jpg" style="width: 100%; height: auto; margin-top: -2px;">
                </div>
              
                <div class="item">
                  <img src="images/3.jpg" style="width: 100%; height: auto; margin-top: -3px;">
                </div>
              
                <div class="item">
                  <img src="images/4.jpg" alt="New york" style="width: 100%; height: auto; margin-top: 0%;">
                </div>
              </div> -->
      <!-- FOR REMOVING STRETCHED IMAGES -->



      <!-- Left and right controls -->
      <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="tp-mask-wrap"
    style="position: absolute; margin-top: -125px; margin-left: 45%; display: block; overflow: hidden; transform: matrix(1, 0, 0, 1, 0, 0);">
    <div class="tp-caption button red rs-hover-ready" id="slide-3-layer-4" data-x="62" data-y="452"
      data-width="['auto']" data-height="['auto']" data-type="button" data-responsive_offset="on"
      data-frames="[{&quot;delay&quot;:1720,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;},{&quot;frame&quot;:&quot;hover&quot;,&quot;speed&quot;:&quot;300&quot;,&quot;ease&quot;:&quot;Linear.easeNone&quot;,&quot;force&quot;:true,&quot;to&quot;:&quot;o:1;rX:0;rY:0;rZ:0;z:0;&quot;,&quot;style&quot;:&quot;c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;&quot;}]"
      data-textalign="['left','left','left','left']" data-paddingtop="[10,10,10,10]" data-paddingright="[30,30,30,30]"
      [routerLink]="['/Stock-Listing']"
      data-paddingbottom="[10,10,10,10]" data-paddingleft="[30,30,30,30]"
      style="z-index: 7; white-space: nowrap; font-size: 14px; line-height: 16px; font-weight: 400; font-family: &quot;Open Sans&quot;; outline: none; box-shadow: none; box-sizing: border-box; cursor: pointer; visibility: inherit; transition: none 0s ease 0s; text-align: left; border-width: 0px; margin: 0px; padding: 10px 30px; letter-spacing: 0px; min-height: 0px; min-width: 0px; max-height: none; max-width: none; opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform-origin: 50% 50% 0px; border-style: none; background-color: rgb(219, 45, 46); color: rgb(255, 255, 255); border-radius: 0px; font-style: normal; text-decoration: none solid rgb(255, 255, 255); border-color: rgb(255, 255, 255);">
      Discover More </div>
  </div>


</section>


<!--=================================
 search -->


<section class="search white-bg">
  <div class="container">
    <div class="search-block">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <span>Select Make</span>
              <div class="selected-box" (change)="offsetValueChange1($event.target)">
                <select class="selectpicker">
                  <option>Make </option>
                  <option *ngFor="let item of list_of_make">{{item.Make}}</option>

                </select>
              </div>
            </div>
            <div class="col-md-4">
              <span>Select Model</span>
              <div class="selected-box">
                <select class="selectpicker" (change)="offsetValueChange2($event.target)">
                  <option>Model</option>
                  <option *ngFor="let item of modelValues">{{item.value}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <span>Select Year</span>
              <div class="selected-box">
                <select class="selectpicker" (change)="offsetValueChange3($event.target)">
                  <option>Year</option>
                  <option *ngFor="let item of yearsValues">{{item.value}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <span>Select Body Style</span>
              <div class="selected-box">
                <select class="selectpicker" (change)="offsetValueChange4($event.target)">
                  <option>Body style</option>
                  <option *ngFor="let item of list_of_body_Type">{{item.Body_Type}}</option>
                  
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <span>Select Location</span>
              <div class="selected-box">
                <select class="selectpicker" (change)="offsetValueChange5($event.target)">
                  
                  <option>Durban</option>
                  <option>Harare</option>
                  <option>Tokyo</option>


                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="price-slide">
            <div class="price">
             <label class="mb-2" for="amount">Price Range</label>
             <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()" [options]="options"></ngx-slider>
               <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
              <div id="slider-range"></div>-->
            </div>
            
           </div>
           <div style="margin-top: 45px;">
            <a class="button1" (click)="SearchBut()">Search</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--=================================
 search -->

<!--=================================
 welcome -->

<!-- <section class="welcome-3 white-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="content-box-2 car-bg-1">
            <i class="glyph-icon flaticon-beetle"></i>
            <a class="title" href="#">Buy a Car</a>
            <p>We sell perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
            <a class="link" href="#">read more <i class="fa fa-angle-double-right"></i> </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="content-box-2 car-bg-2">
            <i class="glyph-icon flaticon-price-tag"></i>
            <a class="title" href="#">Sell My Car</a>
            <p>You can sell sed ut unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
            <a class="link" href="#">read more <i class="fa fa-angle-double-right"></i> </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="content-box-2 car-bg-3">
            <i class="glyph-icon flaticon-reparation"></i>
            <a class="title" href="#">Get Service</a>
            <p>We provide sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
            <a class="link" href="#">read more <i class="fa fa-angle-double-right"></i> </a>
        </div>
      </div>
    </div>
    <div class="row about custom-block-2">
      <div class="col-md-6">
        <h2>About us </h2>
        <span>Everything you need to build an amazing dealership website. </span>
        <p> is the best premium HTML5 Template. We provide everything you need to build an <b>Amazing dealership website </b> developed especially for car sellers, dealers or auto motor retailers. You can use this template for creating website based on any framework and any language </p>
      </div>
      <div class="col-md-6">
        <img class="img-fluid center-block" src="images/car/11.png" alt="">
       </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <div class="feature-box-2 box-hover active">
         <div class="icon">
           <i class="glyph-icon flaticon-beetle"></i>
         </div>
         <div class="content">
          <h5>All brands</h5>
          <p class="mb-0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem acantium doloremque laudantium.</p>
         </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="feature-box-2 box-hover">
         <div class="icon">
           <i class="glyph-icon flaticon-interface-1"></i>
         </div>
         <div class="content">
          <h5>Free Support</h5>
          <p class="mb-0">Omnis sed ut perspiciatis unde iste natus error sit voluptatem acantium doloremque laudantium.</p>
         </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="feature-box-2 box-hover mb-4 mb-sm-0">
         <div class="icon">
           <i class="glyph-icon flaticon-key"></i>
         </div>
         <div class="content">
          <h5>Dealership</h5>
          <p class="mb-0">Error sed ut perspiciatis unde omnis iste natus sit voluptatem acantium doloremque laudantium.</p>
         </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="feature-box-2 box-hover mb-0">
         <div class="icon">
           <i class="glyph-icon flaticon-wallet"></i>
         </div>
         <div class="content">
          <h5>Affordable</h5>
          <p class="mb-0">Perspiciatis sed ut unde omnis iste natus error sit voluptatem acantium doloremque laudantium.</p>
         </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!--=================================
 welcome -->


<!--=================================
 feature-car -->




<!-- <section class="feature-car gray-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <span>Check out our recent cars</span>
          <h2>New Arrivals</h2>
        </div>
      </div>
    </div>

    <ul class="nav nav-tabs" style="margin-left: 33%; margin-bottom: 5%;">
      <li id="menu1-tab">
        <a data-toggle="tab" href="#menu1" style="margin-left: 10px;">
          <button onclick="myfunction()">All</button>
        </a>
      </li>
      <li id="menu2-tab">
        <a data-toggle="tab" href="#menu2" style="margin-left: 10px;">
          <button onclick="myfunction2()">Sedan</button>
        </a>
      </li>
      <li id="menu3-tab">
        <a data-toggle="tab" href="#menu3" style="margin-left: 10px;">
          <button onclick="myfunction3()">SUV</button>
        </a>
      </li>
      <li id="menu4-tab">
        <a data-toggle="tab" href="#menu4" style="margin-left: 10px;">
          <button onclick="myfunction4()">Commercial</button>
        </a>
      </li>
    </ul>
 -->
<!-- <ul class="nav nav-tabs">
      <li id="menu1-tab">
        <a data-toggle="tab" href="#menu1" style="margin-left: 10px;">
          <button onclick="showCards('menu1')">Menu 1</button>
        </a>
      </li>
      <li id="menu2-tab">
        <a data-toggle="tab" href="#menu2" style="margin-left: 10px;">
          <button onclick="showCards('menu2')">Menu 2</button>
        </a>
      </li>
     
    </ul> -->

<!-- <div class="row">
      <div class="col-md-12">
        <div
          class="owl-carousel owl-theme"
          data-nav-dots="true"
          data-items="3"
          data-md-items="3"
          data-sm-items="2"
          data-xs-items="1"
          data-space="15"
        >
          <div class="item tab-pane" id="menu1"> -->
<!-- Content for Menu 1 -->
<!-- <div class="car-item-2 text-center">
              <div class="car-image">
                <img class="img-fluid" src="images/car/01.jpg" alt="">
                <div class="car-overlay-banner">
                 <ul>
                   <li><a href="#"><i class="fa fa-link"></i></a></li>
                   <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
                  </ul>
                </div>
              </div>
              <div class="car-content">
               <a href="#">Acura Rsx</a>
               <div class="car-list">
                <ul class="list-inline">
                  <li> 2021</li>
                  <li>  Manual </li>
                  <li>  210 hp </li>
                  <li> 6,000 mi</li>
                </ul>
                </div>
                <div class="info">
                  <p>You will begin to realize why this exercise Pattern is called the Dickens with to ghost.</p>
                </div>
                <div class="price">
                  <span class="old-price">$29,568</span>
                  <span class="new-price">$26,598 </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item tab-pane" id="menu2"> -->
<!-- Content for Menu 2 -->
<!-- <div class="car-item-2 text-center">
              <div class="car-image">
                <img class="img-fluid" src="images/car/02.jpg" alt="">
                <div class="car-overlay-banner">
                 <ul>
                   <li><a href="#"><i class="fa fa-link"></i></a></li>
                   <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
                  </ul>
                </div>
              </div>
              <div class="car-content">
               <a href="#">Lexus GS 450h</a>
               <div class="car-list">
                <ul class="list-inline">
                  <li> 2021</li>
                  <li>  Manual </li>
                  <li>  210 hp </li>
                  <li> 6,000 mi</li>
                </ul>
                </div>
                <div class="info">
                  <p>Dickens with to ghost you will begin to realize why this exercise Pattern is called the.</p>
                </div>
                <div class="price">
                  <span class="old-price">$40,968</span>
                  <span class="new-price">$36,558 </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item tab-pane" id="menu3"> -->
<!-- Content for Menu 3 -->
<!-- <div class="car-item-2 text-center">
              <div class="car-image">
                <img class="img-fluid" src="images/car/03.jpg" alt="">
                <div class="car-overlay-banner">
                 <ul>
                   <li><a href="#"><i class="fa fa-link"></i></a></li>
                   <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
                  </ul>
                </div>
              </div>
              <div class="car-content">
               <a href="#">GTA 5 Lowriders DLC</a>
               <div class="car-list">
                <ul class="list-inline">
                  <li> 2021</li>
                  <li>  Manual </li>
                  <li>  210 hp </li>
                  <li> 6,000 mi</li>
                </ul>
                </div>
                <div class="info">
                  <p>realize why this dickens with to ghost you will begin to exercise Pattern is called the.</p>
                </div>
                <div class="price">
                  <span class="old-price">$35,568</span>
                  <span class="new-price">$32,698 </span>
                </div>
              </div>
            </div>
          </div>
          <div class="item tab-pane" id="menu4"> -->
<!-- Content for Menu 4 -->
<!-- <div class="car-item-2 text-center">
              <div class="car-image">
                <img class="img-fluid" src="images/car/05.jpg" alt="">
                <div class="car-overlay-banner">
                 <ul>
                   <li><a href="#"><i class="fa fa-link"></i></a></li>
                   <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
                  </ul>
                </div>
              </div>
              <div class="car-content">
               <a href="#">Toyota avalon hybrid </a>
               <div class="car-list">
                <ul class="list-inline">
                  <li> 2021</li>
                  <li>  Manual </li>
                  <li>  210 hp </li>
                  <li> 6,000 mi</li>
                </ul>
                </div>
                <div class="info">
                  <p>Dickens with to ghost realize why this you will begin to exercise Pattern is called the.</p>
                </div>
                <div class="price">
                  <span class="old-price">$44,768</span>
                  <span class="new-price">$33,698 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
          <div class="separator" style="margin-left: 45%; ">
            <br />
            <a class="button" href="#" >View more</a>
          </div>
       



  </div>
</section> -->



<script>
  function myfunction() {
    debugger;
    var menu1Div = document.getElementById('menu1');
    var menu2Div = document.getElementById('menu2');
    var menu3Div = document.getElementById('menu3');
    var menu4Div = document.getElementById('menu4');

    menu1Div.style.display = 'block';
    menu2Div.style.display = 'none';
    menu3Div.style.display = 'none';
    menu3Div.style.display = 'none';
    debugger;

  }

  function myfunction2() {
    var menu1Heading = document.getElementById('menu1');
    var menu2Heading = document.getElementById('menu2');
    var menu3Heading = document.getElementById('menu3');
    var menu4Heading = document.getElementById('menu4');
    menu1Heading.style.display = 'none';
    menu2Heading.style.display = 'block';
    menu3Heading.style.display = 'none';
    menu4Heading.style.display = 'none';
  }

  function myfunction3() {
    var menu1Heading = document.getElementById('menu1');
    var menu2Heading = document.getElementById('menu2');
    var menu3Heading = document.getElementById('menu3');
    var menu4Heading = document.getElementById('menu4');
    menu1Heading.style.display = 'none';
    menu2Heading.style.display = 'none';
    menu3Heading.style.display = 'block';
    menu4Heading.style.display = 'none';
  }

  function myfunction4() {
    var menu1Heading = document.getElementById('menu1');
    var menu2Heading = document.getElementById('menu2');
    var menu3Heading = document.getElementById('menu3');
    var menu4Heading = document.getElementById('menu4');
    menu1Heading.style.display = 'none';
    menu2Heading.style.display = 'none';
    menu3Heading.style.display = 'none';
    menu4Heading.style.display = 'block';
  }
</script>



<section class="feature_section sec_ptb_150 clearfix">
  <div class="container">

    <!-- <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
          <div class="section_title mb_60 text-center" data-aos="fade-up" data-aos-delay="100">
            <h2 class="title_text mb_15">
              <span>NEW ARRIVALS</span>
            </h2>
            <p class="mb-0">
              Mauris cursus quis lorem sed cursus. Aenean aliquam pellentesque magna, ut dictum ex pellentesque
            </p>
          </div>
        </div>
      </div> -->
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h2> NEW ARRIVALS</h2>
          <div class="separator"></div>
          <!-- <p class="mb-0">
              Mauris cursus quis lorem sed cursus. Aenean aliquam pellentesque magna, ut dictum ex pellentesque
            </p> -->
        </div>
      </div>
    </div>

    <ul class="button-group filters-button-group ul_li_center mb_30 clearfix" data-aos-delay="300">
      <li><button (click)="newArrival('all')" class="button active" data-filter="*" style="color: white !important; ">All</button></li>
      <li><button (click)="newArrival('Tokyo')" class="button" data-filter=".sedan" style="color: white !important; ">Tokyo</button></li>
      <li><button (click)="newArrival('Harare')"class="button" data-filter=".sports" style="color: white !important; ">Harare</button></li>
      <li><button (click)="newArrival('Durban')" class="button" data-filter=".luxury" style="color: white !important; ">Durban</button></li>
    </ul>
    <!-- OLD ONE START -->
    <!-- <div class="feature_vehicle_filter element-grid clearfix">
          <div class="element-item sedan " data-category="sedan">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="100">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_01.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Gasoline</li>
                  </ul>
              </div>
          </div>

          <div class="element-item sports " data-category="sports">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="300">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2019 Chevrolet Corvette Stingray Red
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_02.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Hybrid</li>
                  </ul>
              </div>
          </div>

          <div class="element-item luxury " data-category="luxury">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="500">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_03.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$120/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Gasoline</li>
                  </ul>
              </div>
          </div>

          <div class="element-item sedan " data-category="sedan">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="100">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2020 Audi New Generation P00234
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_04.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Electro</li>
                  </ul>
              </div>
          </div>

          <div class="element-item sports " data-category="sports">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="300">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_05.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$160/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Gasoline</li>
                  </ul>
              </div>
          </div>

          <div class="element-item luxury " data-category="luxury">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="500">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_06.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Hybrid</li>
                  </ul>
              </div>
          </div>	
      </div> -->
    <!-- OLD ONE END -->

    <div class="feature_vehicle_filter element-grid clearfix" style="margin-bottom: 0% !important; min-height:400px;">




      <div class="element-item sedan " *ngFor="let item of newArrivalData" data-category="sedan">
        <div class="car-item-2 text-center ">
          <div class="car-image">
            <img class="img-fluid" *ngIf="item.IMAGE_NAME !== ''" src="assets\images\364x260\{{item.IMAGE_NAME}}.jpg" alt="">
            <img style="height: 260px;" width="364" height="260" class="img-fluid" *ngIf="item.IMAGE_NAME === ''" src="assets\images\364x260\default.jpg" alt="">
            <div class="car-overlay-banner">
              <!--<ul>
                <li><a href="#"><i class="fa fa-link"></i></a></li>
                <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
              </ul>-->
            </div>
          </div>
          <div class="car-content">
            <a (click)="PostData(item.Stock_No)" *ngIf="item.MODEL">{{item.MODEL}}</a>
            <div class="car-list">
              <ul class="list-inline">
                <li *ngIf="item.MODEL_YEAR">{{item.MODEL_YEAR}}</li>
                <li *ngIf="item.TRANSMISSION_TYPE"> {{item.TRANSMISSION_TYPE}} </li>
                <li *ngIf="item.ENGINE_CAPACITY"> {{item.ENGINE_CAPACITY}} hp </li>
                <li *ngIf="item.NOTED_MILEAGE">{{item.NOTED_MILEAGE}} mi</li>
              </ul>
            </div>

            <div class="price">
              <span class="new-price" *ngIf="item.C_F_PRICE">${{item.C_F_PRICE}} </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="newArrivalData<1">
        <div class="element-item sedan " data-category="sedan">
          <div class="car-item-2 text-center "><div class="car-image">&nbsp;</div></div>
        </div>
        <div class="element-item sedan "  data-category="sedan">
          <div class="car-item-2 text-center ">
            <div class="car-image">
              <img style="height: 260px;" width="364" height="260" class="img-fluid" src="assets\images\364x260\default.jpg" alt="">
              <div class="car-overlay-banner">
                <!--<ul>
                  <li><a href="#"><i class="fa fa-link"></i></a></li>
                  <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
                </ul>-->
              </div>
            </div>
            <div class="car-content">
              <a >No Car Found</a>
              
            </div>
          </div>
          </div>
      </div>
    </div>




    <!-- <div class="abtn_wrap text-center clearfix" data-aos="fade-up" data-aos-delay="100">
          <a class="custom_btn bg_default_red btn_width text-uppercase" href="#!">Book A Car <img src="assets/ass/images/icons/icon_01.png" alt="icon_not_found"></a>
      </div> -->

    <ul class="button-group filters-button-group ul_li_center mb_30 clearfix" data-aos-delay="300">
      <li><button class="button active" *ngIf="newArrivalData.length>0" (click)="ArrivalMore()" style="color: white !important; ">View more</button></li>
    </ul>


  </div>
</section>

<section class="feature_section sec_ptb_150 clearfix">
  <div class="container">

    <!-- <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
          <div class="section_title mb_60 text-center" data-aos="fade-up" data-aos-delay="100">
            <h2 class="title_text mb_15">
              <span>FEATURED CAR</span>
            </h2>
            <p class="mb-0">
              Mauris cursus quis lorem sed cursus. Aenean aliquam pellentesque magna, ut dictum ex pellentesque
            </p>
          </div>
        </div>
      </div> -->
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h2> FEATURED CARS</h2>
          <div class="separator"></div>
          <!-- <p class="mb-0">
              Mauris cursus quis lorem sed cursus. Aenean aliquam pellentesque magna, ut dictum ex pellentesque
            </p> -->
        </div>
      </div>
    </div>

    <ul class="button-group filters-button-group ul_li_center mb_30 clearfix" data-aos-delay="300">
      <li><button class="button active"  data-filter="*" style="color: white !important;" (click)="home('all')">All</button></li>
      <li><button class="button"  (click)="home('Sedan')" data-filter=".sedan" style="color: white !important; ">Sedan</button></li>
      <li><button class="button" (click)="home('SUV')" data-filter=".sports" style="color: white !important; ">SUV</button></li>
      <li><button class="button" (click)="home('Wagon')" data-filter=".luxury" style="color: white !important; ">Wagon</button></li>
    </ul>

    <!-- <div class="feature_vehicle_filter element-grid clearfix">
          <div class="element-item sedan " data-category="sedan">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="100">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_01.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Gasoline</li>
                  </ul>
              </div>
          </div>

          <div class="element-item sports " data-category="sports">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="300">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2019 Chevrolet Corvette Stingray Red
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_02.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Hybrid</li>
                  </ul>
              </div>
          </div>

          <div class="element-item luxury " data-category="luxury">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="500">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_03.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$120/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Gasoline</li>
                  </ul>
              </div>
          </div>

          <div class="element-item sedan " data-category="sedan">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="100">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2020 Audi New Generation P00234
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_04.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Electro</li>
                  </ul>
              </div>
          </div>

          <div class="element-item sports " data-category="sports">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="300">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_05.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$160/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Gasoline</li>
                  </ul>
              </div>
          </div>

          <div class="element-item luxury " data-category="luxury">
              <div class="feature_vehicle_item" data-aos="fade-up" data-aos-delay="500">
                  <h3 class="item_title mb-0">
                      <a href="#!">
                          2015 Chevrolet Corvette Stingray Z51
                      </a>
                  </h3>
                  <div class="item_image position-relative">
                      <a class="image_wrap" href="#!">
                          <img src="assets/ass/images/feature/img_06.jpg" alt="image_not_found">
                      </a>
                      <span class="item_price bg_default_red">$230/Day</span>
                  </div>
                  <ul class="info_list ul_li_center clearfix">
                      <li>Sports</li>
                      <li>Auto</li>
                      <li>2 Passengers</li>
                      <li>Hybrid</li>
                  </ul>
              </div>
          </div>	
      </div> -->


    <div class="feature_vehicle_filter element-grid clearfix">






      

      <div class="element-item sports " *ngFor="let item of data" data-category="sports">
        <div class="car-item-2 text-center ">
          <div class="car-image">
            <img class="img-fluid" *ngIf="item.IMAGE_NAME !== ''" src="assets\images\364x260\{{item.IMAGE_NAME}}.jpg" alt="">
            <img style="height: 260px;" width="364" height="260" class="img-fluid" *ngIf="item.IMAGE_NAME === ''" src="assets\images\364x260\default.jpg" alt="">
            <div class="car-overlay-banner">
              <!--<ul>
                <li><a href=""><i class="fa fa-link"></i></a></li>
                <li><a href=""><i class="fa fa-dashboard"></i></a></li>
              </ul>-->
            </div>
          </div>
          <div class="car-content">
            <a (click)="PostData(item.Stock_No)" *ngIf="item.MODEL">{{item.MODEL}}</a>
            <div class="car-list">
              <ul class="list-inline">
                <li *ngIf="item.MODEL_YEAR">{{item.MODEL_YEAR}}</li>
                <li *ngIf="item.TRANSMISSION_TYPE"> {{item.TRANSMISSION_TYPE}} </li>
                <li *ngIf="item.ENGINE_CAPACITY"> {{item.ENGINE_CAPACITY}} hp </li>
                <li *ngIf="item.NOTED_MILEAGE">{{item.NOTED_MILEAGE}} mi</li>
              </ul>
            </div>

            <div class="price">
              <span class="new-price" *ngIf="item.C_F_PRICE">${{item.C_F_PRICE}} </span>
            </div>
          </div>
        </div>
      </div>

      
    </div>
    <!-- <div class="abtn_wrap text-center clearfix" data-aos="fade-up" data-aos-delay="100">
          <a class="custom_btn bg_default_red btn_width text-uppercase" href="#!">Book A Car <img src="assets/ass/images/icons/icon_01.png" alt="icon_not_found"></a>
      </div> -->


    <ul class="button-group filters-button-group ul_li_center mb_30 clearfix" data-aos-delay="300">
      <li><button class="button active" (click)="featureMore()" data-filter="*"  style="color: white !important; ">View more</button></li>
    </ul>

  </div>
</section>



<!-- <section class="feature-car gray-bg page-section-ptb">
  <div class="container">
   <div class="row">
    <div class="col-md-12">
      <div class="section-title">
         <span>Check out our recent cars</span>
         <h2>Feature Car </h2>
         <div class="separator"></div>
      </div>
    </div>
   </div>

   <ul class="nav nav-tabs" style="margin-left: 33%; margin-bottom: 5%;">
    <li id="menu1-tab">
      <a data-toggle="tab" href="#menu1" style="margin-left: 10px;">
        <button onclick="myfunction()">All</button>
      </a>
    </li>
    <li id="menu2-tab">
      <a data-toggle="tab" href="#menu2" style="margin-left: 10px;">
        <button onclick="myfunction2()">Japan</button>
      </a>
    </li>
    <li id="menu3-tab">
      <a data-toggle="tab" href="#menu3" style="margin-left: 10px;">
        <button onclick="myfunction3()">Harare</button>
      </a>
    </li>
    <li id="menu4-tab">
      <a data-toggle="tab" href="#menu4" style="margin-left: 10px;">
        <button onclick="myfunction4()">Durban</button>
      </a>
    </li>
  </ul>

 <div class="row">
  <div class="col-md-12">
   <div class="owl-carousel owl-theme" data-nav-dots="true" data-items="3" data-md-items="3" data-sm-items="2" data-xs-items="1" data-space="15">
     <div class="item">
       <div class="car-item-2 text-center">
         <div class="car-image">
           <img class="img-fluid" src="images/car/01.jpg" alt="">
           <div class="car-overlay-banner">
            <ul>
              <li><a href="#"><i class="fa fa-link"></i></a></li>
              <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
             </ul>
           </div>
         </div>
         <div class="car-content">
          <a href="#">Acura Rsx</a>
          <div class="car-list">
           <ul class="list-inline">
             <li> 2021</li>
             <li>  Manual </li>
             <li>  210 hp </li>
             <li> 6,000 mi</li>
           </ul>
           </div>
           <div class="info">
             <p>You will begin to realize why this exercise Pattern is called the Dickens with to ghost.</p>
           </div>
           <div class="price">
             <span class="old-price">$29,568</span>
             <span class="new-price">$26,598 </span>
           </div>
         </div>
       </div>
     </div>
     <div class="item">
       <div class="car-item-2 text-center">
         <div class="car-image">
           <img class="img-fluid" src="images/car/02.jpg" alt="">
           <div class="car-overlay-banner">
            <ul>
              <li><a href="#"><i class="fa fa-link"></i></a></li>
              <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
             </ul>
           </div>
         </div>
         <div class="car-content">
          <a href="#">Lexus GS 450h</a>
          <div class="car-list">
           <ul class="list-inline">
             <li> 2021</li>
             <li>  Manual </li>
             <li>  210 hp </li>
             <li> 6,000 mi</li>
           </ul>
           </div>
           <div class="info">
             <p>Dickens with to ghost you will begin to realize why this exercise Pattern is called the.</p>
           </div>
           <div class="price">
             <span class="old-price">$40,968</span>
             <span class="new-price">$36,558 </span>
           </div>
         </div>
       </div>
     </div>
     <div class="item">
       <div class="car-item-2 text-center">
         <div class="car-image">
           <img class="img-fluid" src="images/car/03.jpg" alt="">
           <div class="car-overlay-banner">
            <ul>
              <li><a href="#"><i class="fa fa-link"></i></a></li>
              <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
             </ul>
           </div>
         </div>
         <div class="car-content">
          <a href="#">GTA 5 Lowriders DLC</a>
          <div class="car-list">
           <ul class="list-inline">
             <li> 2021</li>
             <li>  Manual </li>
             <li>  210 hp </li>
             <li> 6,000 mi</li>
           </ul>
           </div>
           <div class="info">
             <p>realize why this dickens with to ghost you will begin to exercise Pattern is called the.</p>
           </div>
           <div class="price">
             <span class="old-price">$35,568</span>
             <span class="new-price">$32,698 </span>
           </div>
         </div>
       </div>
     </div>
     <div class="item">
       <div class="car-item-2 text-center">
         <div class="car-image">
           <img class="img-fluid" src="images/car/05.jpg" alt="">
           <div class="car-overlay-banner">
            <ul>
              <li><a href="#"><i class="fa fa-link"></i></a></li>
              <li><a href="#"><i class="fa fa-dashboard"></i></a></li>
             </ul>
           </div>
         </div>
         <div class="car-content">
          <a href="#">Toyota avalon hybrid </a>
          <div class="car-list">
           <ul class="list-inline">
             <li> 2021</li>
             <li>  Manual </li>
             <li>  210 hp </li>
             <li> 6,000 mi</li>
           </ul>
           </div>
           <div class="info">
             <p>Dickens with to ghost realize why this you will begin to exercise Pattern is called the.</p>
           </div>
           <div class="price">
             <span class="old-price">$44,768</span>
             <span class="new-price">$33,698 </span>
           </div>
         </div>
       </div>
     </div>
    </div>
   </div>
  </div>

  <div class="separator" style="margin-left: 45%; ">
    <br />
    <a class="button" href="#" >View more</a>
  </div>

  </div>
</section> -->

<!--=================================
 feature-car -->


<!--=================================
 our-service -->

<section>

  <main class="vlt-main-holder vlt-main-padding">

    <div class="container">
      <div class="section-title">
        <h2> BROWSE USED CARS</h2>
        <div class="separator"></div>
      </div>
      <div class="vlt-portfolio-grid-filters">
        <!-- <div data-filter="*" class="cbp-filter-item cbp-filter-item-active"><span>Category</span></div>
      <div data-filter=".portfolio_category-branding" class="cbp-filter-item"><span>Make</span></div> -->
        <ul class="nav nav-tabs">
          <li id="home-tab" class="active">
            <a data-toggle="tab" (click)="usedCarTypeFun(true)">
              <button style="background-color: transparent !important; color: red;">Body Type</button>
            </a>
          </li>
          <li id="menu1-tab">
            <a data-toggle="tab" (click)="usedCarTypeFun(false)">
              <button style="background-color: transparent !important; color: red;">Make</button>
            </a>
          </li>
        </ul>

        <div class="tab-content">


          <div *ngIf="usedCarType" class="tab-pane in active">

            <div class="container" style=" gap: 10px;width:1030px">

              <div class="row tab1" style="display: flex; gap: 15px; margin-top: 10px;width:1100px ">


                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="HATCBACK"
                      (click)="trackEvents('Hatchback')">
                        <img alt="Automatic cars" height="100" loading="lazy" 
                          src="assets\images\clients\body-type\03.png"  />
                        <p> Hatchback </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="SEDAN"
                      (click)="trackEvents('Sedan')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\02.png"  />
                        <p> Sedan </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="SPORTS"
                      (click)="trackEvents('Sport')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\08.png"  />
                        <p> Sport </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="Suv"
                      (click)="trackEvents('Suv')">
                        <img alt="Suv" height="100" loading="lazy"
                          src="assets\images\clients\body-type\04.png"  />
                        <p> Suv </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a  title="TRUCK"
                      (click)="trackEvents('Truck')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\06.png"  />
                        <p> Truck </p>
                      </a>
                    </li>
                  </ul>
                </div>


              </div>

              <div class="row tab1" style="display: flex; gap: 15px; margin-top: 5px; padding-bottom: 5px;width:1100px
              ">

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a  title="VAN"
                      (click)="trackEvents('Van')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\05.png"  />
                        <p> Van </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a  title="WAGON"
                      (click)="trackEvents('Wagon')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\04.png"  />
                        <p> Wagon</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a  title="2dr CAR"
                      (click)="trackEvents('2dr Car')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                        src="assets\images\clients\body-type\08.png" />
                        <p> 2 Door </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="CONVERTIABLE"
                      (click)="trackEvents('Convertible')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\01.png"  />
                        <p> Convertible </p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="4dr CAR"
                      (click)="trackEvents('4dr Car')">
                        <img alt="Automatic cars" height="100" loading="lazy"
                          src="assets\images\clients\body-type\07.png" />
                        <p> 4 Door  </p>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>



            </div>


          </div>
          <div *ngIf="!usedCarType" class="tab-pane in active">
            <div class="container" style=" gap: 10px; width:'800px';margin:0 auto">

              <div class="row tab1" style="display: flex; gap: 15px; margin-top: 10px; width:1100px;">

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px; height: 20%;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="TOYOTA"
                      (click)="makeEvents('Toyota')">
                        <img alt="Automatic cars" height="50" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\toyota.jpg" />
                        <p> Toyota</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="SUZUKI"
                      (click)="makeEvents('Suzuki')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\Suzuki.jpg" />
                        <p> Suzuki</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="HONDA"
                      (click)="makeEvents('Honda')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\honda.jpg" />
                        <p>Honda</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="NISSAN"
                      (click)="makeEvents('Nissan')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\Nissan.jpg" />
                        <p> Nissan</p>
                      </a>
                    </li>
                  </ul>
                </div>

                
              </div>

              <div class="row tab1" style="display: flex; gap: 15px; margin-top: 5px; padding-bottom: 5px;width:1100px">

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px; height: 10%;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="SUBARU"
                      (click)="makeEvents('Subaru')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\sabura.png" />
                        <p> Subaru</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px; height: 10%;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="MERCESES"
                      (click)="makeEvents('Mercedes')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\Mercedes.jpg"/>
                        <p> Mercedes</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px; height: 10%;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a title="MAZDA"
                      (click)="makeEvents('Mazda')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\Mazda.jpg" />
                        <p>Mazda</p>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-12"
                  style="background-color: white; border: 1px solid rgb(189, 188, 188); border-radius: 10px; height: 10%;">
                  <ul class="browse-listing  item active clearfix" style="min-height: 80px; list-style-type: none;">
                    <li style="margin-top: 25px; list-style: none;">
                      <a  title="MISTSUBISHI"
                      (click)="makeEvents('Mitsubishi')">
                        <img alt="Automatic cars" height="100" style="height: 40px;" loading="lazy"
                          src="assets\images\Body_Type\Mistubishi1.png" />
                        <p> Mitsubishi</p>
                      </a>
                    </li>
                  </ul>
                </div>

                


              </div>

            </div>
          </div>

        </div>


      </div>

    </div>
  </main>
</section>


<section class="our-service white-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <h2>our services</h2>
        <span>We provide best services processus dynamicus, qui sequitur mutationem co</span>
        <div class="row">
          <div class="col-md-6">
            <div class="feature-box-2">
              <div class="icon">
                <i class="glyph-icon flaticon-wallet"></i>
              </div>
              <div class="content">
                <h5>Affordable Vehicles </h5>
                <p>We offer a wide range of vehicles at competitive prices, ensuring that you can find a quality vehicle
                  that fits your budget.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="feature-box-2">
              <div class="icon">
                <i class="glyph-icon flaticon-gas-station"></i>
              </div>
              <div class="content">
                <h5>Vehicle Sourcing</h5>
                <p>Looking for a specific make or model? Our dedicated team can help you find the vehicle you desire. We
                  have access to an extensive network, allowing us to source vehicles based on your preferences.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="feature-box-2">
              <div class="icon">
                <i class="glyph-icon flaticon-air-conditioning"></i>
              </div>
              <div class="content">
                <h5> Trade-Ins</h5>
                <p>If you're looking to upgrade your vehicle, we offer trade-in services. Bring your current vehicle to
                  us, and we'll provide a fair evaluation to help you offset the cost of your new purchase.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="feature-box-2">
              <div class="icon">
                <i class="glyph-icon flaticon-gearstick"></i>
              </div>
              <div class="content">
                <h5>Ownership Transfer Assistance </h5>
                <p>We understand the complexities involved in ownership transfer processes. Our knowledgeable team will
                  guide you through the necessary steps and paperwork to facilitate a smooth transfer of ownership for
                  your vehicle.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 mt-4 mt-lg-0">
        <img class="img-fluid" src="assets/images/car/12.jpg" alt="">
      </div>
    </div>
  </div>
</section>

<!--=================================
 our-service -->




<!--=================================
 latest news -->

<section class="latest-blog gray-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <span>Read our latest news</span>
          <h2> LATEST NEWS </h2>
          <div class="separator"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="blog-2">
          <div class="blog-image">
            <img class="img-fluid" src="assets/images/ing/blog/home-blog-1.jpg" alt="">
            <div class="date">
              <span>AUG 21</span>
            </div>
          </div>
          <div class="blog-content">
            <div class="blog-admin-main">
              <div class="blog-admin">
                <img class="img-fluid" src="assets/images/ing/Nazia.png" alt="">
                <span>Nazia Khan</span>
              </div>
              <div class="blog-meta float-end">
                <ul>

                  <li class="share"><a href="#"> <i class="fa fa-share-alt"></i><br /> ...</a>
                    <div class="blog-social">
                      <ul>
                        <li> <a href="#"><i class="fa fa-facebook"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-twitter"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-instagram"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-pinterest-p"></i></a> </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="blog-description text-center">
              <a [routerLink]="['/Blog-Details', '1']">Top 5 Family-Friendly SUVs for South African Car Buyers</a>
              <div class="separator"></div>
              <p>In this blog, we'll explore the top five family-friendly SUVs that cater to the unique preferences.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="blog-2">
          <div class="blog-image">
            <img class="img-fluid" src="assets/images/ing/blog/home-blog-2.jpg" alt="">
            <div class="date">
              <span>AUG 21</span>
            </div>
          </div>
          <div class="blog-content">
            <div class="blog-admin-main">
              <div class="blog-admin">
                <img class="img-fluid" src="assets/images/ing/Nazia.png" alt="">
                <span>Nazia Khan</span>
              </div>
              <div class="blog-meta float-end">
                <ul>
                  <li class="share"><a href=""> <i class="fa fa-share-alt"></i><br /> ...</a>
                    <div class="blog-social">
                      <ul>
                        <li> <a href="#"><i class="fa fa-facebook"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-twitter"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-instagram"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-pinterest-p"></i></a> </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="blog-description text-center">
              <a [routerLink]="['/Blog-Details', '2']">Navigating Zimbabwean Terrain: Best Cars for Adventurous Car Owners</a>
              <div class="separator"></div>
              <p>Zimbabwe's diverse terrain offers a paradise for adventurous car owners who seek to explore its natural
                wonders. </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="blog-2 mb-0">
          <div class="blog-image">
            <img class="img-fluid" src="assets/images/ing/blog/home-blog-3.jpg" alt="">
            <div class="date">
              <span>AUG 21</span>
            </div>
          </div>
          <div class="blog-content">
            <div class="blog-admin-main">
              <div class="blog-admin">
                <img class="img-fluid" src="assets/images/ing/Nazia.png" alt="">
                <span>Nazia Khan</span>
              </div>
              <div class="blog-meta float-end">
                <ul>

                  <li class="share"><a href="#"> <i class="fa fa-share-alt"></i><br /> ...</a>
                    <div class="blog-social">
                      <ul>
                        <li> <a href="#"><i class="fa fa-facebook"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-twitter"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-instagram"></i></a> </li>
                        <li> <a href="#"><i class="fa fa-pinterest-p"></i></a> </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="blog-description text-center">
              <a [routerLink]="['/Blog-Details', '3']">Going Green: The Rise of Electric Vehicles in South Africa and Zimbabwe</a>
              <div class="separator"></div>
              <p>In this blog, we explore the growing popularity of EVs among car buyers in South Africa and Zimbabwe
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--=================================
 latest news -->


<!--=================================
 testimonial -->

<section class="testimonial-3 white-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <span>What Our Happy Clients say about us</span>
          <h2> Testimonials </h2>
          <div class="separator"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="owl-carousel owl-theme" data-nav-dots="true" data-items="1" data-md-items="1" data-sm-items="1"
          data-xs-items="1" data-space="30">
          <div class="item">
            <div class="testimonial-block">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="testimonial-avtar">
                    <img class="img-fluid center-block" src="assets/images/testimonial/test-1.jpeg" alt="">
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <div class="testimonial-content">
                    <p><i class="fa fa-quote-left"></i> <span>Thank you, Premium Auto Gallery, in all the assistance in
                        getting my car. I am very happy. Car is beautiful and they kept their word about delivering one
                        amazing car. </span> <i class="fa fa-quote-right float-end"></i></p>
                  </div>
                  <div class="testimonial-info">
                    <h6>Miss Grace Nyasha</h6>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="testimonial-avtar">
                    <img class="img-fluid center-block" src="assets/images/testimonial/test-2.jpeg" alt="">
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <div class="testimonial-content">
                    <p><i class="fa fa-quote-left"></i> <span>Thank you for exceptional services. Very happy to hit the
                        road in my dream car. Amazing customer service by your team Premium Autos.</span> <i
                        class="fa fa-quote-right float-end"></i></p>
                  </div>
                  <div class="testimonial-info">
                    <h6>Mr. George
                    </h6>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="testimonial-avtar">
                    <img class="img-fluid center-block" src="assets/images/testimonial/test-3.jpeg" alt="">
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <div class="testimonial-content">
                    <p><i class="fa fa-quote-left"></i> <span>Good morning Mr. Nazir,
                        Very happy with my new Mazda CX-5 I purchased through Premium Auto Gallery. The whole buying
                        process was easy and went smoothly. The car was delivered to my doorstep, it was in a very good
                        condition, serviced and clean inside and out. I would definitely recommend to come to Premium
                        Auto Gallery if you are interested to buy a car from Japan. They will make sure that you get the
                        best vehicle you could get. Any question will be answered promptly and you even can rely on
                        their service center for later maintenance or repairs. Great customer service and excellent
                        experience!
                        Detlef Nolden, German Embassy, Administration.

                      </span> <i class="fa fa-quote-right float-end"></i></p>
                  </div>
                  <div class="testimonial-info">
                    <h6>Mr Detlef Nolden</h6>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="row">
                <div class="col-lg-3 col-md-3 colsa-sm-3">
                  <div class="testimonial-avtar">
                    <img class="img-fluid center-block" src="assets/images/testimonial/test-4.webp" alt="">
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <div class="testimonial-content">
                    <p><i class="fa fa-quote-left"></i> <span>Thank you, Jawad, for you and your teams exceptional
                        service. We called you on a public holiday and you were kind enough to come to the show room and
                        show us the vehicles you had in store and facilitated the purchase of the vehicle. We greatly
                        appreciate this exceptional service.
                        We also appreciated the insights you shared on the vehicles you had in stock and the options
                        that you were presenting for us. We had been searching for a vehicle for weeks with no luck on
                        something that could meet our specifications, certainly your assistance was insightful for us to
                        make the necessary purchase decision, which was the type of the car to purchase. Again,
                        following the public holidays, your exceptional team completed the registration process for us
                        and called us in when everything was complete.
                        We are yet to complete the change of ownership process and we will be sure to reach out if we
                        need anything else further from.
                        Many thanks,
                        Nyasha Mabasa.


                      </span> <i class="fa fa-quote-right float-end"></i></p>
                  </div>
                  <div class="testimonial-info">
                    <h6>Nyasha Mabasa</h6>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-block">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="testimonial-avtar">
                    <img class="img-fluid center-block" src=" assets\images\ing\Siphiwe Masango.jpg" alt="">
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <div class="testimonial-content">
                    <p><i class="fa fa-quote-left"></i> <span>Swift, Satisfactory Service. Reliable and dependable
                        service provided. We cannot wait to hit the road!
                        Siphiwe Masango

                      </span> <i class="fa fa-quote-right float-end"></i></p>
                  </div>
                  <div class="testimonial-info">
                    <h6>Siphiwe Masango</h6>

                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>
</section>

<!--=================================
 testimonial -->


<!--=================================
 Newsletter -->

<!-- <section class="news-letter-main bg-1 bg-overlay-black-70">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h4 class="text-red">Subscribe to Our Newsletter Feed</h4>
          <p class="text-white">Simply Dummy Text of the Printin Galley of Type and Bled it to Make a Type.</p>
        </div>
        <div class="col-md-6">
          <form class="news-letter-form">
            <div class="row g-0">
              <div class="col-md-9 col-sm-8 col-7"><input type="email" placeholder="Enter your Email"
                  class="form-control placeholder"></div>
              <div class="col-md-3 col-sm-4 col-5 d-grid">
                <a class="button red btn-block" href="#">Subscribe</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section> -->

<!--=================================
 Newsletter -->