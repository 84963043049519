import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Constants } from '../util/constant';
import { catchError } from 'rxjs/operators';
import { ConditionalExpr } from '@angular/compiler';
//import { CustomerService } from '../customer.service';


@Injectable({
  providedIn: 'root'
})
export class product_listing {

  constructor(private http: HttpClient) {

  }
  TotalRecord(): Observable<any> {
   // console.log('123');
   // let params = new HttpParams();
//params = params.append('param_1', offset);
//params = params.append('param_2', 'value-2');
    const headers = new HttpHeaders()
      // .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
    //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
    return this.http.post(Constants.ToTAL_RECORD_URL, { headers: headers }).pipe(catchError(this.handleError));
  }
  saveUserData(offset: any, pageNo: any,minValue:any,maxValue:any,yearsValueSelected:any,conditionValueSelected:any,bodyValueSelected:any,modelSlectedValues:any,exteriorColorSlectedValues:any,Search:any,make:any,location:any): Observable<any> {
    //console.log('123');
   // let params = new HttpParams();
//params = params.append('param_1', offset);
//params = params.append('param_2', 'value-2');
    const headers = new HttpHeaders()
      // .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
    //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
    return this.http.get(Constants.GET_CAR_DETAIL_URL + '?offset=' + offset + '&pageNo=' + pageNo + '&minValue='+minValue+ '&maxValue='+maxValue+ '&yearsValueSelected='+yearsValueSelected+'&conditionValueSelected='+conditionValueSelected+'&bodyValueSelected='+bodyValueSelected+'&modelSlectedValues='+modelSlectedValues+'&exteriorColorSlectedValues='+exteriorColorSlectedValues+ '&Search=' + Search+ '&make=' + make+ '&location=' + location, { headers: headers }).pipe(catchError(this.handleError));
  }
  savefavoriteData(userId: any, vacId: any, check: any): Observable<any> {
   // console.log('123');
   // let params = new HttpParams();
//params = params.append('param_1', offset);
//params = params.append('param_2', 'value-2');
    const headers = new HttpHeaders()
      // .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
    //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
    return this.http.post(Constants.FAVORITES+'?userId='+userId+'&vacId='+vacId+'&favrorit='+check, { headers: headers }).pipe(catchError(this.handleError));
  }
  Selectedfavorite(userId: any): Observable<any> {
    // console.log('123');
    // let params = new HttpParams();
 //params = params.append('param_1', offset);
 //params = params.append('param_2', 'value-2');
     const headers = new HttpHeaders()
       // .set('app_user_id', this.customerService.getLogedInUserId())
       .set('Content-Type', 'application/json')
     //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
     return this.http.post(Constants.Selectedfavorite+'?userId='+userId, { headers: headers }).pipe(catchError(this.handleError));
   }
  ImgExist(ImgName: any,VecId:any): Observable<any> {
     //console.log('123');
    // let params = new HttpParams();
 //params = params.append('param_1', offset);
 //params = params.append('param_2', 'value-2');
     const headers = new HttpHeaders()
       // .set('app_user_id', this.customerService.getLogedInUserId())
       .set('Content-Type', 'application/json')
     //  .set('Authorization', 'Bearer ' + this.customerService.getToken());
     return this.http.post(Constants.Image_Exist+'?ImgName='+ImgName +'&VecId='+VecId, { headers: headers }).pipe(catchError(this.handleError));
   }
  //advAgcActiveInactive(id: any, status: any): Observable<T:any> {
  // const headers = new HttpHeaders()
  //.set('app_user_id', this.customerService.getLogedInUserId())
  // .set('Content-Type', 'application/json')
  // .set('Authorization', 'Bearer ' + this.customerService.getToken());

  //return this.http.get(Constants.GET_ADMIN_DROPDOWN_URL + '/updateStatus?id=' + id + '&status=' + status, { headers: headers }).pipe(catchError(this.handleError));
  //}
  //saveDataProcess(): Observable<any> {
  /*  const headers = new HttpHeaders()
    .set('app_user_id', this.customerService.getLogedInUserId())
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.customerService.getToken());
    //console.log.log(JSON.stringify(company));
    return this.http.post(Constants.SAVE_DATA_RESPONSES_REQUEST,
      JSON.stringify(dataRequest), { headers: headers }).pipe(catchError(this.handleError));*/
  //}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      //console.log.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}