import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import Validation from '../utils/validation';
import { profile } from './profile.service';


import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  form !: FormGroup;
  submitted = false;
  loader: any = false;
  loginValue: any = '';
  data: any = ''
  constructor(public registration: profile, private formBuilder: FormBuilder) {
    function passwordValidator(control: any) {
      const password = control.value;

      // Check if the password contains at least one capital letter, one special character, and one number
      const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/;

      return regex.test(password) ? null : { invalidPassword: true };
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {

        month: ['', Validators.required],
        year: ['', Validators.required],
        country: ['', Validators.required],
        gender: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        Day: ['', Validators.required],
      },
    );
    this.loginValue = localStorage.getItem('UserID')
    console.log(this.loginValue)
    this.product_listing()
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  product_listing() {
    this.loader = true
    //var offset=2;
    //var pageNo=2;
    //console.log(this.bodyValueSelected);
    this.registration.saveUserData(this.loginValue).subscribe((r: any) => {
      if (r.successfull) {
        this.data = r.content[0];
        console.log(this.data);
        const date = new Date(this.data.DOB);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        this.form.controls['firstName'].setValue(this.data.FIRST_NAME);
        this.form.controls['lastName'].setValue(this.data.LAST_NAME);
        this.form.controls['userName'].setValue(this.data.USER_NAME);
        this.form.controls['email'].setValue(this.data.EMAIL);
        this.form.controls['phoneNumber'].setValue(Number(this.data.HOME_PHONE));
        this.form.controls['Day'].setValue(day);
        this.form.controls['month'].setValue(month);
        this.form.controls['year'].setValue(year);
        this.form.controls['gender'].setValue(this.data.GENDER);
        this.form.controls['country'].setValue(this.data.COUNTRY);
      }
      this.loader = false
    })
  }
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loader = true;
    //console.log(this.form.value);
    this.registration.ProfileUserData(this.form.value, this.loginValue).subscribe((r: any) => {
      //console.log(r.succesfull);
      if (r.success == true) {
        Swal.fire('Updated', 'Usser Profile is succeccfully Updated!', 'success');
        this.submitted = false;
        //this.form.reset();
        //console.log("hey");
      } else {
        Swal.fire('Register', 'Usser is Already Registered with this Email!', 'error');
      }
      this.loader = false
    })
    
  }



  passwordValidator(control: any) {
    const password = control.value;

    // Check if the password contains at least one capital letter, one special character, and one number
    const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]+$/;

    return regex.test(password) ? null : { invalidPassword: true };
  }




  usedCarType: boolean = true;
  loadAPI: any = "";
  url4: any = "assets/js/select/jquery-select.js";

  usedCarTypeFun(val: any) {
    this.usedCarType = val;
  }
  loadScript() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.src = this.url4;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
} 