<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-11 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">Service </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>Service </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!--=================================
   inner-intro -->


<!--=================================
   service -->

<section class="inner-service white-bg page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                           <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of bodyValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
              <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
            </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row g-0">
            <div class="col-md-4 col-sm-6 box-border">
              <div class="feature-box-2 text-center">
                <div class="icon">
                  <i class="glyph-icon flaticon-car"></i>
                </div>
                <div class="content">
                  <h5>Affordable Vehicles</h5>
                  <p style="min-height:120px;text-align:justify">We offer a wide range of vehicles at competitive
                    prices, ensuring that you can
                    find a quality vehicle that fits your budget.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 box-border">
              <div class="feature-box-2 text-center">
                <div class="icon">
                  <i class="glyph-icon flaticon-wallet"></i>
                </div>
                <div class="content">
                  <h5>Delivery Service</h5>
                  <p style="min-height:120px;text-align:justify">We offer convenient delivery services, including transportation of vehicles from
                    Durban and auction houses in Japan to Harare. Count on us to handle the logistics and ensure your
                    vehicle reaches you safely and efficiently.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6 box-border">
              <div class="feature-box-2 no-br text-center">
                <div class="icon">
                  <i class="glyph-icon flaticon-gas-station"></i>
                </div>
                <div class="content">
                  <h5>Ownership Transfer Assistance</h5>
                  <p style="min-height:120px;text-align:justify">We understand the complexities involved in ownership
                    transfer
                    processes. Our knowledgeable team will guide you through the necessary steps and paperwork to
                    facilitate a smooth transfer of ownership for your vehicle.</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6 box-border">
              <div class="feature-box-2 no-bb text-center">
                <div class="icon">
                  <i class="glyph-icon flaticon-gearstick"></i>
                </div>
                <div class="content">
                  <h5>Vehicle Sourcing</h5>
                  <p style="min-height:120px;text-align:justify">Looking for a specific make or model? Our dedicated
                    team can help you find the
                    vehicle you desire. We have access to an extensive network, allowing us to source vehicles based on
                    your preferences.</p>
                </div>
              </div>
            </div>


            <div class="col-md-4 col-sm-6 box-border">
              <div class="feature-box-2 text-center">
                <div class="icon">
                  <i class="glyph-icon flaticon-car"></i>
                </div>
                <div class="content">
                  <h5>Professional Advice</h5>
                  <p style="min-height:120px;text-align:justify">Our knowledgeable staff is always available to provide
                    expert advice and
                    answer any questions you may have. We're committed to ensuring you make informed decisions
                    throughout your car-buying journey
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>