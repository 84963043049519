<div id="top"></div>
<!--<div id="loading">
    <div id="loading-center">
      <img src="assets/images/loader3.gif" alt="">
    </div>
  </div>-->
<!--=================================
 header -->



<!--=================================
 header -->

<router-outlet></router-outlet>

<!--=================================
 footer -->


 <div class="app-footer"></div>

<!--=================================
 footer -->



 <!--=================================
 back to top -->

<div class="car-top">
  <span><img src="images/car.png" alt=""></span>
</div>
