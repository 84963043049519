<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-6 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">blog </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>blog fullwidth </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="blog page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)" [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}" required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>

      <div class="col-lg-9 col-md-8 mt-0">
        <div class="col-md-12">
          <div class="section-title">
            <span>Read what we say in our blog </span>
            <h2>Our blog</h2>
            <div class="separator"></div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <div class="blog-entry">
              <div class="blog-entry-image  clearfix">
                <div class="portfolio-item">
                  <img src="assets/images/ing/blog/img-1.jpg" alt="">
                </div>
              </div>
              <div class="entry-title">
                <a href="#">Top 5 Family-Friendly SUVs for South African Car Buyers</a>
              </div>
              <div class="entry-meta">
                <ul>
                  <li><a href="#"><i class="fa fa-user"></i> By Nazia Khan</a> /</li>

                  <li><a href="#"><i class="fa fa-folder-open"></i> News 2023</a> </li>


                </ul>
              </div>
              <div class="entry-content">
                <p>As the leading car dealership in South Africa, we understand the importance of finding the perfect
                  family vehicle that suits the diverse needs of South African car buyers. In this blog, we'll explore
                  the
                  top five family-friendly SUVs that cater to the unique preferences and requirements of South African
                  car
                  owners.</p>
              </div>
              <div class="entry-share clearfix">
                <a class="button red float-start" [routerLink]="['/Blog-Details', '1']"
                  [ngClass]="{active: mainC === 'gi'}" [ngClass]="{active: mainC === 'gi'}"> Read More </a>
                <div class="share float-end"><a href="#"> <i class="fa fa-share-alt"></i> </a>
                  <div class="blog-social">
                    <ul class="list-style-none">
                      <li> <a href="#"><i class="fa fa-facebook"></i></a> </li>
                      <li> <a href="#"><i class="fa fa-twitter"></i></a> </li>
                      <li> <a href="#"><i class="fa fa-instagram"></i></a> </li>
                      <li> <a href="#"><i class="fa fa-pinterest-p"></i></a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr class="gray">
            <!-- BLOG2============================================ -->
            <div>


              <div>

                <div class="blog-entry">
                  <div class="blog-entry-image  clearfix">
                    <div class="portfolio-item">
                      <img src="assets/images/ing/blog/img-2.jpg" alt="">
                    </div>
                  </div>
                  <div class="entry-title">
                    <a href="#">Navigating Zimbabwean Terrain: Best Cars for Adventurous Car Owners</a>
                  </div>
                  <div class="entry-meta">
                    <ul>
                      <li><a href="#"><i class="fa fa-user"></i> By Nazia Khan </a> /</li>

                      <li><a href="#"><i class="fa fa-folder-open"></i> News 2023</a> </li>

                    </ul>
                  </div>
                  <div class="entry-content">
                    <p>The Land Rover Defender is an iconic off-road vehicle that can conquer Zimbabwe's rugged
                      landscapes
                      with ease. Its sturdy construction, advanced four-wheel-drive system, and high ground clearance
                      make
                      it a go-to choice for adrenaline seekers who wish to delve deep into Zimbabwe's untamed
                      wilderness.
                    </p>
                  </div>
                  <div class="entry-share clearfix">
                    <a class="button red float-start" [routerLink]="['/Blog-Details', '2']"
                      [ngClass]="{active: mainC === 'fo '}" [ngClass]="{active: mainC === 'fo'}"> Read More </a>
                    <div class="share float-end"><a href="#"> <i class="fa fa-share-alt"></i> </a>
                      <div class="blog-social">
                        <ul class="list-style-none">
                          <li> <a href="#"><i class="fa fa-facebook"></i></a> </li>
                          <li> <a href="#"><i class="fa fa-twitter"></i></a> </li>
                          <li> <a href="#"><i class="fa fa-instagram"></i></a> </li>
                          <li> <a href="#"><i class="fa fa-pinterest-p"></i></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="gray">
              </div>

            </div>
            <!-- ============================================ -->


            <!--Blog3-->
            <div>


              <div>
                <div class="blog-entry">
                  <div class="blog-entry-image  clearfix">
                    <div class="portfolio-item">
                      <img src="assets/images/ing/blog/blog-3.jpg" alt="">
                    </div>
                  </div>
                  <div class="entry-title">
                    <a href="#">Going Green: The Rise of Electric Vehicles in South Africa and Zimbabwe</a>
                  </div>
                  <div class="entry-meta">
                    <ul>
                      <li><a href="#"><i class="fa fa-user"></i> By Nazia Khan </a> /</li>

                      <li><a href="#"><i class="fa fa-folder-open"></i> News 2023</a> </li>

                    </ul>
                  </div>
                  <div class="entry-content">

                    <p>Both South Africa and Zimbabwe face environmental challenges, and EVs present an opportunity to
                      reduce carbon emissions and combat air pollution. By switching to electric vehicles, car owners
                      can
                      contribute to cleaner air and a healthier environment for future generations.</p>

                  </div>
                  <div class="entry-share clearfix">
                    <a class="button red float-start" [routerLink]="['/Blog-Details', '3']"
                      [ngClass]="{active: mainC === 'vo'}" [ngClass]="{active: mainC === 'vo'}"> Read More </a>
                    <div class="share float-end"><a href="#"> <i class="fa fa-share-alt"></i> </a>
                      <div class="blog-social">
                        <ul class="list-style-none">
                          <li> <a href="#"><i class="fa fa-facebook"></i></a> </li>
                          <li> <a href="#"><i class="fa fa-twitter"></i></a> </li>
                          <li> <a href="#"><i class="fa fa-instagram"></i></a> </li>
                          <li> <a href="#"><i class="fa fa-pinterest-p"></i></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <!-- ============================================ 
        <div class="pagination-nav  d-flex justify-content-center">
          <ul class="pagination">
            <li><a href="#">«</a></li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">»</a></li>
          </ul>
        </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>