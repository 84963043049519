import { Component, OnInit } from '@angular/core';
import { homeService } from './home.service';
import { Options,LabelType } from '@angular-slider/ngx-slider';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  modelValues:any=[{'value':'3-Series','checked':false},{'value':'Boxter','checked':false},{'value':'Carrera','checked':false},{'value':'F-type','checked':false},{'value':'GT-R','checked':false},{'value':'GTS','checked':false},{'value':'M6','checked':false},{'value':'Macan','checked':false},{'value':'Mazda','checked':false},{'value':'RLX','checked':false},{'value':'FIT','checked':false},{'value':'Aqua','checked':false}]
  bodyValues:any=[{'value':'2 Door','checked':false},{'value':'4 Door','checked':false},{'value':'Convertible','checked':false},{'value':'Sedan','checked':false},{'value':'Sport','checked':false},{'value':'Van','checked':false},{'value':'Wagon','checked':false},{'value':'Truck','checked':false},{'value':'Hatchback','checked':false},{'value':'SUV','checked':false}]
  yearsValues: any =[{'value':'2000','checked':false},{'value':'2001','checked':false},{'value':'2002','checked':false},{'value':'2003','checked':false},{'value':'2004','checked':false},{'value':'2005','checked':false},{'value':'2006','checked':false},{'value':'2007','checked':false},{'value':'2008','checked':false},{'value':'2009','checked':false},{'value':'2010','checked':false},{'value':'2011','checked':false},{'value':'2012','checked':false},{'value':'2013','checked':false},{'value':'2014','checked':false},{'value':'2015','checked':false},{'value':'2016','checked':false},{'value':'2017','checked':false},{'value':'2018','checked':false},{'value':'2019','checked':false},{'value':'2020','checked':false},{'value':'2021','checked':false},{'value':'2022','checked':false},{'value':'2023','checked':false}]
  loader :any =false;
  minValue: number = 1000;
  maxValue: number = 100000;
  options: Options = {
    floor: 1000,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return "$" + value;
      }
    }
  };
  PriceRangeChange(){
    this.minValue=this.minValue
    this.maxValue=this.maxValue
   }
  usedCarType: boolean = true;
  loadAPI: any="";
  data: any=[];
  constructor(public homePageService:homeService,private router: Router){}
  //url1:any = "assets/js/mega-menu/mega_menu.js";
//url2:any =  "assets/js/owl-carousel/owl.carousel.min.js";
  //url3:any =   "assets/js/jquery-ui.js";
 // url5:any =  "assets/js/custom.js";
  //url4:any =   "assets/js/select/jquery-select.js";
  url: any = ["/assets/js/owl-carousel/owl.carousel.min.js","/assets/js/custom.js"];
  addUrl: any = [];
  Search:any =[];
  ngOnInit(){
    localStorage.setItem('type','');
    localStorage.setItem('searchVal', '');
    this.loadAPI = new Promise(resolve => {
      console.log("resolving promise...");
      this.loadScript();
      this.home('all');
      this.newArrival('all')
    });
    this.totalrecord()
    this.List_of_body_Type()
    this.List_of_Make()
  }
  newArrivalVal: any = '';
  newArrival(val:any){
    this.newArrivalVal = val;
    this.newArrivalData = [];
    console.log(val)
    this.homePageService.newArrival(val).subscribe((r:any) => {
      // console.log(r.content);
       //this.data=r.content;
       //console.log(r.content);
       this.newArrivalData = r.content;
       //this.checkIfImageExistsNew(r.content);
       this.loader=false;
     })
  }
  ArrivalMore(){
    this.locationVal = this.newArrivalVal;
    this.SearchBut();
   // let val: any = {'location':this.newArrivalVal};
   // console.log(val);
   // localStorage.setItem('searchVal',JSON.stringify(val));
   // this.trackEvents(this.newArrivalVal)
   }
   featureMore(){
    this.trackEvents(this.feature)
   }
  checkIfImageExists(name:any) {
    this.data = name;
    /*name.forEach( (item: any) =>{
      let imagesPath = 'assets/images/364x260/S'+item.Stock_No+'A.jpg';
            const img = new Image();
            img.src = imagesPath;
      img.onload = () => {
        item.img = true;
       }
      img.onerror = () => {
        item.img = false;
       
      }
     this.data.push(item);
    })
    */
  
  }
  newArrivalData: any = [];
  checkIfImageExistsNew(name:any) {
    this.newArrivalData = name;
    /*name.forEach( (item: any) =>{
      let imagesPath = 'assets/images/364x260/S'+item.Stock_No+'A.jpg';
            const img = new Image();
            img.src = imagesPath;
      img.onload = () => {
        item.img = true;
       }
      img.onerror = () => {
        item.img = false;
      }
     this.newArrivalData.push(item);
    // console.log(this.newArrivalData);
    })*/

  
  }
  feature:any='';
  home(val:any){
    this.feature=val
    console.log(this.feature)
    this.loader=true
    this.data=[];
    this.homePageService.saveUserData(val).subscribe((r:any) => {
      console.log(r.content);
      //this.data=r.content;
      this.data = r.content;
      //this.checkIfImageExists(r.content);
      this.loader=false
    })
   }
   
   makeVal: any = '';
   offsetValueChange1(val : any){
    this.makeVal = val.value;
    //this.Search.push(val.value)
   }
   modelVal: any=''
   offsetValueChange2(val : any){
    this.modelVal = val.value;
   }
   yearVal:any=""
   offsetValueChange3(val : any){
    this.yearVal = val.value;
   }
   bodyVal:any=""
   offsetValueChange4(val : any){
    this.bodyVal = val.value;
   }
   locationVal:any=""
   offsetValueChange5(val : any){
    this.locationVal = val.value;
   }
   trackEvents(val : any){
    this.bodyVal = val;
    this.SearchBut();
    console.log(this.bodyVal);
   // this.router.navigate(['Product-Listing']);
    //localStorage.setItem('type',val);
   }
   makeEvents(val : any){
    this.makeVal = val;
    this.SearchBut();
   // this.router.navigate(['Product-Listing']);
   // localStorage.setItem('make',val);
   }
   SearchBut(){
    let val: any = {'make':this.makeVal,'model':this.modelVal,'year':this.yearVal,'body':this.bodyVal,'location':this.locationVal,'min':this.minValue,'max':this.maxValue};
   console.log(val + 'Here is Value');
    let route
    if(this.locationVal === 'Harare'){
      route = '/Harare-Listing'
    }else if(this.locationVal === 'Tokyo'){
      route = '/Tokyo-Listing'
    }else(
      route = '/Stock-Listing'
    )
    
    localStorage.setItem('searchVal',JSON.stringify(val));
    this.router.navigate([route]);
   }
  usedCarTypeFun(val:any){
    this.usedCarType = val;
  }
   loadScript() {
   // console.log(this.url);
    if(this.url.length>0){
      this.url.forEach((element:any) => {
        let node = document.createElement("script");
        node.src = element;
        node.type = "text/javascript";
        node.async = true;
        document.getElementsByTagName("head")[0].appendChild(node);
        this.addUrl.push(node);
      });
    }
}
ngAfterViewInit() {
  // Hack: Scrolls to top of Page after page view initialized
  let top = document.getElementById('top');
  if (top !== null) {
    top.scrollIntoView();
    top = null;
  }
}
PostData(val: any) {
  //localStorage.setItem('BodyDetail', val1);
  //localStorage.setItem('MakeDetail', val2);
  //localStorage.setItem('ModelDetail', val3);
  this.router.navigate(['Stock-Detail', val]);
  //console.log(val)
}
ngOnDestroy() {
  //console.log('222222');
  //console.log(this.addUrl);
  this.addUrl.forEach((link:any) => {
    link.parentNode.removeChild(link);
  });
}
Record : any = 0
    totalrecord(){
      this.homePageService.TotalRecord().subscribe((r: any) => {
        if(r.successfull){
          this.Record = r.Record;
          localStorage.setItem('totalRecord',r.Record);
         // console.log(this.Record)
        }
      })    
  }
  list_of_make : any = []
  List_of_Make(){
    this.homePageService.Make().subscribe((r: any) => {
      if(r.successfull){
        this.list_of_make = r.content;
       // console.log(this.Record)
      }
    })    
}
list_of_body_Type : any = []
  List_of_body_Type(){
    this.homePageService.Body_Type().subscribe((r: any) => {
      if(r.successfull){
        this.list_of_body_Type = r.content;
        console.log(typeof(this.list_of_body_Type))
       // console.log(this.Record)
      }
    })    
}
}
