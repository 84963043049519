import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { TeamComponent } from './team/team.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'; 
import { ContactUsComponent } from './contact-us/contact-us.component'; 
import { ServiceComponent } from './service/service.component'; 
import { FaqComponent } from './faq/faq.component'; 
import { LogInComponent } from './log-in/log-in.component'; 
import { RegistrationComponent } from './registration/registration.component'; 
import { BlogComponent } from './blog/blog.component'; 
import { PasswordResetComponent } from './password-reset/password-reset.component'; 
import { ProductListingComponent } from './product-listing/product-listing.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
const routes: Routes = [
  {
    path: 'Home',
    component: HomeComponent,
    canActivate: []
  },
 
  {
    path: 'Blog-Details/:id',
    component:  BlogDetailComponent,
    canActivate: []
  },
  {
    path: 'Stock-Detail/:id',
    component:  ProductDetailsComponent,
    canActivate: []
  },
  {
    path: 'Stock-Listing',
    component: ProductListingComponent,
    canActivate: []
  },
  {
    path: 'Harare-Listing',
    component: ProductListingComponent,
    canActivate: []
  },
  {
    path: 'Durban-Listing',
    component: ProductListingComponent,
    canActivate: []
  },
  {
    path: 'Tokyo-Listing',
    component: ProductListingComponent,
    canActivate: []
  },
  {
    path: 'Password-Reset',
    component: PasswordResetComponent,
    canActivate: []
  },
  {
    path: 'Blog',
    component: BlogComponent,
    canActivate: []
  },
  {
    path: 'Log-In',
    component: LogInComponent,
    canActivate: []
  },
  {
    path: 'Registration',
    component: RegistrationComponent,
    canActivate: []
  },
  {
    path: 'Faq',
    component: FaqComponent,
    canActivate: []
  },
  {
    path: 'Service',
    component: ServiceComponent,
    canActivate: []
  },
  {
   path: 'About-Us',
   component: AboutUsComponent,
   canActivate: []
 },
 {
  path: 'Contact-Us',
  component: ContactUsComponent,
  canActivate: []
},
 {
  path: 'privacy-policy',
  component: PrivacyPolicyComponent,
  canActivate: []
},
 {
  path: 'team',
  component: TeamComponent,
  canActivate: []
},
{
  path: 'terms',
  component: TermsComponent,
  canActivate: []
},
{
  path: 'Testimonial',
  component: TestimonialComponent,
  canActivate: []
},



  {
    path: '',
    redirectTo: 'Home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: HomeComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
