<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">blog </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>blog fullwidth </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="blog page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of bodyValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-title">
                <span>Read what we say in our blog </span>
                <h2>Our Blog</h2>
                <div class="separator"></div>
              </div>
            </div>
          </div>
          <!--BLOG1 ============================================ -->

          <div class="row">
            <div class="col-md-12">
              <div class="blog-entry">
                <div class="blog-entry-image  clearfix">
                  <!--BLOG1 ============================================ -->
                  <div *ngIf="mainC === '1'" [ngClass]="{active: mainC === '1'}">
                    <h4 class="sec-title3">Toyota Fortuner:</h4>
                    <div class="portfolio-item">
                      <img src="/assets/images/ing/blog/img-1.jpg" alt="" style="width: 1140px;height: 450px;">
                    </div>

                    <div class="entry-title">
                      <a href="#">Top 5 Family-Friendly SUVs for South African Car Buyers</a>
                    </div>

                    <div class="entry-meta">
                      <ul>
                        <li><a href="#"><i class="fa fa-user"></i> By Nazia Khan</a> /</li>

                        <li><a href="#"><i class="fa fa-folder-open"></i> News 2023</a> </li>

                      </ul>
                    </div>
                    <h4 class="sec-title3">Introduction:</h4>
                    <p>As the leading car dealership in South Africa, we understand the importance of finding the
                      perfect
                      family vehicle that suits the diverse needs of South African car buyers. In this blog, we'll
                      explore
                      the top five family-friendly SUVs that cater to the unique preferences and requirements of South
                      African car owners.</p>
                    <div class="entry-content">
                      <p>The Toyota Fortuner has gained immense popularity among South African families due to its
                        robust
                        build, spacious interior, and reliable performance. With its off-road capabilities, the Fortuner
                        is perfect for adventurous weekend getaways to explore South Africa's picturesque landscapes.
                      </p>
                    </div>



                    <div>
                      <h4 class="sec-title3">Ford EcoSport:</h4>
                      <div class="blog-entry">
                        <div class="blog-entry-image  clearfix">
                          <div class="portfolio-item">
                            <img src="/assets/images/ing/blog/img-2-1.jpg" alt="" style="width: 1140px;height: 450px;">
                          </div>
                        </div>
                        <div class="entry-content">
                          <p>For urban families seeking a compact SUV, the Ford EcoSport is an ideal choice. Its small
                            size makes it easy to maneuver through busy city streets, while its fuel efficiency helps
                            curb
                            rising fuel costs. The EcoSport's modern design and advanced safety features provide a sense
                            of reassurance for families on the move.</p>
                        </div>

                      </div>
                    </div>


                    <div>
                      <h4 class="sec-title3">Nissan X-Trail:</h4>
                      <div class="blog-entry">
                        <div class="blog-entry-image  clearfix">
                          <div class="portfolio-item">
                            <img src="/assets/images/ing/blog/img-3.jpg" alt="">
                          </div>
                        </div>


                        <div class="entry-content">
                          <p>The Nissan X-Trail strikes a perfect balance between style, comfort, and practicality,
                            making
                            it a favorite among South African car buyers. Its spacious cabin ensures a comfortable ride
                            for both the driver and passengers, while the advanced technology and safety features offer
                            peace of mind during long journeys.</p>
                        </div>

                      </div>
                    </div>

                    <div>
                      <h4 class="sec-title3">
                        Volkswagen Tiguan:
                      </h4>
                      <div class="blog-entry">
                        <div class="blog-entry-image  clearfix">
                          <div class="portfolio-item">
                            <img src="/assets/images/ing/blog/img-4.jpg" alt="">
                          </div>
                        </div>

                        <div class="entry-content">
                          <p>The Volkswagen Tiguan appeals to South African families who value a touch of luxury
                            combined
                            with versatility. With its premium interior, smooth handling, and ample cargo space, the
                            Tiguan offers a premium driving experience for the entire family.</p>
                        </div>

                      </div>
                    </div>

                    <div>
                      <h4 class="sec-title3">
                        Hyundai Creta:
                      </h4>
                      <div class="blog-entry">
                        <div class="blog-entry-image  clearfix">
                          <div class="portfolio-item">
                            <img src="/assets/images/ing/blog/img-5.jpg" alt="">
                          </div>
                        </div>

                        <div class="entry-content">
                          <p>The Hyundai Creta has become a go-to choice for budget-conscious South African car buyers
                            seeking a reliable and affordable SUV. Despite its competitive price, the Creta boasts an
                            array of features and a stylish design, making it an excellent option for young families.
                          </p>
                        </div>

                      </div>
                    </div>
                    <h4 class="sec-title3">
                      Conclusion
                    </h4>
                    <p>Choosing the perfect family-friendly SUV is essential for South African car owners, considering
                      their unique lifestyle and needs. Each of the mentioned SUVs offers a distinct set of features and
                      benefits, catering to various preferences. As the leading car dealership in South Africa, we are
                      committed to helping our customers find the ideal vehicle that best suits their family's
                      requirements.</p>
                  </div>
                  <!--BLOG2 ============================================ -->
                  <div *ngIf="mainC === '2'" [ngClass]="{active: mainC === '2'}">
                    <h4 class="sec-title3">Land Rover Defender: </h4>
                    <div class="blog-entry">
                      <div class="blog-entry-image  clearfix">
                        <div class="portfolio-item">
                          <img src="/assets/images/ing/blog/img-2.jpg" alt="">
                        </div>
                      </div>
                      <div class="entry-title">
                        <a href="#">Navigating Zimbabwean Terrain: Best Cars for Adventurous Car Owners</a>
                      </div>

                      <div class="entry-meta">
                        <ul>
                          <li><a href="#"><i class="fa fa-user"></i> By Nazia Khan</a> /</li>

                          <li><a href="#"><i class="fa fa-folder-open"></i> News 2023</a> </li>

                        </ul>
                      </div>
                      <h4 class="sec-title3">Introduction:</h4>
                      <p class="entry-content">Zimbabwe's diverse terrain offers a paradise for adventurous car owners
                        who
                        seek to explore its natural wonders and cultural heritage. As the premier car dealership in
                        Zimbabwe, we present the top car choices for those daring Zimbabwean car buyers ready to embark
                        on
                        thrilling adventures.</p>
                      <div class="entry-content">
                        <p>The Land Rover Defender is an iconic off-road vehicle that can conquer Zimbabwe's rugged
                          landscapes with ease. Its sturdy construction, advanced four-wheel-drive system, and high
                          ground
                          clearance make it a go-to choice for adrenaline seekers who wish to delve deep into Zimbabwe's
                          untamed wilderness.</p>
                      </div>
                    </div>
                    <h4 class="sec-title3">Isuzu D-Max: </h4>
                    <div class="blog-entry">
                      <div class="blog-entry-image  clearfix">
                        <div class="portfolio-item">
                          <img src="/assets/images/ing/blog/img-7.jpg" alt="">
                        </div>
                      </div>

                      <div class="entry-content">
                        <p>The Isuzu D-Max is a rugged pickup truck, well-suited for Zimbabwean car owners with a taste
                          for outdoor adventures. Whether it's navigating through muddy terrains or crossing shallow
                          rivers, the D-Max's robust design and powerful engine ensure a smooth and reliable ride, no
                          matter the conditions.</p>
                      </div>
                    </div>
                    <h4 class="sec-title3">Toyota Hilux:</h4>
                    <div class="blog-entry">
                      <div class="blog-entry-image  clearfix">
                        <div class="portfolio-item">
                          <img src="/assets/images/ing/blog/img-8.jpg" alt="">
                        </div>
                      </div>

                      <div class="entry-content">
                        <p>The Toyota Hilux is a favorite among Zimbabwean car buyers, known for its durability and
                          capability to handle rough terrains. From exploring the awe-inspiring Victoria Falls to
                          embarking on safari trips, the Hilux provides the space and versatility required for any
                          adventure.</p>
                      </div>
                    </div>
                    <h4 class="sec-title3">Mitsubishi Pajero:</h4>
                    <div class="blog-entry">
                      <div class="blog-entry-image  clearfix">
                        <div class="portfolio-item">
                          <img src="/assets/images/ing/blog/img-9.jpg" alt="">
                        </div>
                      </div>

                      <div class="entry-content">
                        <p>The Mitsubishi Pajero offers a blend of on-road comfort and off-road capability, making it an
                          excellent choice for Zimbabwean car owners who seek both practicality and adventure. With its
                          spacious interior and advanced features, the Pajero guarantees a comfortable and safe journey.
                        </p>
                      </div>
                    </div>
                    <h4 class="sec-title3">Ford Ranger Raptor: </h4>
                    <div class="blog-entry">
                      <div class="blog-entry-image  clearfix">
                        <div class="portfolio-item">
                          <img src="/assets/images/ing/blog/img-10.jpg" alt="">
                        </div>
                      </div>

                      <div class="entry-content">
                        <p>For thrill-seekers who desire a powerful and exhilarating driving experience, the Ford Ranger
                          Raptor delivers. This high-performance pickup truck is specifically designed for off-road
                          challenges, allowing Zimbabwean car owners to explore the country's wild side with confidence.
                        </p>
                      </div>
                    </div>
                    <h4 class="sec-title3">Conclusion: </h4>
                    <p>Zimbabwean car buyers have a unique taste for adventure, and our selection of vehicles caters to
                      their passion for exploration. Whether it's traversing through rugged landscapes or embarking on a
                      safari to witness wildlife up close, these cars provide the reliability and capability needed to
                      conquer Zimbabwe's diverse terrain.</p>
                  </div>
                  <!--Blog3-->
                  <div *ngIf="mainC === '3'" [ngClass]="{active: mainC === '3'}">
                    <div class="blog-entry">
                      <div class="blog-entry-image  clearfix">
                        <div class="portfolio-item">
                          <img src="/assets/images/ing/blog/blog-3.jpg" alt="">
                        </div>
                      </div>
                      <div class="entry-title">
                        <a href="#">Going Green: The Rise of Electric Vehicles in South Africa and Zimbabwe</a>
                      </div>

                      <div class="entry-meta">
                        <ul>
                          <li><a href="#"><i class="fa fa-user"></i> By Nazia Khan</a> /</li>


                          <li><a href="#"><i class="fa fa-folder-open"></i> News 2023</a> </li>

                        </ul>
                      </div>
                      <h4 class="sec-title3">Introduction</h4>
                      <p class="entry-content">As the world embraces sustainability and environmental consciousness, the
                        automotive industry has witnessed a significant shift towards electric vehicles (EVs). In this
                        blog, we explore the growing popularity of EVs among car buyers in South Africa and Zimbabwe and
                        discuss the benefits of embracing these eco-friendly alternatives.</p>
                      <div class="entry-content">
                        <h4 class="sec-title3">Environmental Impact:</h4>
                        <p class="entry-content">Both South Africa and Zimbabwe face environmental challenges, and EVs
                          present an opportunity to reduce carbon emissions and combat air pollution. By switching to
                          electric vehicles, car owners can contribute to cleaner air and a healthier environment for
                          future generations.</p>
                        <h4 class="sec-title3">Government Incentives:</h4>
                        <p class="entry-content">Governments in both South Africa and Zimbabwe have taken measures to
                          promote the adoption of electric vehicles. Incentives such as tax rebates, reduced import
                          duties, and access to carpool lanes have made EVs more appealing to eco-conscious car buyers.
                        </p>
                        <h4 class="sec-title3">Range and Infrastructure:</h4>
                        <p class="entry-content">One of the concerns for potential EV owners has been the range and
                          availability of charging infrastructure. However, advancements in battery technology have
                          significantly improved the range of EVs, and both countries have been gradually expanding
                          their
                          charging networks.</p>
                        <h4 class="sec-title3">
                          Cost Savings:
                        </h4>
                        <p class="entry-content">While the upfront cost of purchasing an electric vehicle may be higher,
                          the long-term cost savings are notable. EVs have lower maintenance costs and are more
                          energy-efficient, resulting in reduced fuel expenses compared to traditional internal
                          combustion
                          engine vehicles.</p>
                        <h4 class="sec-title3">EV Models Available:</h4>
                        <P>Numerous automakers have introduced electric models suitable for the South African and
                          Zimbabwean markets. From compact city cars to luxury SUVs, there's an electric vehicle to suit
                          every car buyer's preferences and needs.</P>
                        <h4 class="sec-title3">Conclusion: </h4>
                        <p class="entry-content">The transition to electric vehicles represents a positive step towards
                          a
                          sustainable future in both South Africa and Zimbabwe. As the leading car dealership in these
                          regions, we encourage car buyers to consider the numerous benefits of EVs, including their
                          positive impact on the environment, government incentives, cost savings, and the expanding
                          charging infrastructure. Embracing electric vehicles not only enhances the driving experience
                          but also contributes to building greener and cleaner societies.</p>
                      </div>


                    </div>
                  </div>












                  <!-- ============================================ 
        <div class="pagination-nav  d-flex justify-content-center">
          <ul class="pagination">
            <li><a href="#">«</a></li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">»</a></li>
          </ul>
        </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>