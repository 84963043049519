import { NgIf } from '@angular/common';
import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators, AbstractControl, FormGroup, FormControl,  } from "@angular/forms";
import { LogInService } from './log-in.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {
 title = 'Password Match Validator';
  loginForm: FormGroup;
  submitted = false;
  loader:any=false;
  @ViewChild('LoginInput') emailInputElement!: ElementRef<HTMLInputElement>;
  constructor(public registration:LogInService,public router:Router){
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password : new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(40)
      ])
    })
  } 

   login(){
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loader=true;
    //console.log(this.loginForm.value)
    this.registration.saveUserData(this.loginForm.value).subscribe((r:any) => {
      //console.log(r);
      if (r.successfull===true) {
        //console.log(r.tk);
        //console.log(r.content)
        console.log(r.content.CUST_ID)
        localStorage.setItem('UserID',r.content.CUST_ID)
        localStorage.setItem('Token',r.tk)
        localStorage.setItem('UserData',r.content.USER_NAME)
        console.log(r.content.USER_NAME)
        this.router.navigate(['/Home']);
      }else{
        Swal.fire('Login', 'Email And Password are Incorrect', 'error');
      }
      this.loader=false
    })
   }
    ngAfterViewInit() {
    this.emailInputElement.nativeElement.focus();
    // Hack: Scrolls to top of Page after page view initialized
    /*let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }*/
  }
  ngOnInit() : void {}
  
} 
