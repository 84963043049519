<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
    <div class="container">
       <div class="row text-center intro-title">
             <div class="col-md-6 text-md-start d-inline-block">
               <h1 class="text-white">Faq</h1>
             </div>
             <div class="col-md-6 text-md-end float-end">
               <ul class="page-breadcrumb">
                  <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
                  <li><span>faq</span> </li>
               </ul>
             </div>
       </div>
    </div>
  </section>

  

<section class="privacy-policy page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)" [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}" required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
           <div class="section-title">
             <span> Have a question? Please check our knowledgebase first.</span>
             <h2>Frequently Asked Questions </h2>
             <div class="separator"></div>
           </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
           <div id="tabs">
            <div class="tabs-header d-flex justify-content-center">
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                  <li (click)="mainTab('gi')" class="nav-item" role="presentation">
                    <button [ngClass]="{active: mainC === 'gi'}" class="nav-link" id="general-information-tab" data-bs-toggle="tab" data-bs-target="#general-information" type="button" role="tab" aria-controls="general-information" aria-selected="true">General Information</button>
                  </li>
             <!--     <li (click)="mainTab('fo')" class="nav-item" role="presentation">
                    <button [ngClass]="{active: mainC === 'fo'}" class="nav-link" id="features-options-tab" data-bs-toggle="tab" data-bs-target="#features-options" type="button" role="tab" aria-controls="features-options" aria-selected="false">Features & Options</button>
                  </li>
                  <li (click)="mainTab('vo')" class="nav-item" role="presentation">
                    <button [ngClass]="{active: mainC === 'vo'}" class="nav-link" id="vehicle-overview-tab" data-bs-toggle="tab" data-bs-target="#vehicle-overview" type="button" role="tab" aria-controls="vehicle-overview" aria-selected="false">Vehicle Overview</button> 
                  </li>
                -->
                </ul>
              </div>
            <div class="tab-content mb-0" id="myTabContent">

                <div *ngIf="mainC === 'gi'" class="tab-pane show active" role="tabpanel" aria-labelledby="general-information-tab">
                  <div class="accordion" id="accordionExample01">
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('One')" class="accordion-header">
                        <button  id="headingOne" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          How to choose my vehicle?
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          
                          <p>Browse through our stock, select vehicle of your choice and check pictures along with other information about it in detail before choosing and ordering it. If you can’t find vehicle of your choice, kindly let us know what you desire by emailing us or fill in the contact us form from the contact us page.</p>
                          
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Two')" class="accordion-header" >
                        <button id="headingTwo" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          How to place an order for the vehicle I like?
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                        
                          <p>  Once you choose & decide the vehicle, simply click enquiry for getting quotation/invoice on the details page.<br>Once we receive the order, our sales representative will dispatch the invoice by email.</p>
                         
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Three')" class="accordion-header" >
                        <button id="headingThree" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How to proceed with payment once the invoice is issued?
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          
                          <p>After getting invoice from us, complete payment through any bank and share Telegraphic Transfer copy, known as TT via email. Receiving the TT copy and checking our bank, we will email you the confirmation. You can also visit us and make payment in cash. </p>
                           
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Four')" class="accordion-header" >
                        <button id="headingFour" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                          When can the car be transferred in my name?
                        </button>
                      </h2>
                      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                         
                          <p>Once payment is received, we will initiate the documentation process to change the ownership on your name. On completion of documents, we will inform you and you can come and collect your vehicle to drive it home.</p>
                           
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Five')" class="accordion-header" >
                        <button id="headingFive" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                          How can I find car exporters from Japan?
                        </button>
                      </h2>
                      <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          
                          <p>Best exporters of Japanese cars provide cars in good condition, low price, and exceptional exported services. With Premium Auto Gallery all car lovers can get trusted service to import cars to their doors easily along with all these features.</p>
                          
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Six')" class="accordion-header" >
                        <button id="headingSix" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                          Is it easy to import cars in Zimbabwe or South Africa?
                        </button>
                      </h2>
                      <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                         
                          <p>The importation of used vehicles in South Africa & Zimbabwe is restricted to protect the local motor vehicle manufacturing industry of the country. You can only import a second-hand vehicle into South Africa or Zimbabwe if you have been granted a permit to do so. Premium Auto Gallery’s staff with an extensive experience can help in sorting out all these documentations.</p>
                         
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Seven')" class="accordion-header" >
                        <button id="headingSeven" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                          Do you provide with legal advise on buying a car?
                        </button>
                      </h2>
                      <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                         
                          <p>As Premium Auto Gallery has an experience of importing more than 8000 cars, we can provide you a genuine guidance on buying a new or used car with complete legal details.</p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Eight')" class="accordion-header" >
                        <button id="headingEight" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                          What guidelines are followed while importing cars?
                        </button>
                      </h2>
                      <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                         
                          <p>Local Legal Guidelines and regulations provided by the government are followed while importing cars.</p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Nine')" class="accordion-header" >
                        <button id="headingNine" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                          What is the process of importing cars via Premium Auto Gallery?
                        </button>
                      </h2>
                      <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                         
                          <p>You can complete the process by following these steps:</p>
                          <ul class="list-style-none">
                            <li>1.Take a look at our online stock by visiting our Inventory Page. </li>
                            <li>2.Select a vehicle of your choice. </li>
                            <li>3.Click the Send Inquiry button & submit the form with all relevant information. </li>
                            <li>4.The affiliated Pro forma invoice will be sent to you through email or fax, so do check your emails. CIF Prices, all bank details, and the mode of payment will be mentioned in the Pro forma invoice. </li>
                             <li>5.Make your payment and provide the necessary details. Please note that your selected vehicle will only be reserved on the full receipt of payment. </li>
                         <li>6.The vehicle will be safely shipped to you when the above steps are completed.</li>
                        <li>7.The vehicle will finally reach the port of discharge and you can receive it. </li>
                        <li>8.Enjoy driving your Car.</li>
                        </ul>
                        </div>
                      </div>
                      </div>
                      <div class="accordion-item">
                        <h2 (click)="accordingHeading('Ten')" class="accordion-header" >
                          <button id="headingTen" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                            How does Premium Auto Gallery select cars to be imported?
                          </button>
                        </h2>
                        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample01">
                          <div class="accordion-body">
                           
                            <p>All vehicles available at Premium Auto Gallery are bought from popular auto auctions, franchise dealers, and wholesalers belonging to the central regions of Japan and its whereabouts. All vehicles are properly checked by our team of professional technical staff before we pick up and stock them in our warehouses in Africa. We are neither third-party agents nor brokers.</p>
                           
                          </div>
                        </div>
                        </div>
                        <div class="accordion-item">
                          <h2 (click)="accordingHeading('Eleven')" class="accordion-header" >
                            <button id="headingEleven" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                              How to purchase a Japanese vehicle which is not listed in the stock?
                            </button>
                          </h2>
                          <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample01">
                            <div class="accordion-body">
                             
                              <p>If you want to purchase Japanese cars for sale that is not listed in our available stock then you can simply email us with your requirements. As per your contact details our representative will call you and discuss the availability of that specific vehicle.</p>
                             
                            </div>
                          </div>
                          </div>
    
                          <div class="accordion-item">
                            <h2 (click)="accordingHeading('Twelve')" class="accordion-header" >
                              <button id="headingTwelve" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                What are the payment terms for car import/purchase?
                              </button>
                            </h2>
                            <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample01">
                              <div class="accordion-body">
                               
                                <p>100% payment must be paid in advance to initiate the process of importing your car in Africa.</p>
                               
                              </div>
                            </div>
                            </div>
    
                            <div class="accordion-item">
                              <h2 (click)="accordingHeading('Thirteen')" class="accordion-header" >
                                <button id="headingThirteen" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                  Do you provide any warranty for used vehicles imported from Japan?
                                </button>
                              </h2>
                              <div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample01">
                                <div class="accordion-body">
                                 
                                  <p>No, we don`t provide any warranty on Japanese used cars, but we openly disclose the condition of the Japanese car including any interior/exterior damage, repair history, the distance the Japanese car has been driven, etc. In order to keep the condition of the vehicle transparent and crystal clear with our customers. The customer can also book and appointment and visit us to check the stock in person.</p>
                                 
                                </div>
                              </div>
                              </div>
                              <div class="accordion-item">
                                <h2 (click)="accordingHeading('Fourteen')" class="accordion-header" >
                                  <button id="headingFourteen" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    What is the payment via which payment can be done?
                                  </button>
                                </h2>
                                <div id="collapseFourteen" class="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample01">
                                  <div class="accordion-body">
                                   
                                    <p>Telegraphic Transfer and PayPal are two modes of payment that could be opted for the transaction. Usually, the telegraphic transfer is the swiftest, simplest, and most secure method of payment. The processing time of payment is dependent on the mode you select. In the case of PayPal, certain terms and conditions apply. We work on the policy of first come first serve, so make your payment as soon as possible to reserve your dream vehicle. The Bank details will be provided to you along with the invoice. </p>
                                   
                                  </div>
                                </div>
                                </div>
    
           </div>
    
    
                </div>
               <!--
                <div *ngIf="mainC === 'fo'" class="tab-pane show active"  role="tabpanel" aria-labelledby="features-options-tab">
                  <div class="accordion" id="accordionExample02">
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('One')" class="accordion-header" >
                        <button id="headingOne" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          How many days do I have to transfer ownership?
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          <h6>You will begin to realize why this exercise?</h6>
                          <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                           <ul class="list-style-none">
                             <li>1. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum</li>
                             <li>2. Auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. </li>
                             <li>3. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</li>
                             <li>4. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit Class.</li>
                           </ul>
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Two')" class="accordion-header" >
                        <button id="headingTwo" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                         Is the price you quote guaranteed?
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          <h6>You will begin to realize why this exercise?</h6>
                          <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                           <ul class="list-style-none">
                             <li>1. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum</li>
                             <li>2. Auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. </li>
                             <li>3. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</li>
                             <li>4. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit Class.</li>
                           </ul>
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Three')" class="accordion-header" >
                        <button  id="headingThree" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How much is the most you will pay for a car?
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          <h6>You will begin to realize why this exercise?</h6>
                          <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                           <ul class="list-style-none">
                             <li>1. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum</li>
                             <li>2. Auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. </li>
                             <li>3. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</li>
                             <li>4. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit Class.</li>
                           </ul>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
                </div>
                
                 <div *ngIf="mainC === 'vo'" class="tab-pane show active" role="tabpanel" aria-labelledby="vehicle-overview-tab">
    
                  <div class="accordion" id="accordionExample02">
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('One')" class="accordion-header" >
                        <button id="headingOne" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          How many days do I have to transfer ownership?
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          <h6>You will begin to realize why this exercise?</h6>
                          <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                           <ul class="list-style-none">
                             <li>1. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum</li>
                             <li>2. Auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. </li>
                             <li>3. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</li>
                             <li>4. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit Class.</li>
                           </ul>
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Two')" class="accordion-header" >
                        <button id="headingTwo" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                         Is the price you quote guaranteed?
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          <h6>You will begin to realize why this exercise?</h6>
                          <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                           <ul class="list-style-none">
                             <li>1. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum</li>
                             <li>2. Auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. </li>
                             <li>3. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</li>
                             <li>4. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit Class.</li>
                           </ul>
                        </div>
                      </div>
                    </div>
    
                    <div class="accordion-item">
                      <h2 (click)="accordingHeading('Three')" class="accordion-header" >
                        <button  id="headingThree" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How much is the most you will pay for a car?
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample01">
                        <div class="accordion-body">
                          <h6>You will begin to realize why this exercise?</h6>
                          <p>Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</p>
                           <ul class="list-style-none">
                             <li>1. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum</li>
                             <li>2. Auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. </li>
                             <li>3. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.</li>
                             <li>4. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit Class.</li>
                           </ul>
                        </div>
                      </div>
                    </div>
                  </div>
    
                </div>
          -->
              </div>
           
          </div>
  
  
         
        </div>
      </div>
     </div>
     </div>
    </div>
    </div>
  </section>