<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">privacy policy</h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>privacy policy</span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>


<section class="privacy-policy page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)" [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}" required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-title">
                <span>Protecting your personal information </span>
                <h2>Privacy Policy </h2>
                <div class="separator"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p>You will begin to realize why, consectetur adipisicing elit. Quidem error quae illo excepturi nostrum
                blanditiis laboriosam magnam explicabo.
              <p> eum nihil expedita dolorum odio dolorem, explicabo rem illum magni perferendis. You will begin to
                realize why, consectetur adipisicing elit. Quidem error quae illo excepturi nostrum blanditiis
                laboriosam magnam explicabo. Molestias, eum nihil expedita dolorum odio dolorem, explicabo rem illum
                magni perferendis.</p>
              <p>You will begin to realize why, consectetur adipisicing elit. Quidem error quae illo excepturi nostrum
                blanditiis laboriosam magnam explicabo. Molestias, eum nihil expedita dolorum odio dolorem, explicabo
                rem illum magni perferendis.</p>

              <h5 class="text-red">Personal Information</h5>
              <ul class="list-style-1">
                <li> <i class="fa fa-angle-right"></i> You will begin to realize why, consectetur </li>
                <li> <i class="fa fa-angle-right"></i> Quidem error quae illo excepturi nostrum blanditiis laboriosam
                </li>
                <li> <i class="fa fa-angle-right"></i> Molestias, eum nihil expedita dolorum odio dolorem</li>
                <li> <i class="fa fa-angle-right"></i> Eum nihil expedita dolorum odio dolorem</li>
                <li> <i class="fa fa-angle-right"></i> Explicabo rem illum magni perferendis</li>
              </ul>
              <p>You will begin to realize why, caonsectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi
                sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi
                nesciunt illo. Deserunt.</p>

              <h5 class="text-red">Use of User Information.</h5>
              <p>You will begin to realize why, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi
                sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi
                nesciunt illo. Deserunt.You will begin to realize why, consectetur adipisicing elit. Possimus, ex,
                quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi
                quia deleniti commodi nesciunt illo. Deserunt.You will begin to realize why, consectetur adipisicing
                elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores.
                Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.</p>

              <h5 class="text-red">Disclosure of User Information.</h5>
              <p>You will begin to realize why, consectetur adipisicing elit. Autem ullam nostrum dolor alias aspernatur
                nobis suscipit eaque cumque distinctio eos, beatae deserunt, nihil nam maiores vero, eius harum.
                Reprehenderit, aspernatur.<a
                  href="mailto:support@premiumautogallery.com">support@premiumautogallery.com</a> </p>

              <ul class="list-style-1">
                <li> <i class="fa fa-angle-right"></i> Nulla excepturi sint iusto incidunt sed omnis expedita </li>
                <li> <i class="fa fa-angle-right"></i> Quidem error quae illo excepturi nostrum blanditiis laboriosam
                </li>
                <li> <i class="fa fa-angle-right"></i> Deserunt.You will begin to realize why</li>
                <li> <i class="fa fa-angle-right"></i> Possimus, ex, quisquam. Nulla excepturi</li>
              </ul>
              <p class="mb-0">You will begin to realize why, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla
                excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti
                commodi nesciunt illo. Deserunt.<a
                  href="support@premiumautogallery.com">support@premiumautogallery.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>