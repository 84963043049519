import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { product_details } from './product_details.service';
import Swal from 'sweetalert2';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})

export class ProductDetailsComponent  {
  constructor(private formBuilder: FormBuilder,public registration:product_details,private route: ActivatedRoute, private router: Router){}
  usedCarType: boolean = true;
  form !: FormGroup;
  submitted = false;
  loader:any=false;
  loadAPI: any="";
  data : any=[];
  RecentlyAdded:any=[];
   //url1:any = "src/assets/js/slick/slick.min.js";
   //url2:any =  "assets/js/owl-carousel/owl.carousel.min.js";
 // url3:any = "assets/js/jquery-ui.js";
  //url5:any = "assets/js/custom.js";
 // url4:any =   "assets/js/select/jquery-select.js";
   // url6:any =    "assets/js/slick/slick.min.js";
    url7:any =    "/assets/assets/css/slick.css";
    url8:any =    "/assets/assets/css/slick-theme.css";
   url: any = ["assets/js/slick/slick.min.js","assets/js/owl-carousel/owl.carousel.min.js","assets/js/custom.js"];
    addUrl: any = [];
    id : any="";
    mainC: any = 'gi';
  mainTab(val:any){
    this.mainC =val;
  }
  ngOnInit(){  
    this.id = this.route.snapshot.params['id']; 
    this.form = this.formBuilder.group(
      {
        Name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        message: [''],
      },
    );
      //this.loadAPI = new Promise(resolve => {
        setTimeout(()=>{ 
         // console.log("resolving promise...");
          this.loadScript();
          this.product_listing();
      }, 2000);
        
      //});
  
  }
  
  product_listing(){
    this.loader=true;
    
    //var offset=2;
    //var pageNo=2;
    this.registration.saveUserData(this.id).subscribe((r:any) => {
      //this.checkIfImageExists(r.content);
      this.data=r.content;
      console.log(this.data);
      this.RecentlyAddes(this.data[0].BODY_TYPE, this.data[0].MAKE, this.data[0].MODEL);
      this.loader=false;
    })
   }
   checkIfImageExists(name:any) {
    //this.data.push(name);
    name.forEach( (item: any) =>{
      let imagesPathA = 'assets/images/91x55/'+item.Stock_No+'A.jpg';
            const imgA = new Image();
            imgA.src = imagesPathA;
      imgA.onload = () => {
        item.imgA = true;
       }
      imgA.onerror = () => {
        item.imgA = false;
       
      }
      let imagesPathB = 'assets/images/91x55/'+item.Stock_No+'B.jpg';
            const imgB = new Image();
            imgB.src = imagesPathB;
      imgB.onload = () => {
        item.imgB = true;
       }
      imgB.onerror = () => {
        item.imgB = false;
       
      }
      let imagesPathC = 'assets/images/91x55/'+item.Stock_No+'C.jpg';
            const imgC = new Image();
            imgC.src = imagesPathC;
      imgC.onload = () => {
        item.imgC = true;
       }
      imgC.onerror = () => {
        item.imgC = false;
       
      }
      let imagesPathD = 'assets/images/91x55/'+item.Stock_No+'D.jpg';
            const imgD = new Image();
            imgD.src = imagesPathD;
      imgD.onload = () => {
        item.imgD = true;
       }
      imgD.onerror = () => {
        item.imgD = false;
       
      }
      let imagesPathE = 'assets/images/91x55/'+item.Stock_No+'E.jpg';
            const imgE = new Image();
            imgE.src = imagesPathE;
      imgE.onload = () => {
        item.imgE = true;
       }
      imgE.onerror = () => {
        item.imgE = false;
       
      }
      console.log(item);
      this.data.push(item);
    })
  }
  //BodyDetail:any='';
  //MakeDetail:any='';
  //ModelDetail:any='';
   RecentlyAddes(bodyType:any, make:any, model:any){
    //this.BodyDetail=localStorage.getItem('BodyDetail')
    //this.MakeDetail=localStorage.getItem('MakeDetail')
    //this.ModelDetail=localStorage.getItem('ModelDetail')
   // console.log(this.MakeDetail)
   // console.log(this.ModelDetail)
    //console.log(this.BodyDetail)
    this.registration.RecentlyAdded(bodyType,make,model).subscribe((r:any) => {
     // console.log(r.content);
      this.RecentlyAdded = r.content;
      //this.checkIfImageExistsRecent(r.content);
     // this.RecentlyAdded=r.content;
     this.loader=false;
    });
   }
   checkIfImageExistsRecent(name:any) {
    this.RecentlyAdded = name;
    /*name.forEach( (item: any) =>{
      let imagesPath = 'assets/images/255x188/S'+item.Stock_No+'A.jpg';
            const img = new Image();
            img.src = imagesPath;
      img.onload = () => {
        item.img = true;
       }
      img.onerror = () => {
        item.img = false;
       
      }
      this.RecentlyAdded.push(item);
    })*/
  
  }
  usedCarTypeFun(val:any){
    this.usedCarType = val;
  }
   loadScript() {
    if(this.url.length>0){
      this.url.forEach((element:any) => {
        let node = document.createElement("script");
        node.src = element;
        node.type = "text/javascript";
        node.async = true;
        document.getElementsByTagName("head")[0].appendChild(node);
        this.addUrl.push(node);
      });
    }

    let style = document.createElement("link");
    let style1 = document.createElement("link");
    style.href = this.url7;
    style1.href = this.url8;
    style1.type = "text/css";
    style1.rel = "stylesheet";
    style.type = "text/css";
    style.rel = "stylesheet";
    //console.log(style);
    document.getElementsByTagName("head")[0].appendChild(style);
    document.getElementsByTagName("head")[0].appendChild(style1);
}
ngAfterViewInit() {
  // Hack: Scrolls to top of Page after page view initialized
  let top = document.getElementById('top');
  if (top !== null) {
    top.scrollIntoView();
    top = null;
  }
}
onSubmit(){
  this.submitted = true;
  console.log(this.form.value)
  if (this.form.invalid) {
    return;
  }
  this.loader=true;
  //console.log(this.form.value);
  this.registration.formData(this.form.value,this.data[0].Stock_No,this.data[0].MAKE,this.data[0].MODEL).subscribe((r: any) => {
    //console.log(r.succesfull);
    if (r.successfull == true) {
      Swal.fire('Query', 'Query is succeccfully submitted!', 'success');
      this.submitted = false;
      this.form.reset();
      //console.log("hey");
    } else {
      Swal.fire('Query', 'Query is not Submitted!', 'error');
    }

    this.loader=false
  })
}
ngOnDestroy() {
  //console.log('222222');
  //console.log(this.addUrl);
  this.addUrl.forEach((link:any) => {
    link.parentNode.removeChild(link);
  });
}
PostData(val: any) {
  this.id = val;
  this.product_listing();
 // this.router.navigate(['Product-Detail', val]);
  //console.log(val)
}
}


