<!-- <div id="top"></div> -->
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
  <div class="container">
    <div class="row text-center intro-title">
      <div class="col-md-6 text-md-start d-inline-block">
        <h1 class="text-white">Terms and Conditions </h1>
      </div>
      <div class="col-md-6 text-md-end float-end">
        <ul class="page-breadcrumb">
          <li><a href="/home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
          <li><span>terms and Conditions</span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!--=================================
   inner-intro -->


<!--=================================
   welcome -->

<section class="terms-and-conditions page-section-ptb">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 product-listing">
        <div class="listing-sidebar">
          <div class="widget">
            <div class="widget-search">
              <h5>Advanced Search</h5>
              <ul class="list-style-none">
                <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
              </ul>
            </div>

            <div class="clearfix">
              <ul class="list-group">
                <li class="list-group-item">
                  <div class="col-lg-12 p-0 m-0">

                    <div class="price-slide">
                      <div class="price">
                        <label class="mb-2" for="amount">Price Range</label>
                        <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()"
                          [options]="options"></ngx-slider>
                        <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                         <div id="slider-range"></div>-->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <a>Year</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value=""
                          id="invalidCheck01" required>
                        <label class="form-check-label" for="invalidCheck01">
                          All Years
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of yearsValues">
                      <div class="form-check">
                        <input (click)="yearValues($event.target, item.value)" [checked]="item.checked"
                          class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Condition</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck10" required>
                        <label class="form-check-label" for="invalidCheck10">
                          All Conditions
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of conditionValues">
                      <div class="form-check">
                        <input class="form-check-input" (click)="conditionValue($event.target, item.value)"
                          [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Body</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck14" required>
                        <label class="form-check-label" for="invalidCheck14">
                          All Body Styles
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of list_of_body_Type">
                      <div class="form-check">
                        <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)" [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}" required>
                        <label class="form-check-label" for="{{item.Body_Type}}">
                          {{item.Body_Type}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Model</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox" value=""
                          id="invalidCheck20" required>
                        <label class="form-check-label" for="invalidCheck20">
                          All Models
                        </label>
                      </div>
                    </li>
                    <li *ngFor="let item of modelValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Transmission</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox"
                          value="" id="invalidCheck32" required>
                        <label class="form-check-label" for="invalidCheck32">
                          All Transmissions
                        </label>
                      </div>
                    </li>

                    <li *ngFor="let item of transmissionValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          (click)="transmissionValue($event.target, item.value)" [checked]="item.checked"
                          value="{{item.value}}" id="{{item.value}}" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Exterior Color</a>
                  <ul>
                    <li *ngFor="let item of exteriorColorValues">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="{{item.value}}"
                          (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked"
                          id="iem.value" required>
                        <label class="form-check-label" for="{{item.value}}">
                          {{item.value}}
                        </label>
                      </div>
                    </li>

                  </ul>
                </li>
                <li class="list-group-item">
                  <a>Interior Color</a>
                  <ul>
                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                        <label class="form-check-label" for="invalidCheck46">
                          Platinum Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                        <label class="form-check-label" for="invalidCheck47">
                          Agate Grey
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                        <label class="form-check-label" for="invalidCheck48">
                          Marsala Red
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                        <label class="form-check-label" for="invalidCheck49">
                          Alcantara Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                        <label class="form-check-label" for="invalidCheck50">
                          Black
                        </label>
                      </div>
                    </li>

                    <li>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                        <label class="form-check-label" for="invalidCheck51">
                          Luxor Beige
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                <a class="button1" (click)="SearchValues()">Search</a>
              </div>
            </div>
          </div>
          <!--<div class="widget-banner">
            <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
          </div>-->
        </div>
      </div>
      <div class="col-lg-9 col-md-8 mt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-title">
                <span>Protecting your personal information </span>
                <h2>Terms And Conditions</h2>
                <div class="separator"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h5 class="text-red">Vehicle Availability </h5>
              <p>All vehicles are available on a first-come-first-serve basis. If more than one buyer is interested in
                the
                same vehicle, the vehicle will be sold to the one who pays first. If a payment reaches us late and the
                vehicle is sold to another buyer, we will provide you with a similar vehicle with the same
                specifications.</p>

              <h5 class="text-red">Age Requirement</h5>
              <p>You must be 18 years old or over to buy a vehicle. People younger than 18 years can buy a car
                through their parents.
              </p>

              <h5 class="text-red"> Bulk Purchases Discounts</h5>
              <p>A certain percentage discount is offered on bulk purchases. The % varies according to the number of
                units purchased; the greater the number of units purchased, the higher the % discount.
              </p>

              <h5 class="text-red">Vehicle Condition </h5>
              <p>The Buyer acknowledges that all used/reconditioned vehicles are sold "as is" and that no guarantee
                or warranty is given as to the condition or performance of the vehicle(s), including the parts and
                accessories. As the stock is used/reconditioned and therefore a certain tolerance on the general
                condition of the vehicle must be considered.</p>
              <p>However, buyers are given the opportunity to check the vehicle in detail before buying. </p>

              <!-- <ul class="list-style-1">
                <li> <i class="fa fa-angle-right"></i> Lorem ipsum dolor sit amet, consectetur </li>
                <li> <i class="fa fa-angle-right"></i> Quidem error quae illo excepturi nostrum blanditiis laboriosam </li>
                <li> <i class="fa fa-angle-right"></i> Molestias, eum nihil expedita dolorum odio dolorem</li>
                <li> <i class="fa fa-angle-right"></i> Eum nihil expedita dolorum odio dolorem</li>
                <li> <i class="fa fa-angle-right"></i> Explicabo rem illum magni perferendis</li>
              </ul> -->

              <h5 class="text-red">Age Requirement </h5>
              <p>You must be 18 years old or over to buy a vehicle. People younger than 18 years can buy a car
                through their parents.
              </p>


              <h5 class="text-red"> Bulk Purchases Discounts</h5>
              <p>A certain percentage discount is offered on bulk purchases. The % varies according to the number of
                units purchased; the greater the number of units purchased, the higher the % discount.
              </p>

              <h5 class="text-red"> Vehicle Condition</h5>
              <p>The Buyer acknowledges that all used/reconditioned vehicles are sold "as is" and that no guarantee
                or warranty is given as to the condition or performance of the vehicle(s), including the parts and
                accessories. As the stock is used/reconditioned and therefore a certain tolerance on the general
                condition of the vehicle must be considered.</p>
              <p>However, buyers are given the opportunity to check the vehicle in detail before buying.</p>

              <h5 class="text-red"> Payment and Delivery</h5>
              <p>The vehicle(s) will be delivered to the Buyer upon receipt of full payment. If the payment method is
                TT (telegraphic transfer), the Buyer will be responsible for all sender and receiver bank charges.
              </p>
              <p>The vehicle/s delivery process will start after receiving full payment.</p>

              <h5 class="text-red"> Proof of Payment</h5>
              <p>If the payment method is TT, the Buyer must send proof of payment, and a swift copy must be
                provided to PAG to locate and confirm the payment in time.</p>

              <h5 class="text-red"> Damage(s) and Insurance</h5>
              <p>In the event that the vehicle(s) get damaged during delivery, the PAG will provide a similar vehicle at
                its discretion, subject to the procedure and approval of the insurance company.
              </p>

              <h5 class="text-red"> Availability of Buyer for Delivery</h5>
              <p>If the Buyer is not present at the time of receiving/collecting the vehicle(s) from the border/port,
                the
                vehicle(s) will be delivered to the Buyer's designated authority or agent.</p>

              <h5 class="text-red"> Delay, Demurrage and Demurrage Charges
              </h5>
              <p>If there is a delay in receiving the vehicle(s) at the border/port due to the Buyer's actions, the
                Buyer
                will be responsible for any demurrage charges imposed by the authorities.
              </p>
              <p>If the car carrier could not cross the border due to incomplete documents by the Buyer or in the
                absence of documents, then the Buyer will be responsible for paying the demurrage charges
                imposed by the authorities.</p>

              <h5 class="text-red"> Customs Clearance & Required Documents</h5>
              <p>The Buyer must provide all local customs authority documents required for clearance upon receiving
                the vehicle(s) from the border/port.</p>
              <p>On receiving a vehicle from the border/port, the buyer must provide all local custom authority
                documents required for clearance. After receiving formal/required documents, the vehicle will be
                loaded on a car carrier for delivery.</p>

              <h5 class="text-red"> Payment of Charges</h5>
              <p>The Buyer must pay all charges quoted by the PAG at the time of delivery. If the Buyer fails to do so,
                the car carrier driver will offload the vehicle(s) in the customs yard, and the PAG will not be
                responsible for any loss or if custom authorities auction it. </p>

              <h5 class="text-red">Currency and Bank Charges</h5>
              <p>If Payment is made in any other currency other than US Dollar, all exchange rate differences will be
                borne by the buyer. </p>
              <p>The consignee shall bear all the Bank Charges and expenses. USD 25 to be deposited on top of the
                invoice amount for bank service charges in Japan.</p>

              <h5 class="text-red">Local Taxes and Charges</h5>
              <p>The C&F price of the vehicle(s) does not include local taxes and charges of local authorities.</p>

              <h5 class="text-red">Car Carrier and Delivery Time</h5>
              <p>Once the required documents are received, the vehicle(s) will be loaded on a car carrier for delivery.
                It will take 10 working days for Zimbabwe for the vehicle(s) to reach their respective destination
                borders.
              </p>

              <h5 class="text-red">Shipping Documentation</h5>
              <p>Once the payment is received the following documentation, necessary to clear the vehicle at the
                destination port, will be sent to you via mail or courier after the vessel has been shipped:</p>

              <ul class="list-style-1">
                <li> <i class="fa fa-angle-right"></i> B/L (Bill of Loading)</li>
                <li> <i class="fa fa-angle-right"></i> Invoice</li>
                <li> <i class="fa fa-angle-right"></i> Export Certification if required</li>
                <li> <i class="fa fa-angle-right"></i>Other required documents in your local custom</li>
              </ul>

              <h5 class="text-red">Responsibility Scratches and Thef</h5>
              <p>The PAG will not be responsible for any scratches on the vehicle(s) or in case of theft during
                delivery. </p>

              <h5 class="text-red">Refunds</h5>
              <p>Refunds will not be issued for any payment made due to the buyer's complaint or request. However,
                the PAG understands that certain situations may warrant a refund, in which case a deduction of 20%
                of the FOB amount will be applied to cover our service and bank charges.
              </p>

              <h5 class="text-red">Buyer Arrangement</h5>
              <p>After Arrangement of the vehicle, necessary payment for shipment (i.e. 50% or 100%) must be
                complete within 7 days of vehicle purchase. If there are any delays, then the penalty will be imposed
                based on the detailed PAG penalty policy.</p>
              <p>If in any case PAG agrees to ship/transport on 50% payment, then the remaining 50% must be
                completed within 7 days of BL copy or transportation car carrier. If there are any delays, then the
                penalty will be imposed based on the detailed PAG penalty policy.</p>

              <h5 class="text-red">Vehicle Examination After Receiving at Port/Border</h5>
              <p>Upon receiving the vehicle(s) at the border, the Buyer must check the engine and gear oil, water level
                in radiator filters, air pressure of tires, radiator coolant & filters, and brake fluid levels before
                driving.
                Failure to do so may cause engine damage or tire puncture.</p>
              <p>The PAG does not provide any guarantee for parts or other issues.The PAG does not provide any
                guarantee for parts or other issues.</p>
              <p>The Buyer must only use recommended oils and filters suggested by the manufacturer. The coolant
                must be full but only up to the given levels by the manufacturer. The engine oil and ATF must be
                within the min-max level. If required, air filters must be changed.</p>

              <h5 class="text-red">Enactment, Validity, Interpretation, and Execution</h5>
              <p>In case a new/used/reconditioned vehicle is being imported directly from Japan, then the Terms and
                Conditions of sales comply with the Japanese laws and conform to rules and regulations pertaining
                to automobile export/trading. As such enactment, validity, interpretation, and execution of these
                Terms and Conditions are subject to the Japanese automobile export/trading laws, rules, and
                regulations only.</p>

              <h5 class="text-red">Dispute Resolution</h5>
              <p>Should any dispute between the buyer(s) and the Company occur with regards to the service, and/or
                any contracts concluded through the service, the parties concerned agree to attempt to settle any
                such disputes through dialogue in the most sincere manner.</p>
              <p>In case, if the dispute(s) failed to be resolved through dialogue between the concerned parties, the
                Durban District Court holds original and exclusive jurisdiction over the resolution of the dispute.
              </p>

              <h5 class="text-red">Updates to Terms & Conditions</h5>
              <p>PAG reserves the right to update the Terms & Conditions at any time without prior notice.</p>

              <!-- <a class="button red" href="#"><span>Accept</span></a>
            <a class="button red" href="#"><span>Close</span></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>









<!-- Mirrored from themes.potenzaglobalsolutions.com/html/cardealer/about-01.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 31 May 2023 21:39:47 GMT -->