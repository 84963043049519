<!-- <div id="top"></div> -->
<div class="app-loader" *ngIf="loader"></div>
<div class="app-header"></div>
<section class="inner-intro bg-1 bg-overlay-black-70">
    <div class="container">
       <div class="row text-center intro-title">
             <div class="col-md-6 text-md-start d-inline-block">
               <h1 class="text-white">Our {{homeTitle}} Stock </h1>
             </div>
             <div class="col-md-6 text-md-end float-end">
               <ul class="page-breadcrumb">
                  <li><a href="/Home"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
                  <li><span>Stock listing</span> </li>
               </ul>
             </div>
       </div>
    </div>
  </section>
  
  <!--=================================
   inner-intro -->
  
  
  <!--=================================
  product-listing  -->
  
  <section class="product-listing page-section-ptb">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4">
          <div class="listing-sidebar">
            <div class="widget">
              <div class="widget-search">
               <h5>Advanced Search</h5>
               <ul class="list-style-none">
                 <li><i class="fa fa-star"> </i> Total Records <span class="float-end">({{Record}})</span></li>
                 <!-- <li><i class="fa fa-shopping-cart"> </i> Compare Vehicles <span class="float-end">(10)</span></li> -->
               </ul>
              </div>
              
              <div class="clearfix">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="col-lg-12 p-0 m-0">
            
                        <div class="price-slide">
                         <div class="price">
                          <label class="mb-2" for="amount">Price Range</label>
                          <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" (mouseup)="PriceRangeChange()" [options]="options"></ngx-slider>
                            <!--<input (change)="priceRangeChange($event.target)" type="text" id="amount" class="amount" value="$50 - $300" />
                           <div id="slider-range"></div>-->
                         </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <a>Year</a>
                      <ul>
                        <li>
                          <div class="form-check">
                            <input (click)="yearSelectAll($event.target)" class="form-check-input" type="checkbox" value="" id="invalidCheck01" required>
                            <label class="form-check-label" for="invalidCheck01">
                              All Years
                            </label>
                          </div>
                        </li>
                        <li *ngFor="let item of yearsValues">
                          <div class="form-check">
                            <input (click)="yearValues($event.target, item.value)" [checked]="item.checked" class="form-check-input" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                            <label class="form-check-label" for="{{item.value}}">
                              {{item.value}}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="list-group-item">
                      <a >Condition</a>
                      <ul>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" (click)="conditionSelectAll($event.target)" type="checkbox" value="" id="invalidCheck10" required>
                            <label class="form-check-label" for="invalidCheck10">
                              All Conditions
                            </label>
                          </div>
                        </li>
                        <li *ngFor="let item of conditionValues">
                          <div class="form-check">
                            <input class="form-check-input" (click)="conditionValue($event.target, item.value)" [checked]="item.checked" type="checkbox" [value]="item.value" id="{{item.value}}" required>
                            <label class="form-check-label" for="{{item.value}}">
                              {{item.value}}
                            </label>
                          </div>
                        </li>                        
                      </ul>
                    </li>
                    <li class="list-group-item">
                      <a>Body</a>
                      <ul>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" (click)="bodySelectAll($event.target)" type="checkbox" value="" id="invalidCheck14" required>
                            <label class="form-check-label" for="invalidCheck14">
                              All Body Styles
                            </label>
                          </div>
                        </li>
                        <li *ngFor="let item of list_of_body_Type">
                          <div class="form-check">
                            <input class="form-check-input" (click)="bodyValue($event.target, item.Body_Type)" [checked]="item.checked" type="checkbox" [value]="item.Body_Type" id="{{item.Body_Type}}" required>
                            <label class="form-check-label" for="{{item.Body_Type}}">
                              {{item.Body_Type}}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="list-group-item">
                      <a >Model</a>
                      <ul>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" (click)="modelSelectAll($event.target)" type="checkbox"  value="" id="invalidCheck20" required>
                            <label class="form-check-label" for="invalidCheck20">
                              All Models
                            </label>
                          </div>
                        </li>  
                        <li *ngFor="let item of modelValues">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" (click)="transmissionValue($event.target, item.value)" [checked]="item.checked" value="{{item.value}}" id="{{item.value}}" required>
                            <label class="form-check-label" for="{{item.value}}">
                              {{item.value}}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="list-group-item">
                      <a >Transmission</a>
                      <ul>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" (click)="transmissionSelectAll($event.target)" type="checkbox" value="" id="invalidCheck32" required>
                            <label class="form-check-label" for="invalidCheck32">
                              All Transmissions
                            </label>
                          </div>
                        </li>
  
                        <li *ngFor="let item of transmissionValues">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" (click)="transmissionValue($event.target, item.value)" [checked]="item.checked" value="{{item.value}}" id="{{item.value}}" required>
                            <label class="form-check-label" for="{{item.value}}">
                              {{item.value}}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="list-group-item">
                      <a >Exterior Color</a>
                      <ul>
                        <li *ngFor="let item of exteriorColorValues">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="{{item.value}}" (click)="exteriorColorValue($event.target, item.value)" [checked]="item.checked" id="iem.value" required>
                            <label class="form-check-label" for="{{item.value}}">
                              {{item.value}}
                            </label>
                          </div>
                        </li>
                        
                      </ul>
                    </li>
                    <li class="list-group-item">
                      <a>Interior Color</a>
                      <ul>
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck46" required>
                            <label class="form-check-label" for="invalidCheck46">
                              Platinum Grey
                            </label>
                          </div>
                        </li>
  
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck47" required>
                            <label class="form-check-label" for="invalidCheck47">
                              Agate Grey
                            </label>
                          </div>
                        </li>
  
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck48" required>
                            <label class="form-check-label" for="invalidCheck48">
                              Marsala Red
                            </label>
                          </div>
                        </li>
  
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck49" required>
                            <label class="form-check-label" for="invalidCheck49">
                              Alcantara Black
                            </label>
                          </div>
                        </li>
  
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck50" required>
                            <label class="form-check-label" for="invalidCheck50">
                              Black
                            </label>
                          </div>
                        </li>
  
                        <li>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck51" required>
                            <label class="form-check-label" for="invalidCheck51">
                              Luxor Beige
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                </ul>
                <div style="margin-top: 20px;padding-left: 100px;" class="mb-2">
                  <a class="button1" (click)="SearchValues()">Search</a>
                </div>
              </div>
             </div>
            <!--<div class="widget-banner">
              <img class="img-fluid center-block" src="assets\images\banner.jpg" alt="">
            </div>-->
          </div>
        </div>
        <div class="col-lg-9 col-md-8 mt-0">
           <div class="car-grid" *ngIf="data.length<1" >
            <div class="row">
            Records are not Found
            </div>
          </div>
            <div *ngFor="let item of data" class="car-grid">
             <div class="row">
              <div class="col-lg-4 col-md-12">
                <div class="car-item text-center">
                 <div  class="car-image">
                   <img class="img-fluid" *ngIf="item.IMAGE_NAME !== ''" src="assets\images\255x188\{{item.IMAGE_NAME}}.jpg" alt="">
                   <img class="img-fluid" *ngIf="item.IMAGE_NAME === ''" src="assets\images\255x188\default.jpg" alt="">
                   <!--<div class="car-overlay-banner">
                    <ul>
                      <li><a href="javascript;"><i class="fa fa-link"></i></a></li>
                      <li><a href="javascript"><i class="fa fa-shopping-cart"></i></a></li>
                     </ul>
                   </div>-->
                 </div>
                </div>
               </div>
                <div class="col-lg-8 col-md-12">
                  <div class="car-details">
                  <div class="car-title" >
                   <a (click)="PostData(item.Stock_No,item.BODY_TYPE,item.MAKE,item.MODEL)" *ngIf="item.MODEL">{{item.MODEL}}</a>
                    </div>
                    <div class="price">
                         <!--<span class="old-price">$35,568</span>-->
                         <div class="float-end ml-2  likeButton" *ngIf="userToken !== 'null' && item.FavVac" ><a (click)="favorute(item, false)" class="active" style="background-color:#db2d2e;color: #fff;border: 1px solid; padding:2px 5px; margin-left:5px"><i class="fa fa-heart-o"></i></a></div>
                         <div class="float-end ml-2  likeButton" *ngIf="userToken !== 'null' && !item.FavVac" ><a (click)="favorute(item, true)" style="border: 1px solid; padding:2px 5px; margin-left:5px"><i class="fa fa-heart-o"></i></a></div>
                         <span class="new-price" *ngIf="item.C_F_PRICE">${{item.C_F_PRICE}} </span>
                         <div class="button red float-end" *ngIf="item.out_off_stock===1">Out Of Stock</div>
                         <a class="button red float-end" (click)="PostData(item.Stock_No,item.BODY_TYPE,item.MAKE,item.MODEL)" *ngIf="item.out_off_stock===0">Details</a>
                       </div>
                     <div class="car-list" >
                       <ul class="list-inline">
                         <li *ngIf="item.MODEL_YEAR ">
                            <i class="fa fa-registered"></i> {{item.MODEL_YEAR}}
                          </li>
                         <li *ngIf="item.TRANSMISSION_TYPE"><i class="fa fa-cog"></i> {{item.TRANSMISSION_TYPE}} </li>
                         <li *ngIf="item.NOTED_MILEAGE"><i class="fa fa-shopping-cart"></i> {{item.NOTED_MILEAGE}} mi</li>
                       </ul>
                     </div>
                    </div>
                  </div>
                 </div>
               </div>
             <div class="pagination-nav d-flex justify-content-center" *ngIf="data.length > 0">
                 <ul class="pagination" >
                   <li ><a [ngClass]="{'disable':pageNo===1}" (click)="previousPage()">«</a></li>
                   <li *ngFor="let val of counter(totalPages); let i = index" [ngClass]="{'active': i === pageNo-1}"><a (click)="pageNoViwe(i+1)">{{i+1}}</a></li>
                   <li ><a [ngClass]="{'disable':pageNo===totalPages}" (click)="nextPage()">»</a></li>
                  </ul>
                  <div class="col-lg-2">
                    <div class="price-search">
                      <!--<span class="mb-2">Showing Records</span>-->
                     <select [(ngModel)]="offset" (change)="offsetValueChange($event.target)">
                       <option value="25">25 </option>
                       <option value="50">50 </option>
                       <option value="100">100</option>
                       <option value="500">500 </option>
                     </select>
                   </div>
                  </div>
            </div>
            </div>
          </div>
        </div>
  </section>