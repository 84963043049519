import { Component, OnInit } from '@angular/core';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { Router } from '@angular/router';  
import { homeService } from '../home/home.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  minValue: number = 1000;
  maxValue: number = 100000;
  options: Options = {
    floor: 1000,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return "$" + value;
      }
    }
  };
  exteriorColorValues:any=[{'value':'Ruby Red Matelic','checked':false},{'value':'Racing Yellow','checked':false},{'value':'Gaurds Red','checked':false},{'value':'Aqua Blue Matelic','checked':false},{'value':'White','checked':false},{'value':'Dark Blue Metallic ','checked':false},{'value':'Pearl ','checked':false}]
  modelValues:any=[{'value':'3-Series','checked':false},{'value':'Boxter','checked':false},{'value':'Carrera','checked':false},{'value':'F-type','checked':false},{'value':'GT-R','checked':false},{'value':'GTS','checked':false},{'value':'M6','checked':false},{'value':'Macan','checked':false},{'value':'Mazda','checked':false},{'value':'RLX','checked':false},{'value':'FIT','checked':false},{'value':'Aqua','checked':false}]
  conditionValues:any=[{'value':'Brand New','checked':false},{'value':'Sligthly Used','checked':false},{'value':'Used','checked':false}]
  bodyValues:any=[{'value':'2dr Car','checked':false},{'value':'4dr Car','checked':false},{'value':'Convertible','checked':false},{'value':'Sedan','checked':false},{'value':'Sports Utility Vehicle','checked':false}]
  transmissionValues: any =[{'value':'5-Speed manual','checked':false},{'value':'6-Speed manual','checked':false},{'value':'6-Speed Automatic','checked':false},{'value':'6-Speed Semi-Auto','checked':false},{'value':'7-Speed PDK','checked':false},{'value':'8-Speed Automatic','checked':false},{'value':'8-Speed Triptronic','checked':false}]
  yearsValues: any =[{'value':'2008','checked':false},{'value':'2009','checked':false},{'value':'2010','checked':false},{'value':'2011','checked':false},{'value':'2012','checked':false},{'value':'2013','checked':false},{'value':'2014','checked':false},{'value':'2015','checked':false},{'value':'2016','checked':false},{'value':'2017','checked':false},{'value':'2018','checked':false},{'value':'2019','checked':false},{'value':'2020','checked':false},{'value':'2021','checked':false},{'value':'2022','checked':false},{'value':'2023','checked':false}]
  makeValueSelected:any=[];
  locationValueSelected:any=[];
  Search: any='';
  mainC: any = 'gi';
  Record : any = 0
  constructor(public homePageService:homeService,private router: Router){}
  loadAPI: any="";
 // url5:any =  "assets/js/custom.js";
  url:any=[ "assets/js/custom.js"];
  addUrl: any = [];
  ngOnInit(){
    this.Record = localStorage.getItem('totalRecord')
    this.loadAPI = new Promise(resolve => {
      console.log("resolving promise...");
      this.loadScript();
    });
    this.List_of_body_Type();
  }
  loadScript() {
    if(this.url.length>0){
      this.url.forEach((element:any) => {
        let node = document.createElement("script");
        node.src = element;
        node.type = "text/javascript";
        node.async = true;
        document.getElementsByTagName("head")[0].appendChild(node);
        this.addUrl.push(node);
      });
    }
  }
  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
    ngOnDestroy() {
      //console.log('222222');
      //console.log(this.addUrl);
      this.addUrl.forEach((link:any) => {
        link.parentNode.removeChild(link);
      });
    }
    yearsValueSelected:any=[];
    yearValues(check:any, val:any){
      //console.log(val);
      if (check.checked) {
        this.yearsValueSelected.push(val);
      } else {
        let index = this.yearsValueSelected.indexOf(val);
        if (index > -1) {
          this.yearsValueSelected.splice(index, 1);
        } 
      }
     // console.log(this.yearsValueSelected);
    }
    yearSelectAll(check:any){
      if (check.checked) {
        this.yearsValues.forEach((item:any) => {
          item.checked = true;
          this.yearsValueSelected.push(item.value);  
        })
      }else{
        this.yearsValueSelected = []; 
        this.yearsValues.forEach((item:any) => {
          item.checked = false; 
        })
      }
      //this.yearsValueSelected
    }
    conditionValueSelected:any=[]
    conditionValue(check:any, val:any){
      if (check.checked) {
        this.conditionValueSelected.push(val);
        //console.log(this.conditionValueSelected)
      } else {
        let index = this.conditionValueSelected.indexOf(val);
        if (index > -1) {
          this.conditionValueSelected.splice(index, 1);
         // console.log(this.conditionValueSelected)
        } 
      }
      console.log(this.conditionValueSelected);
    }
    conditionSelectAll(check:any){
      if (check.checked) {
        this.conditionValues.forEach((item:any) => {
          item.checked = true;
          this.conditionValueSelected.push(item.value);  
        })
      }else{
        this.conditionValueSelected = []; 
        this.conditionValues.forEach((item:any) => {
          item.checked = false; 
        })
      }
      //this.yearsValueSelected
    }
    bodyValueSelected:any=[]
    bodyValue(check:any, val:any){
      if (check.checked) {
        this.bodyValueSelected.push(val);
        //console.log(this.conditionValueSelected)
      } else {
        let index = this.bodyValueSelected.indexOf(val);
        if (index > -1) {
          this.bodyValueSelected.splice(index, 1);
         // console.log(this.conditionValueSelected)
        } 
      }
      console.log(this.bodyValueSelected);
    }
    bodySelectAll(check:any){
      if (check.checked) {
        this.bodyValues.forEach((item:any) => {
          item.checked = true;
          this.bodyValueSelected.push(item.value);  
        })
      }else{
        this.bodyValueSelected = []; 
        this.bodyValues.forEach((item:any) => {
          item.checked = false; 
        })
      }
      //this.yearsValueSelected
    }
    transmissionSelectedValues:any=[]
    transmissionValue(check:any, val:any){
      if (check.checked) {
        this.transmissionSelectedValues.push(val);
        //console.log(this.conditionValueSelected)
      } else {
        let index = this.transmissionSelectedValues.indexOf(val);
        if (index > -1) {
          this.transmissionSelectedValues.splice(index, 1);
         // console.log(this.conditionValueSelected)
        } 
      }
      console.log(this.transmissionSelectedValues);
    }
    transmissionSelectAll(check:any){
      if (check.checked) {
        this.transmissionValues.forEach((item:any) => {
          item.checked = true;
          this.transmissionSelectedValues.push(item.value);  
        })
      }else{
        this.transmissionSelectedValues = []; 
        this.transmissionValues.forEach((item:any) => {
          item.checked = false; 
        })
      }
      //this.yearsValueSelected
    }
    modelSlectedValues:any=[]
    modelValue(check:any, val:any){
      if (check.checked) {
        this.modelSlectedValues.push(val);
        //console.log(this.conditionValueSelected)
      } else {
        let index = this.modelSlectedValues.indexOf(val);
        if (index > -1) {
          this.modelSlectedValues.splice(index, 1);
         // console.log(this.conditionValueSelected)
        } 
      }
      console.log(this.modelSlectedValues);
    }
    modelSelectAll(check:any){
      if (check.checked) {
        this.modelValues.forEach((item:any) => {
          item.checked = true;
          this.modelSlectedValues.push(item.value);  
        })
      }else{
        this.modelSlectedValues = []; 
        this.modelValues.forEach((item:any) => {
          item.checked = false; 
        })
      }
      //this.yearsValueSelected
    }
    exteriorColorSlectedValues:any=[]
    exteriorColorValue(check:any, val:any){
      if (check.checked) {
        this.exteriorColorSlectedValues.push(val);
        //console.log(this.conditionValueSelected)
      } else {
        let index = this.exteriorColorSlectedValues.indexOf(val);
        if (index > -1) {
          this.exteriorColorSlectedValues.splice(index, 1);
         // console.log(this.conditionValueSelected)
        } 
      }
      console.log(this.exteriorColorSlectedValues);
    }
    
    PriceRangeChange(){
      this.minValue=this.minValue
      this.maxValue=this.maxValue
     }
    SearchValues(){
      let innerSearchVal: any = {'InnerSreacrhModel':this.modelSlectedValues,'InnerSearchYear':this.yearsValueSelected,'InnerSearchBody':this.bodyValueSelected,'min':this.minValue,'max':this.maxValue,'InnerModelSearch':this.modelSlectedValues,'InnerTransmissionSearch':this.transmissionSelectedValues,'InnerExteriorSearch':this.exteriorColorSlectedValues,'InnerPriceSearch':this.Search,'InnerSearchcondition':this.conditionValueSelected};
      localStorage.setItem('InnerSearchVal',JSON.stringify(innerSearchVal));
      this.router.navigate(['/Stock-Listing']);
    }
    submitSearch(val : any) {
      this.Search = val.value;
      this.SearchValues();
    }
    list_of_body_Type : any = []
  List_of_body_Type(){
    this.homePageService.Body_Type().subscribe((r: any) => {
      if(r.successfull){
        this.list_of_body_Type = r.content;
        console.log(typeof(this.list_of_body_Type))
       // console.log(this.Record)
      }
    })    
}
}
